import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { IPostDetail } from "../../../../apis/post/post.types";
import BackHeader from "../../../basic/header/BackHeader";
import Content from "../../../common/Content";
import PostUserInformation from "../../../common/PostUserInformation";
import Layout from "../../../container/Layout";

interface Props {
  item: IPostDetail;
  EditButton?: React.ReactElement;
  LikeButton: React.ReactElement;
  CommentInput: React.ReactElement;
  CommentList: React.ReactElement;
}

const PostDetailTemplate: React.FC<Props> = ({ item, EditButton, LikeButton, CommentInput, CommentList }) => {
  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  const title = "";

  return (
    <Layout Header={<BackHeader title={title} onClick={handleBack} Right={EditButton} />}>
      <Container>
        <TextBox>
          <TitleWrapper>
            <Title>{item.title}</Title>
            <PostUserInformation nickname={item.user && item.user.username} date={item.created_at} />
            <TitleUnderline />
          </TitleWrapper>

          <ContentWrapper>
            <Content content={item.content} />
          </ContentWrapper>

          <TextBoxBottom>
            <LikeButtonWrapper>{LikeButton}</LikeButtonWrapper>
            <BackMenuListTextWrapper>
              <Link to="/posts">
                <BackMenuListText>목록으로</BackMenuListText>
              </Link>
              <div>
                <Count>조회 {item.viewCount || 0}</Count>
                <Count>추천 {item.likeCount || 0}</Count>
              </div>
            </BackMenuListTextWrapper>
          </TextBoxBottom>
        </TextBox>

        <Box>
          <BoxTitle>댓글</BoxTitle>
          <CommentListWrapper>{CommentList}</CommentListWrapper>
          <CommentInputWrapper>{CommentInput}</CommentInputWrapper>
        </Box>
      </Container>
    </Layout>
  );
};

export default PostDetailTemplate;

const Container = styled.div``;

const TextBox = styled.div`
  background-color: #fff;
  padding: 16px 0;
`;

const TitleWrapper = styled.div`
  padding: 0 16px;
`;

const Title = styled.h3`
  color: #000;
  font-family: sans-serif;
  font-size: 1.55rem;
  font-weight: normal;
  line-height: 1.35;
  margin-top: 0;
  margin-bottom: 16px;
`;

const TitleUnderline = styled.div`
  width: 100%;
  border-bottom: 1px solid #eaeaea;
`;

const ContentWrapper = styled.div`
  margin-top: 16px;
`;

const Box = styled.div`
  background-color: #fff;
  padding: 20px;
  margin-top: 8px;
`;

const BoxTitle = styled.h6`
  margin-top: 0;
  margin-bottom: 16px;
  color: #888;
`;

const CommentListWrapper = styled.div``;

const CommentInputWrapper = styled.div`
  margin-top: 16px;
`;

const TextBoxBottom = styled.div`
  margin-top: 32px;
  padding: 0 16px;
`;

const LikeButtonWrapper = styled.div`
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Count = styled.span`
  font-size: 0.725rem;
  font-weight: 600;
  margin-left: 12px;
  color: #999;
`;

const BackMenuListTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BackMenuListText = styled.div`
  font-size: 0.825rem;
  color: #151e2e;
  background-color: #f2f2f2;
  border: 1px solid #eaeaea;
  border-radius: 3px;
  padding: 6px;
`;
