import { gql } from "@apollo/client";

export const ADD_POST_LIKE = gql`
  mutation addPostLike($id: ID!) {
    addPostLike(postId: $id) {
      id
    }
  }
`;

export const DELETE_POST_LIKE = gql`
  mutation deletePostLike($id: ID!) {
    deletePostLike(postId: $id) {
      id
    }
  }
`;
