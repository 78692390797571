import React from "react";
import styled from "styled-components";
import KakaoAdFitBox from "../../../ad/KakaoAdFitBox";
import BackHeader from "../../../basic/header/BackHeader";
import NoticeBox from "../../../box/NoticeBox";
import Layout from "../../../container/Layout";

interface Props {
  Tab: React.ReactElement;
  List: React.ReactElement;
}

const TreasureListPageTemplate: React.FC<Props> = ({ Tab, List }) => {
  return (
    <Layout Header={<BackHeader title="✨ 보물 티어" />}>
      <Container>
        <Top>
          <UpdateText>UPDATE 2021.05.15</UpdateText>

          <NoticeWrapper>
            <NoticeBox text="커뮤니티, 유튜브 등 다양한 의견을 종합한 주관적 티어 분류입니다." />
          </NoticeWrapper>

          <TopAdWrapper>
            <KakaoAdFitBox width="320" height="100" unit="DAN-0ExLSxuqDYCbe0FS" name="treasure-list-adfit-top" />
          </TopAdWrapper>
        </Top>

        <TabListWrapper>{Tab}</TabListWrapper>
        <ListWrapper>{List}</ListWrapper>

        <BottomAdWrapper>
          <KakaoAdFitBox width="320" height="100" unit="DAN-3F8SCEf82HEhMYb6" name="treasure-list-adfit-bottom" />
        </BottomAdWrapper>
      </Container>
    </Layout>
  );
};

export default TreasureListPageTemplate;

const Container = styled.div``;

const Top = styled.div`
  padding: 20px;
  background-color: #fff;
`;

const TabListWrapper = styled.div`
  margin-top: 8px;
  padding-top: 4px;
  background-color: #fff;
  border-bottom: 1px solid #eaeaea;
`;

const ListWrapper = styled.div`
  background-color: #fff;
`;

const NoticeWrapper = styled.div`
  margin-top: 16px;
`;

const UpdateText = styled.p`
  font-size: 0.725rem;
  margin-top: 0;
  margin-bottom: 0;
  color: #444;
`;

const TopAdWrapper = styled.div`
  margin-top: 16px;
  position: relative;
  height: 100px;
`;

const BottomAdWrapper = styled.div`
  background-color: #fff;
  padding-top: 16px;
  padding-bottom: 16px;
  height: 100px;
`;
