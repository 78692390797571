import React from "react";
import styled from "styled-components";
import { ICookieTopping } from "../../../../../apis/cookie/cookie.topping.types";
import { ICookie } from "../../../../../apis/cookie/cookie.type";

interface Props {
  cookie: ICookie | null;
  active?: boolean;
  title?: string;
  onClickCookieImage?: () => void;
  onClickEmptySlot?: () => void;
  topping: ICookieTopping | null;
}

const DeckCookieSlot: React.FC<Props> = ({ cookie, active, title, onClickCookieImage, onClickEmptySlot, topping }) => {
  return (
    <CookieSlot>
      {title && <CookieSlotIndex>{title}</CookieSlotIndex>}
      {cookie ? (
        <SelectedCookieImageWrapper focus={active} onClick={onClickCookieImage}>
          <SelectedCookieImage src={cookie.thumbnail} />
          {topping && (
            <ToppingWrapper>
              <Topping src={topping.thumbnail} />
            </ToppingWrapper>
          )}
        </SelectedCookieImageWrapper>
      ) : (
        <EmptyCookieSlot focus={active} onClick={onClickEmptySlot}>
          <span>+</span>
        </EmptyCookieSlot>
      )}
    </CookieSlot>
  );
};

export default DeckCookieSlot;

const cookieImageSize = 50;

const CookieSlot = styled.div``;

const CookieSlotIndex = styled.div`
  text-align: center;
  font-size: 0.625rem;
  font-weight: bold;
  color: #fff;
  margin-bottom: 6px;
`;

const EmptyCookieSlot = styled.div<{ focus?: boolean }>`
  border: 3px solid ${(props) => (props.focus ? "#fff" : "#333436")};
  background-color: #4d4e5a;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${cookieImageSize}px;
  width: ${cookieImageSize}px;
  border-radius: 12px;
  padding: 2px;
`;

const SelectedCookieImageWrapper = styled.div<{ focus?: boolean }>`
  position: relative;
  border: 3px solid ${(props) => (props.focus ? "#fff" : "#b37314")};
  background-color: #fff;
  height: ${cookieImageSize}px;
  width: ${cookieImageSize}px;
  border-radius: 12px;
  padding: 2px;
`;

const SelectedCookieImage = styled.img`
  height: ${cookieImageSize}px;
  width: ${cookieImageSize}px;
  border-radius: 10px;
`;

const ToppingWrapper = styled.div`
  position: absolute;
  top: 50px;
  left: 14px;
  z-index: 4;
`;

const Topping = styled.img`
  height: 38px;
  width: 26.5px;
`;
