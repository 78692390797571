import React from "react";
import styled from "styled-components";
import { IComment } from "../../../../apis/common/comment.types";
import { IUser } from "../../../../types/IUser";
import CookieCommentListItem from "./CookieCommentListItem";

interface Props {
  loginUser?: IUser | null;
  items: IComment[];
  onDelete?: (item: IComment) => void;
}

const CookieCommentList: React.FC<Props> = ({ loginUser, items, onDelete }) => {
  return (
    <CookieCommentListBlock>
      {items.map((item, index) => (
        <CookieCommentListItem
          item={item}
          key={`cookie-comment-${index}`}
          onDelete={loginUser && loginUser.id.toString() === item.user.id ? onDelete : undefined}
        />
      ))}
    </CookieCommentListBlock>
  );
};

export default CookieCommentList;

const CookieCommentListBlock = styled.div``;
