import { gql } from "@apollo/client";

export const GET_COUPONS = gql`
  query coupons {
    coupons {
      id
      name
      code
      reward
      comment
    }
  }
`;
