import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { ICookieDeck } from "../../../../../apis/deck/deck.types";
import BackHeader from "../../../../basic/header/BackHeader";
import Layout from "../../../../container/Layout";
import DeckCookieSlot from "../common/DeckCookieSlot";
import { Link } from "react-router-dom";
import PostUserInformation from "../../../../common/PostUserInformation";

interface Props {
  DeleteButton?: React.ReactElement;
  item: ICookieDeck;
  LikeButton: React.ReactElement;
  CommentInput: React.ReactElement;
  CommentList: React.ReactElement;
}

const CookieDeckDetailPageTemplate: React.FC<Props> = ({
  DeleteButton,
  item,
  LikeButton,
  CommentInput,
  CommentList,
}) => {
  const history = useHistory();

  const handleBack = () => {
    history.push("/decks");
  };

  return (
    <Layout Header={<BackHeader title="덱" onClick={handleBack} Right={DeleteButton} />}>
      <Container>
        <DeckSlotWithContent>
          <DeckList>
            <DeckCookieSlot cookie={item.cookieA} topping={item.toppingA} />
            <DeckCookieSlot cookie={item.cookieB} topping={item.toppingB} />
            <DeckCookieSlot cookie={item.cookieC} topping={item.toppingC} />
            <DeckCookieSlot cookie={item.cookieD} topping={item.toppingD} />
            <DeckCookieSlot cookie={item.cookieE} topping={item.toppingE} />
          </DeckList>

          <TextContainerWrapper>
            <TextContainer>
              <TitleWrapper>
                <Title>{item.title}</Title>
                <PostUserInformation nickname={item.user && item.user.username} date={item.created_at} />
              </TitleWrapper>

              <ContentWrapper>
                <Content>{item.content}</Content>
              </ContentWrapper>
            </TextContainer>

            <Bottom>
              <LikeButtonWrapper>{LikeButton}</LikeButtonWrapper>
              <BackMenuListTextWrapper>
                <Link to="/decks">
                  <BackMenuListText>덱 목록으로</BackMenuListText>
                </Link>
                <div>
                  <Count>조회 {item.viewCount || 0}</Count>
                  <Count>추천 {item.likeCount || 0}</Count>
                </div>
              </BackMenuListTextWrapper>
            </Bottom>
          </TextContainerWrapper>
        </DeckSlotWithContent>

        <Box>
          <BoxTitle>댓글</BoxTitle>
          <CommentListWrapper>{CommentList}</CommentListWrapper>
          <CommentInputWrapper>{CommentInput}</CommentInputWrapper>
        </Box>
      </Container>
    </Layout>
  );
};

export default CookieDeckDetailPageTemplate;

const Container = styled.div``;

const DeckList = styled.div`
  padding: 20px 10px;
  padding-bottom: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #151e2e;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.8);
`;

const DeckSlotWithContent = styled.div`
  background-color: #fff;
`;

const TextContainerWrapper = styled.div`
  min-height: 480px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
`;

const TextContainer = styled.div``;

const TitleWrapper = styled.div``;

const Title = styled.h3`
  font-size: 1.5rem;
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 16px;
`;

const ContentWrapper = styled.div`
  border-top: 1px solid #eaeaea;
`;

const Content = styled.p`
  font-family: sans-serif;
  font-size: 1rem;
  line-height: 1.7;
  white-space: pre-line;
`;

const Bottom = styled.div`
  margin-top: 32px;
`;

const LikeButtonWrapper = styled.div`
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Count = styled.span`
  font-size: 0.725rem;
  font-weight: 600;
  margin-left: 12px;
  color: #999;
`;

const BackMenuListTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BackMenuListText = styled.div`
  font-size: 0.825rem;
  color: #151e2e;
  background-color: #f2f2f2;
  border: 1px solid #eaeaea;
  border-radius: 3px;
  padding: 6px;
`;

const Box = styled.div`
  background-color: #fff;
  padding: 20px;
  margin-top: 8px;
`;

const BoxTitle = styled.h6`
  margin-top: 0;
  margin-bottom: 16px;
  color: #888;
`;

const CommentListWrapper = styled.div``;

const CommentInputWrapper = styled.div`
  margin-top: 16px;
`;
