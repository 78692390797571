import React from "react";
import styled from "styled-components";
import { format } from "date-fns";

interface Props {
  nickname: string;
  date: string;
}

const PostUserInformation: React.FC<Props> = ({ nickname, date }) => {
  return (
    <PostUserInformationBlock>
      <ProfileImage>
        <span>U</span>
      </ProfileImage>
      <div>
        <Name>{nickname}</Name>
        <DateTime>{format(new Date(date), "yyyy.MM.dd HH:mm")}</DateTime>
      </div>
    </PostUserInformationBlock>
  );
};

export default PostUserInformation;

const PostUserInformationBlock = styled.div`
  display: flex;
`;

const ProfileImage = styled.div`
  background-color: #f2f2f2;
  border-radius: 100%;
  border: 1px solid #eaeaea;
  height: 38px;
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.925rem;
  font-weight: bold;
  color: #999;
  margin-right: 8px;
`;

const Name = styled.div`
  font-family: sans-serif;
  font-size: 0.925rem;
  color: #000;
`;

const DateTime = styled.div`
  margin-top: 2px;
  font-family: sans-serif;
  font-size: 0.8rem;
  color: #949494;
  padding-bottom: 16px;
`;
