import React from "react";
import styled from "styled-components";
import LikeIcon from "../../../../icon/LikeIcon";

interface Props {
  count?: number;
  liked?: boolean;
  onLike?: () => void;
  onLikeCancel?: () => void;
}

const CookieDeckLikeButton: React.FC<Props> = ({ liked = false, count = 0, onLike, onLikeCancel }) => {
  const handleClick = () => {
    liked ? onLikeCancel && onLikeCancel() : onLike && onLike();
  };

  return (
    <CookieDeckLikeButtonBlock>
      <Container active={liked} onClick={handleClick}>
        <LikeIcon active={liked} size={24} color={liked ? activeColor : inActiveColor} />{" "}
        <Count active={liked}>{count}</Count>
      </Container>
    </CookieDeckLikeButtonBlock>
  );
};

export default CookieDeckLikeButton;

const activeColor = "#f66";
const inActiveColor = "#ccc";

const CookieDeckLikeButtonBlock = styled.div``;

const Container = styled.div<{ active?: boolean }>`
  height: 40px;
  width: 100px;
  border: 2px solid ${(props) => (props.active ? activeColor : inActiveColor)};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Count = styled.div<{ active?: boolean }>`
  margin-left: 8px;
  color: ${(props) => (props.active ? activeColor : inActiveColor)};
  font-size: 1.25rem;
  font-weight: bold;
`;
