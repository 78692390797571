import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { IPatchNote } from "../../../apis/patchnote/patchnote.type";

interface Props {
  items: IPatchNote[];
}

const HomePatchNoteList: React.FC<Props> = ({ items }) => {
  return (
    <HomePatchNoteListBlock>
      {items.map((item) => (
        <Link to={`/patchnote/${item.id}`} key={`home-patch-note-${item.id}`}>
          <ListItemWrapper>
            <ListItem>
              <Date>{item.date}</Date>
              <Title>{item.title}</Title>
            </ListItem>
          </ListItemWrapper>
        </Link>
      ))}
    </HomePatchNoteListBlock>
  );
};

export default HomePatchNoteList;

const HomePatchNoteListBlock = styled.div`
  border-radius: 6px;

  a {
    color: #000;
  }
`;

const ListItemWrapper = styled.div`
  margin: 16px 0;
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Title = styled.span`
  font-size: 0.925rem;
`;

const Date = styled.span`
  font-family: sans-serif;
  font-size: 0.725rem;
  font-weight: 400;
  color: #888;
  margin-bottom: 2px;

  @media (max-width: 500px) {
    font-size: 0.685rem;
    font-family: sans-serif;
  }
`;
