import React from "react";
import styled from "styled-components";
import { ICoupon } from "../../../apis/coupon/coupon.types";
import LoadingMessageBox from "../../container/LoadingMessageBox";
import CouponListItem from "./CouponListItem";

interface Props {
  loading: boolean;
  items: ICoupon[];
}

const CouponList: React.FC<Props> = ({ loading, items }) => {
  if (loading) {
    return <LoadingMessageBox />;
  }

  return (
    <div>
      {items.map((item) => (
        <ListItemWrapper key={item.code}>
          <CouponListItem item={item} />
        </ListItemWrapper>
      ))}
    </div>
  );
};

export default CouponList;

const ListItemWrapper = styled.div`
  margin-bottom: 16px;
`;
