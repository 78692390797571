import React, { useEffect } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import BackIcon from "../../icon/BackIcon";
import { useDispatch } from "react-redux";
import { checkToken } from "../../../modules/auth/auth";

interface Props {
  title?: string;
  onClick?: () => void;
  Right?: React.ReactElement;
}

const BackHeader: React.FC<Props> = ({ title, onClick, Right }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkToken());
  }, [dispatch]);

  const handlePressBack = () => {
    onClick ? onClick() : history.push("/");
  };

  return (
    <BackHeaderBlock>
      <BackIconWrapper onClick={handlePressBack}>
        <BackIcon size={24} color="#fff" />
      </BackIconWrapper>

      <Title>{title}</Title>

      <BackIconWrapper>{Right ? Right : ""}</BackIconWrapper>
    </BackHeaderBlock>
  );
};

export default BackHeader;

const BackHeaderBlock = styled.header`
  position: relative;
  z-index: 2;
  background-color: #151e2e;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BackIconWrapper = styled.div`
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2px;
  cursor: pointer;
  line-height: 0;
`;

const Title = styled.h3`
  font-family: "양진체";
  font-weight: normal;
  text-align: center;
  line-height: 1.4;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #fff;

  @media (max-width: 500px) {
    width: 240px;
  }
`;
