import React, { ChangeEvent } from "react";
import styled from "styled-components";

interface Props {
  title: string;
  onChangeTitle: (value: string) => void;
  content: string;
  onChangeContent: (value: string) => void;
}

const CreateCookieTitleWithContent: React.FC<Props> = ({ title, onChangeTitle, content, onChangeContent }) => {
  const handleChangeTitle = (event: ChangeEvent<HTMLInputElement>) => {
    onChangeTitle(event.target.value);
  };

  const handleChangeContent = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onChangeContent(event.target.value);
  };

  return (
    <CreateCookieTitleWithContentBlock>
      <TitleInput
        type="text"
        autoFocus
        autoComplete="off"
        placeholder="제목을 입력해주세요."
        value={title}
        onChange={handleChangeTitle}
      />
      <ContentInput placeholder="내용을 입력해주세요." value={content} onChange={handleChangeContent} />
    </CreateCookieTitleWithContentBlock>
  );
};

export default CreateCookieTitleWithContent;

const CreateCookieTitleWithContentBlock = styled.div`
  width: 100%;
  color: #000;
`;

const TitleInput = styled.input`
  width: calc(100% - 20px);
  font-size: 16px;
  padding: 16px 10px;
  border: 0;
  outline: none;
  border-bottom: 1px solid #eaeaea;
`;

const ContentInput = styled.textarea`
  width: calc(100% - 20px);
  font-size: 16px;
  padding: 16px 10px;
  border: 0;
  outline: none;
  height: 500px;
`;
