import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import BackHeader from "../../../basic/header/BackHeader";
import Layout from "../../../container/Layout";

interface Props {
  Username: React.ReactElement;
  Password: React.ReactElement;
  SubmitButton: React.ReactElement;
}

const LoginPageTemplate: React.FC<Props> = ({ Username, Password, SubmitButton }) => {
  return (
    <Layout Header={<BackHeader title="로그인" />}>
      <Container>
        <Box>
          <InputBoxWrapper>
            <Label>아이디</Label>
            <InputWrapper>{Username}</InputWrapper>
          </InputBoxWrapper>

          <InputBoxWrapper>
            <Label>비밀번호</Label>
            <InputWrapper>{Password}</InputWrapper>
          </InputBoxWrapper>

          <LoginButtonWrapper>{SubmitButton}</LoginButtonWrapper>

          <Link to="/register">
            <RegisterText>회원가입</RegisterText>
          </Link>
        </Box>
      </Container>
    </Layout>
  );
};

export default LoginPageTemplate;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #fff;
`;

const Box = styled.div`
  height: 600px;
  width: 100%;
`;

const InputBoxWrapper = styled.div`
  margin: 16px 0;
`;

const Label = styled.label`
  font-size: 0.825rem;
`;

const InputWrapper = styled.div`
  margin-top: 4px;
`;

const LoginButtonWrapper = styled.div`
  margin-top: 24px;
`;

const RegisterText = styled.p`
  margin-top: 24px;
  text-align: center;
  font-size: 0.825rem;
  color: #000;
`;
