import React from "react";
import styled from "styled-components";
import { ITreasure } from "../../../../apis/treasure/treasure.type";
import LoadingMessageBox from "../../../container/EmptyMessageBox";
import TreasureListItem from "./TreasureListItem";

interface Props {
  loading: boolean;
  tierType: string;
  items: ITreasure[];
}

const TreasureList: React.FC<Props> = ({ loading, tierType, items }) => {
  if (loading) {
    return <LoadingMessageBox />;
  }

  return (
    <div>
      {items.map((item, index) => (
        <ListItemWrapper key={`treasure-${index}`}>
          <TreasureListItem tierType={tierType} item={item} />
        </ListItemWrapper>
      ))}
    </div>
  );
};

export default TreasureList;

const ListItemWrapper = styled.div``;
