import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { REGISTER } from "../../apis/auth/register";
import Button from "../../components/button/Button";
import Input from "../../components/common/Input";
import RegisterPageTemplate from "../../components/pages/auth/register/RegisterPageTemplate";

const RegisterPage: React.FC = () => {
  const [register, { data }] = useMutation(REGISTER);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const setToken = (jwt: string) => {
    localStorage.setItem("token", jwt);
  };

  if (data) {
    try {
      const res = data.register;
      const { jwt } = res;
      setToken(jwt);
      window.location.href = "/";
    } catch (error) {}
  }

  const usernameErrorMessages = (): undefined | string => {
    if (!username) {
      return undefined;
    }

    if (username.length < 4) {
      return "아이디는 최소 4글자 이상 입력해주세요.";
    }

    if (username.length > 12) {
      return "아이디는 최소 12글자 이하만 가능합니다.";
    }

    return undefined;
  };

  const passwordErrorMessage = (): undefined | string => {
    if (!password) {
      return undefined;
    }

    if (password.length < 6) {
      return "패스워드는 최소 6글자 이상 입력해주세요.";
    }

    return undefined;
  };

  const passwordConfirmErrorMessage = (): undefined | string => {
    if (!passwordConfirm) {
      return undefined;
    }

    if (password !== passwordConfirm) {
      return "패스워드가 일치하지 않습니다.";
    }

    return undefined;
  };

  const handleSubmit = async () => {
    if (usernameErrorMessages() || passwordErrorMessage() || passwordConfirmErrorMessage()) {
      return;
    }

    try {
      localStorage.clear();

      await register({
        variables: {
          input: {
            username,
            password,
            email: `${username}@rok.gg`,
          },
        },
      });
    } catch (error) {
      alert("이미 중복된 아이디입니다.");
    }
  };

  return (
    <RegisterPageTemplate
      Username={
        <Input
          errorMessage={usernameErrorMessages()}
          value={username
            .replace(/ /gi, "")
            .replace(/운영진/gi, "")
            .replace(/관리자/gi, "")
            .replace(/섹스/gi, "")
            .replace(/아이디/gi, "")
            .replace(/비밀번호/gi, "")}
          minLength={4}
          maxLength={12}
          onChange={setUsername}
        />
      }
      Password={
        <Input
          type="password"
          errorMessage={passwordErrorMessage()}
          value={password.replace(/ /gi, "")}
          onChange={setPassword}
        />
      }
      PasswordConfirm={
        <Input
          type="password"
          errorMessage={passwordConfirmErrorMessage()}
          value={passwordConfirm.replace(/ /gi, "")}
          onChange={setPasswordConfirm}
        />
      }
      SubmitButton={<Button disabled={!username || !password} text="회원가입" onClick={handleSubmit} />}
    />
  );
};

export default RegisterPage;
