import React from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import BackHeader from "../../../basic/header/BackHeader";
import Layout from "../../../container/Layout";

interface Props {
  Username: React.ReactElement;
  Password: React.ReactElement;
  PasswordConfirm: React.ReactElement;
  SubmitButton: React.ReactElement;
}

const RegisterPageTemplate: React.FC<Props> = ({ Username, Password, PasswordConfirm, SubmitButton }) => {
  const history = useHistory();

  const handleBack = () => {
    history.push("/login");
  };

  return (
    <Layout Header={<BackHeader title="회원가입" onClick={handleBack} />}>
      <Container>
        <Box>
          <InputBoxWrapper>
            <Label>아이디</Label>
            <InputWrapper>{Username}</InputWrapper>
          </InputBoxWrapper>

          <InputBoxWrapper>
            <Label>비밀번호</Label>
            <InputWrapper>{Password}</InputWrapper>
          </InputBoxWrapper>

          <InputBoxWrapper>
            <Label>비밀번호 재확인</Label>
            <InputWrapper>{PasswordConfirm}</InputWrapper>
          </InputBoxWrapper>

          <SubmitButtonWrapper>{SubmitButton}</SubmitButtonWrapper>

          <Link to="/login">
            <LoginText>로그인하러 가기</LoginText>
          </Link>
        </Box>
      </Container>
    </Layout>
  );
};

export default RegisterPageTemplate;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #fff;
`;

const Box = styled.div`
  height: 600px;
  width: 100%;
`;

const InputBoxWrapper = styled.div`
  margin: 12px 0;
`;

const Label = styled.label`
  font-size: 0.825rem;
`;

const InputWrapper = styled.div`
  margin-top: 4px;
  min-height: 70px;
`;

const SubmitButtonWrapper = styled.div`
  margin-top: 16px;
`;

const LoginText = styled.p`
  margin-top: 24px;
  text-align: center;
  font-size: 0.825rem;
  color: #000;
`;
