import { gql } from "@apollo/client";

export const INCREMENT_POST_VIEW_COUNT = gql`
  mutation postViewCount($id: ID!) {
    postViewCount(id: $id) {
      id
      viewCount
    }
  }
`;

export const INCREMENT_POST_COMMENT_COUNT = gql`
  mutation postCommentCount($id: ID!) {
    postCommentCount(id: $id) {
      id
      commentCount
    }
  }
`;
