import React from "react";
import styled from "styled-components";
import { Grade } from "../../../../apis/common/common.type";
import { ICookie } from "../../../../apis/cookie/cookie.type";
import { convertTier } from "../../../../utils/tier";

interface Props {
  tierType: string;
  item: ICookie;
}

const CookieListItem: React.FC<Props> = ({ tierType, item }) => {
  const getBackgroundColor = () => {
    if (item.grade === Grade.에이션트) {
      return "#8400ff";
    }

    if (item.grade === Grade.에픽) {
      return "#cc379f";
    }

    if (item.grade === Grade.레어) {
      return "#0089ff";
    }

    if (item.grade === Grade.커먼) {
      return "#f4f4f4";
    }

    return "#0089ff";
  };

  const getTier = () => {
    if (tierType === "worldTier") {
      return item.worldTier;
    } else if (tierType === "arenaTier") {
      return item.arenaTier;
    } else if (tierType === "warTier") {
      return item.warTier;
    } else {
      return item.totalTier;
    }
  };

  return (
    <CookieListItemBlock backgroundColor={getBackgroundColor()}>
      {item.thumbnail ? (
        <ThumbnailWrapper>
          <Thumbnail src={item.thumbnail} alt={item.name} />
        </ThumbnailWrapper>
      ) : (
        <EmptyThumbnail>-</EmptyThumbnail>
      )}

      <TextContainer>
        <div>
          <Name>{item.name}</Name>
          <div>
            <AttackType>{item.type}</AttackType>
            <AttackType>{item.position}</AttackType>
          </div>
        </div>

        {getTier() > 100 ? (
          <Tier>
            <TierKor>-</TierKor>
          </Tier>
        ) : (
          <Tier>
            <span>{convertTier(getTier())}</span>
          </Tier>
        )}
      </TextContainer>
    </CookieListItemBlock>
  );
};

export default CookieListItem;

const CookieListItemBlock = styled.div<{ backgroundColor: string }>`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f2f2f7;
  padding: 12px;
`;

const ThumbnailWrapper = styled.div`
  background-color: #fff;
  height: 50px;
  width: 50px;
  border-radius: 4px;
`;

const Thumbnail = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 4px;
`;

const EmptyThumbnail = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.825rem;
  color: #999;
`;

const TextContainer = styled.div`
  flex: 1;
  margin-left: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Name = styled.span`
  font-size: 0.925rem;
  color: #000;
`;

const Tier = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
  color: #000;
  padding-right: 8px;
`;

const TierKor = styled.span`
  font-size: 0.725rem;
  color: #333;
`;

const AttackType = styled.span`
  font-size: 0.625rem;
  color: #444;
  margin-right: 8px;
`;
