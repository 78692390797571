import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { DELETE_COOKIE_DECK, GET_DECK } from "../../apis/deck/deck";
import { INCREMENT_COOKIE_DECK_VIEW_COUNT, INCREMENT_COOKIE_DECK_COMMENT_COUNT } from "../../apis/deck/deck.count";
import { ADD_COOKIE_DECK_LIKE, DELETE_COOKIE_DECK_LIKE } from "../../apis/deck/deck.like";
import { ICookieDeckDetail } from "../../apis/deck/deck.types";
import EmptyLayout from "../../components/container/EmptyLayout";
import LoadingLayout from "../../components/container/LoadingLayout";
import CookieDeckCommentInputBox from "../../components/pages/cookie/deck/detail/CookieDeckCommentInputBox";
import CookieDeckDetailPageTemplate from "../../components/pages/cookie/deck/detail/CookieDeckDetailPageTemplate";
import CookieDeckLikeButton from "../../components/pages/cookie/deck/detail/CookieDeckLikeButton";
import CookieCommentList from "../../components/pages/cookie/detail/CookieCommentList";
import { user } from "../../modules/auth/auth";
import CookieDeckDetailDeleteText from "../../components/pages/cookie/deck/detail/CookieDeckDetailDeleteText";

const CookieDeckDetailPage: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const loginUser = useSelector(user);

  const { loading, data, refetch } = useQuery<{ cookieDeck: ICookieDeckDetail }>(GET_DECK, {
    variables: { id: Number(id) },
    onCompleted: () => {
      try {
        incrementViewCount({
          variables: {
            id: Number(id),
          },
        });
      } catch (error) {
        return;
      }
    },
  });
  const [incrementViewCount] = useMutation(INCREMENT_COOKIE_DECK_VIEW_COUNT);
  const [incrementCommentCount] = useMutation(INCREMENT_COOKIE_DECK_COMMENT_COUNT);
  const [addLike, { loading: addLikeLoading }] = useMutation(ADD_COOKIE_DECK_LIKE);
  const [deleteLike, { loading: deleteLikeLoading }] = useMutation(DELETE_COOKIE_DECK_LIKE);
  const [deleteCookieDeck] = useMutation(DELETE_COOKIE_DECK);

  const handleRefresh = async () => {
    try {
      await incrementCommentCount({
        variables: {
          id: Number(id),
        },
      });

      refetch();
    } catch (error) {
      return;
    }
  };

  const isLiked = () => {
    if (!data || !loginUser) {
      return false;
    }

    try {
      const find = data.cookieDeck.cookieDeckLikeHistories.find(
        (history) => history.user.id === loginUser.id.toString()
      );

      return find ? true : false;
    } catch (error) {
      return false;
    }
  };

  const handleLike = async () => {
    if (!loginUser) {
      alert("로그인 후 이용해보세요");
      history.push(`/login?prev=${window.location.pathname}`);
      return;
    }

    try {
      await addLike({
        variables: {
          id: Number(id),
        },
      });

      refetch();
    } catch (error) {
      return;
    }
  };

  const handleLikeCancel = async () => {
    if (!loginUser) {
      alert("로그인 후 이용해보세요");
      history.push(`/login?prev=${window.location.pathname}`);
      return;
    }

    try {
      await deleteLike({
        variables: {
          id: Number(id),
        },
      });

      refetch();
    } catch (error) {
      return;
    }
  };

  const isMyCookieDeck = () => {
    try {
      if (loginUser && data && loginUser.id.toString() === data.cookieDeck.user.id.toString()) {
        return true;
      }

      return false;
    } catch (error) {
      return false;
    }
  };

  const handleDelete = async () => {
    try {
      const { data } = await deleteCookieDeck({
        variables: {
          id: Number(id),
        },
      });

      if (data) {
        alert("삭제되었습니다.");
        history.push("/decks");
        return;
      }
    } catch (error) {
      alert("잠시 후 다시 시도해주세요.");
      return;
    }
  };

  if (loading) {
    return <LoadingLayout />;
  }

  if (!data || !data.cookieDeck) {
    return <EmptyLayout />;
  }

  return (
    <CookieDeckDetailPageTemplate
      item={data.cookieDeck}
      DeleteButton={isMyCookieDeck() ? <CookieDeckDetailDeleteText onClick={handleDelete} /> : undefined}
      LikeButton={
        <CookieDeckLikeButton
          count={data.cookieDeck.likeCount || 0}
          liked={isLiked()}
          onLike={!addLikeLoading && !deleteLikeLoading ? handleLike : undefined}
          onLikeCancel={!addLikeLoading && !deleteLikeLoading ? handleLikeCancel : undefined}
        />
      }
      CommentInput={
        <CookieDeckCommentInputBox cookieDeckId={data.cookieDeck.id} user={loginUser} onRefresh={handleRefresh} />
      }
      CommentList={<CookieCommentList items={data.cookieDeck.cookieDeckComments} />}
    />
  );
};

export default CookieDeckDetailPage;
