import React from "react";
import styled from "styled-components";

interface Props {
  title?: string;
  onClick?: () => void;
}

const CookieDeckDetailDeleteText: React.FC<Props> = ({ title = "삭제", onClick }) => {
  return <Text onClick={onClick}>{title}</Text>;
};

export default CookieDeckDetailDeleteText;

const Text = styled.div`
  font-size: 0.825rem;
  font-weight: bold;
  color: #fff;
`;
