import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { isProduction } from "./config/basic";
import GA4React from "ga-4-react";

const App = () => {
  const location = useLocation();

  useEffect(() => {
    if (isProduction) {
      const path = location.pathname;
      const gaId = "G-MP3T5MX97G";
      const ga4react = new GA4React(gaId);
      ga4react.pageview(path);
      ga4react.gtag("config", gaId, {
        page_title: path,
        page_location: path,
      });
    }
  }, [location]);

  return <div />;
};

export default App;
