import decode from "jwt-decode";

export const isTokenExpired = (token: string | null) => {
  try {
    if (!token) {
      return true;
    }

    const decoded: any = decode(token);
    if (decoded.exp < Date.now() / 1000) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return true;
  }
};

export const tokenDecode = (token: string | null) => {
  try {
    if (!token) {
      return null;
    }

    const decoded: any = decode(token);
    return decoded;
  } catch (err) {
    return null;
  }
};
