import React from "react";
import styled from "styled-components";

interface Props {
  onClick?: () => void;
}

const CreatePostTextSaveButton: React.FC<Props> = ({ onClick }) => {
  return <CreatePostTextSaveButtonBlock onClick={onClick}>저장</CreatePostTextSaveButtonBlock>;
};

export default CreatePostTextSaveButton;

const CreatePostTextSaveButtonBlock = styled.div`
  font-size: 0.925rem;
  font-weight: bold;
  color: #fff;
  padding-right: 8px;
`;
