import React from "react";
import { IconBaseProps } from "react-icons";
import { BiLike } from "react-icons/bi";
import { AiTwotoneLike } from "react-icons/ai";

interface Props extends IconBaseProps {
  active?: boolean;
}

const LikeIcon: React.FC<Props> = ({ active = false, size, color }) => {
  if (active) {
    return <AiTwotoneLike size={size} color={color} />;
  }

  return <BiLike size={size} color={color} />;
};

export default LikeIcon;
