import React from "react";
import { IconBaseProps } from "react-icons";
import { FiMenu } from "react-icons/fi";

interface Props extends IconBaseProps {}

const MenuIcon: React.FC<Props> = ({ size, color }) => {
  return <FiMenu size={size} color={color} />;
};

export default MenuIcon;
