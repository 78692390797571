import React from "react";
import styled from "styled-components";

const LoadingMessageBox = () => {
  return <LoadingMessageBoxBlock>🍪 데이터 로딩중...</LoadingMessageBoxBlock>;
};

export default LoadingMessageBox;

const LoadingMessageBoxBlock = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 100px;
  height: 400px;
  width: 100%;
`;
