import React from "react";
import styled from "styled-components";

interface Props {
  value: string;
  onChange: (value: string) => void;
}

const CookieListPositionTab: React.FC<Props> = ({ value, onChange }) => {
  return (
    <CookieListSubTabBlock>
      {["전체", "전방", "중앙", "후방"].map((v, index) => (
        <Button
          key={`cookie-position-tab-${index}`}
          active={v === value ? true : false}
          onClick={() => {
            onChange(v);
          }}
        >
          <ButtonText active={v === value ? true : false}>{v}</ButtonText>
        </Button>
      ))}
    </CookieListSubTabBlock>
  );
};

export default CookieListPositionTab;

const CookieListSubTabBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Button = styled.div<{ active: boolean }>`
  border: 2px solid ${(props) => (props.active ? "#333" : "#eaeaea")};
  background-color: ${(props) => (props.active ? "#fff" : "#f2f2f2")};
  border-radius: 6px;
  padding: 5px 12px;
  margin: 5px;
  cursor: pointer;
`;

const ButtonText = styled.span<{ active: boolean }>`
  font-size: 0.825rem;
  font-weight: bold;
  color: ${(props) => (props.active ? "#333" : "#888")};
`;
