import React from "react";
import styled from "styled-components";

interface Props {
  onClick?: () => void;
}

const PostDetailTextEditButton: React.FC<Props> = ({ onClick }) => {
  return <PostDetailTextEditButtonBlock onClick={onClick}>수정</PostDetailTextEditButtonBlock>;
};

export default PostDetailTextEditButton;

const PostDetailTextEditButtonBlock = styled.div`
  font-size: 0.925rem;
  font-weight: bold;
  color: #fff;
  padding-right: 8px;
`;
