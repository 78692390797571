import React, { useEffect, useState } from "react";
import { APIGetPatchNoteList } from "../apis/patchnote/patchnote";
import { APIGetYoutubeList } from "../apis/youtube/youtube";
import { IYoutube } from "../apis/youtube/youtube.types";
import HomePageTemplate from "../components/pages/home/HomePageTemplate";
import HomePatchNoteList from "../components/pages/home/HomePatchNoteList";
import HomeYoutubeList from "../components/pages/home/HomeYoutubeList";

const patchNotes = APIGetPatchNoteList().slice(0, 2);
const youtubeList = APIGetYoutubeList();

const HomePage: React.FC = () => {
  const [youtubueItems, setYoutubeItems] = useState<IYoutube[]>([]);

  useEffect(() => {
    const length = youtubeList.length;
    const random = Math.floor(Math.random() * (length + 1));
    const previewSize = 3;

    if (random < previewSize) {
      setYoutubeItems(youtubeList.slice(0, previewSize));
    } else if (random > length - previewSize) {
      setYoutubeItems(youtubeList.slice(7, 7 + previewSize));
    } else {
      setYoutubeItems(youtubeList.slice(random, random + previewSize));
    }
  }, []);

  return (
    <HomePageTemplate
      PatchNote={<HomePatchNoteList items={patchNotes} />}
      Youtube={<HomeYoutubeList items={youtubueItems} />}
    />
  );
};

export default HomePage;
