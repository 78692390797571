import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { USER_ME } from "../apis/auth/me";

interface Props extends RouteProps {}

const PrivateRoute: React.FC<Props> = (restProps: Props) => {
  const { data, loading } = useQuery(USER_ME);

  if (loading) {
    return <div />;
  }

  if (!loading && data && data.me) {
    return <Route {...restProps} />;
  }

  return <Redirect to="/login" />;
};

export default PrivateRoute;
