import React from "react";
import styled from "styled-components";
import { Grade } from "../../../../apis/common/common.type";
import { ITreasure } from "../../../../apis/treasure/treasure.type";
import { convertTier } from "../../../../utils/tier";

interface Props {
  tierType: string;
  item: ITreasure;
}

const TreasureListItem: React.FC<Props> = ({ item }) => {
  const getGradeColor = () => {
    if (item.grade === Grade.에이션트) {
      return "#8400ff";
    }

    if (item.grade === Grade.에픽) {
      return "#cc379f";
    }

    if (item.grade === Grade.레어) {
      return "#0089ff";
    }

    if (item.grade === Grade.커먼) {
      return "#999";
    }

    return "#0089ff";
  };

  const getGradeName = () => {
    if (item.grade === Grade.에픽) {
      return "에픽";
    }

    if (item.grade === Grade.레어) {
      return "레어";
    }

    if (item.grade === Grade.커먼) {
      return "일반";
    }

    return "-";
  };

  const getTier = () => {
    return item.totalTier;
  };

  const backgroundColor = "#f7faff";

  return (
    <TreasureListItemBlock backgroundColor={backgroundColor}>
      {item.thumbnail ? <Thumbnail src={item.thumbnail} alt={item.name} /> : <EmptyThumbnail>-</EmptyThumbnail>}

      <TextContainer>
        <div>
          <GradeName color={getGradeColor()}>{getGradeName()}</GradeName>
          <Name>{item.name}</Name>
          <Level1>{item.level1}</Level1>
        </div>

        <TierWrapper>
          <Tier>{convertTier(getTier())}</Tier>
        </TierWrapper>
      </TextContainer>
    </TreasureListItemBlock>
  );
};

export default TreasureListItem;

const TreasureListItemBlock = styled.div<{ backgroundColor: string }>`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f2f2f7;
  padding: 12px;
`;

const Thumbnail = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 4px;
`;

const EmptyThumbnail = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.825rem;
  color: #999;
`;

const TextContainer = styled.div`
  flex: 1;
  margin-left: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Name = styled.span`
  font-size: 0.875rem;
  color: #000;
`;

const Level1 = styled.p`
  margin: 4px 0;
  font-size: 0.685rem;
  color: #999;
  line-height: 1.4;
`;

const TierWrapper = styled.div`
  text-align: center;
  width: 50px;
`;

const Tier = styled.span`
  font-size: 1.25rem;
  font-weight: bold;
  color: #000;
`;

const GradeName = styled.div<{ color: string }>`
  font-weight: 600;
  font-size: 0.7rem;
  color: ${(props) => props.color};
  margin-bottom: 2px;
`;
