import React from "react";
import styled from "styled-components";
import KakaoAdFitBox from "../../ad/KakaoAdFitBox";
import BackHeader from "../../basic/header/BackHeader";
import Layout from "../../container/Layout";

interface Props {
  List: React.ReactElement;
}

const CouponListPageTemplate: React.FC<Props> = ({ List }) => {
  return (
    <Layout Header={<BackHeader title="쿠폰 목록" />}>
      <Container>
        <TitleWrapper>
          <Title>쿠키런 킹덤</Title>
        </TitleWrapper>

        <TopAdWrapper>
          <KakaoAdFitBox width="320" height="50" unit="DAN-ZeEIYadW8bHNF5KE" name="coupon-list-adfit-top" />
        </TopAdWrapper>

        <a href="https://game.devplay.com/coupon/ck/ko">
          <CouponRegisterButtonBox>
            <CouponImageWrapper>
              <img src="https://image.rok.gg/cookie/common/kingdom.png" height={60} width={60} alt="킹덤" />
            </CouponImageWrapper>
            <div>
              <CouponButtonText>쿠폰 등록하러 가기</CouponButtonText>
              <CouponSite>https://game.devplay.com/coupon/ck/ko</CouponSite>
            </div>
          </CouponRegisterButtonBox>
        </a>
        <ListWrapper>{List}</ListWrapper>
      </Container>
    </Layout>
  );
};

export default CouponListPageTemplate;

const Container = styled.div`
  background-color: #fff;
  min-height: 600px;
  padding: 20px 0;
`;

const TitleWrapper = styled.div`
  padding: 0 20px;
`;

const Title = styled.h3`
  color: #000;
`;

const TopAdWrapper = styled.div`
  padding: 0 20px;
  margin-top: 8px;
  position: relative;
  height: 50px;
`;

const CouponRegisterButtonBox = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  padding: 0 20px;
`;

const CouponImageWrapper = styled.div`
  margin-right: 16px;
`;

const CouponButtonText = styled.p`
  margin: 0;
  font-size: 1rem;
  color: #000;
`;

const CouponSite = styled.p`
  margin-top: 4px;
  margin-bottom: 8px;
  font-size: 0.725rem;
  color: #0078ff;
`;

const ListWrapper = styled.div`
  border-top: 8px solid #ebeef1;
  margin-top: 16px;
`;
