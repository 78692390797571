import React from "react";
import styled from "styled-components";
import { ICookieDeck } from "../../../../../apis/deck/deck.types";
import { timeForToday } from "../../../../../utils/date";

interface Props {
  item: ICookieDeck;
}

const DeckListItem: React.FC<Props> = ({ item }) => {
  return (
    <DeckListItemBlock>
      <Inline>
        <Title>{item.title}</Title>
        <DateTime>{timeForToday(item.created_at)}</DateTime>
      </Inline>
      <ImageList>
        <ImageWrapper>
          <PreviewImage src={item.cookieA.thumbnail} />
        </ImageWrapper>
        <ImageWrapper>
          <PreviewImage src={item.cookieB.thumbnail} />
        </ImageWrapper>
        <ImageWrapper>
          <PreviewImage src={item.cookieC.thumbnail} />
        </ImageWrapper>
        <ImageWrapper>
          <PreviewImage src={item.cookieD.thumbnail} />
        </ImageWrapper>
        <ImageWrapper>
          <PreviewImage src={item.cookieE.thumbnail} />
        </ImageWrapper>
      </ImageList>
      <CountWithName>
        <CountWrapper>
          <Count>조회 {item.viewCount || 0}</Count>
          <Count>댓글 {item.commentCount || 0}</Count>
          <Count>추천 {item.likeCount || 0}</Count>
        </CountWrapper>
        <div>
          <Username>{item.user.username}</Username>
        </div>
      </CountWithName>
    </DeckListItemBlock>
  );
};

export default DeckListItem;

const DeckListItemBlock = styled.div`
  padding: 20px;
`;

const Inline = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  flex: 1;
  font-size: 1rem;
  line-height: 1.1;
  font-weight: bold;
  color: #000;
`;

const DateTime = styled.div`
  width: 60px;
  text-align: right;
  font-size: 0.725rem;
  line-height: 1.1;
  color: #666;
`;

const ImageList = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
`;

const ImageWrapper = styled.div`
  margin-right: 8px;
`;

const PreviewImage = styled.img`
  height: 38px;
  width: 38px;
  border-radius: 100%;
  border: 2px solid #b37314;
  padding: 4px;
`;

const CountWithName = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CountWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Count = styled.div`
  font-size: 0.725rem;
  color: #666;
  margin-right: 8px;
`;

const Username = styled.div`
  text-align: right;
  width: 100px;
  font-size: 0.625rem;
  font-weight: 600;
  color: #8bc1ff;
`;
