import { gql } from "@apollo/client";

export const SAVE_POST_COMMENT = gql`
  mutation createPostComment($input: createPostCommentInput!) {
    createPostComment(input: $input) {
      postComment {
        content
      }
    }
  }
`;

export const DELETE_POST_COMMENT = gql`
  mutation myPostCommentDelete($id: ID!) {
    myPostCommentDelete(id: $id) {
      postComment {
        content
      }
    }
  }
`;
