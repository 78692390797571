import React from "react";
import { IconBaseProps } from "react-icons";
import { IoAlertCircleSharp } from "react-icons/io5";

interface Props extends IconBaseProps {}

const NoticeIcon: React.FC<Props> = ({ size, color }) => {
  return <IoAlertCircleSharp size={size} color={color} />;
};

export default NoticeIcon;
