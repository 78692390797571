import React, { useCallback, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_TIP_POSTS, POST_CATEGORY } from "../../apis/post/post";
import { IPost } from "../../apis/post/post.types";
import PostList from "../../components/pages/post/list/PostList";
import PostListPageTemplate from "../../components/pages/post/list/PostListPageTemplate";
import Tab from "../../components/common/Tab";
import { useSelector } from "react-redux";
import { user } from "../../modules/auth/auth";
import queryString from "query-string";
import { useLocation, useHistory } from "react-router";

const sort = "created_at:DESC";

const tabs = [
  {
    name: "유저 팁",
    value: POST_CATEGORY.유저팁,
  },
  {
    name: "BEST 꿀팁",
    value: POST_CATEGORY.베스트팁,
  },
];

const PostListPage: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const [category, setCategory] = useState<string>(POST_CATEGORY.유저팁);
  const loginUser = useSelector(user);

  const { loading, data, refetch } = useQuery<{ posts: IPost[] }>(GET_TIP_POSTS, {
    variables: { sort, where: { category } },
  });

  const handleChangeTab = useCallback(
    (value: string) => {
      history.push("/posts?category=" + value);
    },
    [history]
  );

  const handleCategoryWithFetch = useCallback(
    (value: string) => {
      setCategory(value);
      refetch({
        where: { category: value },
      });
    },
    [refetch]
  );

  useEffect(() => {
    const search: { category?: string } = queryString.parse(location.search);
    if (typeof search.category === "string") {
      handleCategoryWithFetch(search.category);
    }
  }, [handleCategoryWithFetch, location]);

  return (
    <PostListPageTemplate
      isLoggedIn={!!loginUser}
      Tab={<Tab value={category} onChange={handleChangeTab} items={tabs} />}
      List={<PostList category={category} loading={loading} items={data ? data.posts : []} />}
    />
  );
};

export default PostListPage;
