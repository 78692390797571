import React from "react";
import { useHistory, useParams } from "react-router";
import { IPostDetail } from "../../apis/post/post.types";
import PostDetailTemplate from "../../components/pages/post/detail/PostDetailTemplate";
import { GET_POST } from "../../apis/post/post";
import { useMutation, useQuery } from "@apollo/client";
import EmptyLayout from "../../components/container/EmptyLayout";
import LoadingLayout from "../../components/container/LoadingLayout";
import PostCommentInputBox from "../../components/pages/post/detail/PostCommentInputBox";
import { user } from "../../modules/auth/auth";
import { useSelector } from "react-redux";
import CookieCommentList from "../../components/pages/cookie/detail/CookieCommentList";
import { INCREMENT_POST_COMMENT_COUNT, INCREMENT_POST_VIEW_COUNT } from "../../apis/post/post.count";
import { DELETE_POST_COMMENT } from "../../apis/post/post.comment";
import { IComment } from "../../apis/common/comment.types";
import CookieDeckLikeButton from "../../components/pages/cookie/deck/detail/CookieDeckLikeButton";
import { ADD_POST_LIKE, DELETE_POST_LIKE } from "../../apis/post/post.like";
import PostDetailTextEditButton from "../../components/pages/post/detail/PostDetailTextEditButton";

const PostDetailPage: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const loginUser = useSelector(user);

  const { loading, data, refetch } = useQuery<{ post: IPostDetail }>(GET_POST, {
    variables: {
      id: Number(id),
    },
    onCompleted: () => {
      incrementViewCount({
        variables: {
          id: Number(id),
        },
      });
    },
  });

  const [incrementViewCount] = useMutation(INCREMENT_POST_VIEW_COUNT);
  const [incrementCommentCount] = useMutation(INCREMENT_POST_COMMENT_COUNT);
  const [deleteComment] = useMutation(DELETE_POST_COMMENT);
  const [addLike, { loading: addLikeLoading }] = useMutation(ADD_POST_LIKE);
  const [deleteLike, { loading: deleteLikeLoading }] = useMutation(DELETE_POST_LIKE);

  const handleRefresh = async () => {
    try {
      await incrementCommentCount({
        variables: {
          id: Number(id),
        },
      });

      refetch();
    } catch (error) {
      return;
    }
  };

  if (loading) {
    return <LoadingLayout />;
  }

  if (!data || !data.post) {
    return <EmptyLayout />;
  }

  const handleDeleteComment = async (item: IComment) => {
    const isOk = window.confirm("삭제하시겠습니까?");

    if (!isOk) {
      return;
    }

    try {
      await deleteComment({
        variables: {
          id: Number(item.id),
        },
      });

      refetch();

      await incrementCommentCount({
        variables: {
          id: Number(id),
        },
      });
    } catch (error) {}
  };

  const isLiked = () => {
    if (!data || !loginUser) {
      return false;
    }

    try {
      const find = data.post.postLikeHistories.find((history) => history.user.id === loginUser.id.toString());

      return find ? true : false;
    } catch (error) {
      return false;
    }
  };

  const handleLike = async () => {
    if (!loginUser) {
      alert("로그인 후 이용해보세요");
      history.push(`/login?prev=${window.location.pathname}`);
      return;
    }

    try {
      await addLike({
        variables: {
          id: Number(id),
        },
      });

      refetch();
    } catch (error) {
      return;
    }
  };

  const handleLikeCancel = async () => {
    if (!loginUser) {
      alert("로그인 후 이용해보세요");
      history.push(`/login?prev=${window.location.pathname}`);
      return;
    }

    try {
      await deleteLike({
        variables: {
          id: Number(id),
        },
      });

      refetch();
    } catch (error) {
      return;
    }
  };

  const handleEdit = () => {
    history.push(`/create/post/${data.post.id}`);
  };

  const isEditable = () => {
    if (data.post && loginUser && data.post.user.id.toString() === loginUser.id.toString()) {
      return true;
    }

    return false;
  };

  return (
    <PostDetailTemplate
      item={data.post}
      EditButton={isEditable() ? <PostDetailTextEditButton onClick={handleEdit} /> : undefined}
      LikeButton={
        <CookieDeckLikeButton
          count={data.post.likeCount || 0}
          liked={isLiked()}
          onLike={!addLikeLoading && !deleteLikeLoading ? handleLike : undefined}
          onLikeCancel={!addLikeLoading && !deleteLikeLoading ? handleLikeCancel : undefined}
        />
      }
      CommentInput={<PostCommentInputBox postId={data.post.id} user={loginUser} onRefresh={handleRefresh} />}
      CommentList={
        <CookieCommentList items={data.post.postComments || []} loginUser={loginUser} onDelete={handleDeleteComment} />
      }
    />
  );
};

export default PostDetailPage;
