import { gql } from "@apollo/client";

export const INCREMENT_COOKIE_VIEW_COUNT = gql`
  mutation cookieViewCount($id: ID!) {
    cookieViewCount(id: $id) {
      id
      viewCount
    }
  }
`;
