// import { gql } from "@apollo/client";
import { IPatchNote } from "./patchnote.type";

// export const GET_NOTICES = gql`
//   query notices($type: String!) {
//     notices(type: $type) {
//       id
//       title
//       content
//       date
//       type
//     }
//   }
// `;

// export const GET_NOTICE = gql`
//   query notice($id: Float!) {
//     notice(id: $id) {
//       id
//       title
//       content
//       date
//       type
//     }
//   }
// `;

export const APIGetPatchNoteList = (): IPatchNote[] => {
  return [
    {
      id: 3,
      title: "[패치] 4월 15일(목) 일부 오류 수정 및 변경 패치",
      date: "2021.04.15. 19:01",
      type: "패치노트",
      content: `
      안녕하세요, 쿠키런: 킹덤입니다.

​

4월 15일(목) 패치를 통해 적용된 사항을 안내드리며, 더욱 즐거운 왕국 생활을 위해 노력하겠습니다.

※ 게임에 접속해 계셨던 분들은 게임APP 재접속 시 최신 업데이트 항목을 적용하실 수 있습니다.

최신 업데이트 적용 및 원활한 이용을 위해 게임APP 재접속을 부탁드립니다.

​

■ 독버섯맛 쿠키의 스킬 툴팁 수정에 대한 보상 지급

사전에 공지사항을 통해 안내드린 ‘독버섯맛 쿠키’의 보상이 우편을 통해 지급되었습니다.

☞ 독버섯맛 쿠키의 스킬 툴팁 수정에 대한 보상 지급 안내

기다려주신 분들께 진심으로 감사드리며, 상세 내용은 아래 안내드리는 공지에서 확인을 부탁드립니다.

※ 보상은 게임 내 우편으로 지급되었습니다. 게임에 접속해 계셨던 분들은 재접속 시 보상을 확인하실 수 있습니다.

​

■ 일부 해외 국가의 패키지 가격 오류에 대한 구성품 변경

사전에 공지사항을 통해 안내드린 패키지 3종의 구성품 변경이 진행되었습니다.

☞ [안내] 일부 해외 국가의 패키지 가격 오류에 대한 구성품 변경 및 보상 지급

- 퓨어바닐라 쿠키 출시 기념 진리의 빛 패키지 : 기존 구성품에 ‘크리스탈 1,000개’ 추가

- ‘퓨어바닐라 쿠키’ 첫 획득 축하 패키지 : 기존 구성품에 ‘크리스탈 500개’ 추가

- ‘블랙레이즌맛 쿠키’ 첫 승급 축하 패키지 : 기존 구성품에 ‘크리스탈 500개’ 추가

​

4월 15일(목) 패치를 통해 구성품이 변경되기 전까지 관련 패키지 3종을 정상가에 구매하신 분들께는 ‘구성품 변경으로 추가된 크리스탈’을 구매 수량에 따라 우편으로 지급해드릴 예정이며, 상세 일정은 차주 화요일에 안내 드릴 예정입니다.

※ 상품이 정상 금액으로 변경된 4월 9일(금) 23:30 이전에, 환율이 잘못 적용된 일부 해외 국가 GooglePlay에서 정상가보다 낮은 가격에 구매하신 분들께는 추가 구성품이 지급되지 않습니다.

​

■ <용감한 시즌3> 킹덤패스, 킹덤 아레나의 시즌 기간 표기 수정

<용감한 시즌 3>의 킹덤패스, 킹덤 아레나는 21년 4월 22일 23:59에 시즌이 종료되지만

메인 공지이미지와 이벤트 캘린더에서 종료 기간이 잘못 표기된 부분을 확인하여 정상 일정으로 수정하였습니다.

​

기간 표기 실수로 이용에 불편을 드린 점에 사과드리며

용감한 시즌과 함께 즐거운 왕국 생활이 되실 수 있도록 더욱 노력하겠습니다.

​

1) 메인 공지이미지

· 킹덤패스 : ~ 21년 4월 22일 23:59 (수정)

· 킹덤 아레나 : ~ 21년 4월 22일 23:59 (수정)

​

2) 이벤트 캘린더

· 아레나 시즌 특별 보상 : ~ 21년 4월 22일 23:59(수정)

​

■ 기타

- 친구와 주고받은 일일 무료 선물 메시지가 출력되도록 수정하였습니다.

[출처] 4월 15일(목) 일부 오류 수정 및 변경 패치 (쿠키런 킹덤 공식카페) | 작성자 쿠키런 킹덤`,
    },
    {
      id: 1,
      title: "[패치] 4월 8일(목) 업데이트 안내",
      date: "2021.04.08. 09:49",
      type: "패치노트",
      content: `
안녕하세요, 쿠키런: 킹덤 입니다.

4월 8일(목) 업데이트를 통해 적용된 사항을 안내드리며, 더욱 즐거운 왕국 생활을 위해 노력하겠습니다.

📜 신규 업데이트

■ 신규 에피소드 9, 10 추가

- [일반/어둠] Ep.9 '하늘에 떠있는 성’을 추가하였습니다.

- [일반/어둠] Ep.10 '시간이 멈춘 왕국'을 추가하였습니다.

- 추가된 에피소드 지역으로 곰젤리 열기구 탐사 보내기가 가능합니다.

  · 신규 에피소드 내 스테이지를 모두 클리어하면 탐사가 가능합니다.

- 왕국 정보 > 대표 이미지에 신규 에피소드 ‘어둠모드’ 클리어 시 획득할 수 있는 프로필 4종을 추가하였습니다.

​

■ 후르츠칸 앵무의 해변 교역소 추가

- 왕국의 생산품을 다양한 상품들과 물물거래하는 ‘후르츠칸 앵무의 해변 교역소’를 추가하였습니다.

- 쿠키성 6레벨 이상 달성 시 난파선과 연결된 영토의 확장이 가능합니다.

- 난파선과 연결된 영토를 확장한 후 등장하는 퀘스트를 클리어하면 ‘후르츠칸 앵무의 해변 교역소’ 복구가 가능합니다.

- 교역소에서는 각 상품을 1회씩 물물거래 할 수 있으며, 우측 하단의 재입고 시간에 따라 상품이 갱신됩니다.

■ 첫 번째 에이션트, 퓨어바닐라 쿠키 추가

- 전설의 고대왕국 중 하나인 바닐라 왕국에서 펼쳐지는 모험과 함께 [ANCIENT] 퓨어바닐라 쿠키기 등장하였습니다.

- 퓨어바닐라 쿠키는 '사랑과 평화' 라는 스킬을 구사하며, 전투 중인 아군 쿠키 전체의 체력을 회복시키고 최대 체력의 일정 비율만큼 체력 보호막을 생성하는 회복형 쿠키 입니다.

​

■ 블랙레이즌맛 쿠키 추가

- [EPIC] 블랙레이즌맛 쿠키를 추가하였습니다.

- '그림자 파수꾼'이라는 스킬을 구사하며, 적 진영의 중간에 위치한 적들에게 여러 번의 큰 범위 피해를 입히는 침투형 쿠키입니다.

​

■ 쿠키 최대 레벨 확장

- 쿠키 최대 레벨을 50에서 60으로 확장하였습니다.

- 51~60레벨까지 레벨 별 필요 경험치는 쿠키 레벨 49에서 50으로 상승시키는데에 필요한 양과 모두 동일합니다.

- 쿠키 레벨과 함께 스킬 최대 레벨도 60으로 확장하였습니다.

​

■ 쿠키성, 풍요의 분수, 쿠키 하우스 최대 레벨 확장

- 쿠키성 최대 레벨을 12에서 15로 확장하였습니다.

- 풍요의 분수 최대 레벨이 15로 증가하였으며, 레벨업에 따라 획득 가능한 보샹량이 증가합니다.

- 쿠키 하우스의 최대 레벨과 건설 가능한 개수를 증가하였습니다.

  · 쿠키 하우스 최대레벨: 10 → 15 (레벨업에 따라 생산되는 경험의 별사탕 수량이 증가합니다)

  · 쿠키 하우스 건설개수: 36 → 48 (쿠키성 레벨 상승 시마다 4개씩 추가 건설 가능)

- 쿠키성 레벨 상승과 함께 확장 가능한 왕국 영토를 추가하였습니다.

​

■ 꾸미기 테마 추가

- '평화와 치유의 바닐라 왕국' 테마를 추가하였습니다.

- '복작복작 나무 도감' 테마를 추가하였습니다.

🎁 이벤트

- 퓨어바닐라 쿠키 및 퓨어바닐라 쿠키의 영혼석을 획득할 수 있는 ‘차원뽑기’ 이벤트를 추가하였습니다.

  · 차원뽑기는 이벤트와 상점 등에서 획득할 수 있는 ‘진리의 빛’ 아이템을 모아 쿠키뽑기를 할 수 있는 이벤트입니다.

  · 차원뽑기에서는 퓨어바닐라 쿠키와 퓨어바닐라 쿠키의 영혼석을 일반 뽑기 보다 높은 확률로 획득할 수 있습니다.

  · 차원뽑기 250회마다 퓨어바닐라 쿠키를 확정으로 획득할 수 있습니다.

  · 차원뽑기 이벤트 종료 시 남아 있는 진리의 빛은 일정량의 코인으로 변환될 예정입니다.

- ‘차원뽑기’ 이벤트에 필요한 재료인 ‘진리의 빛’ 획득 미션 이벤트를 추가하였습니다.

- 신규 에피소드를 클리어하고 크리스탈 보상을 획득할 수 있는 ‘신규 에피소드 정복’ 이벤트를 추가하였습니다.

- 1000만 왕국 달성을 기념하여 감사의 보상을 드리는 ‘1000만 왕국 감사제’ 이벤트를 추가하였습니다.

💎 상점

■ 신규 패키지 추가

- 1000만 왕국 기념 감사 패키지를 추가하였습니다.

- 블랙레이즌맛 쿠키의 까마귀 해먹 패키지를 추가하였습니다.

- 퓨어바닐라맛 쿠키의 고요한 바닐라꽃 정원 패키지를 추가하였습니다.

- 퓨어바닐라맛 쿠키 출시기념 진리의 빛 패키지를 추가하였습니다.

- 신규 에피소드 출시기념 크리스탈 대박 패키지를 추가하였습니다.

- 블랙레이즌맛 쿠키 출시 기념 에픽 확정 쿠키 뽑기 패키지 Vol.1 / 2 를 추가하였습니다.

- 블랙레이즌맛 쿠키의 첫 획득 축하 패키지와 첫 승급 축하 패키지를 추가하였습니다.

- 퓨어바닐라맛 쿠키의 첫 획득 축하 패키지와 첫 승급 축하 패키지를 추가하였습니다.

※ 아래 패키지 4종에 포함된 진리의 빛은 차원뽑기 이벤트 종료 후 스태미너 젤리로 변경될 예정입니다.

  · 블랙레이즌맛 쿠키의 첫 (획득/승급) 축하 패키지

  · 퓨어바닐라맛 쿠키의 첫 (획득/승급) 축하 패키지

​

■ 마일리지샵 상품 추가

- 에스프레소맛 쿠키의 영혼석(100 마일리지)을 추가하였습니다.

- 구미호맛 쿠키의 영혼석(100 마일리지)을 추가하였습니다.

​

■ 패키지 구매 조건

- 경험의 별사탕 패키지(실속, 고급, 대용량)의 구매 제한 횟수를 변경하였습니다. (주 1회 → 주 3회)

- 마스터 등급을 달성한 후 강등 되어도 '끝없는 승리 아레나 승급 축하 패키지 (마스터)' 반짝 타임딜이 유지되도록 개선하였습니다.

⭐️ 추가·변경 및 개선

■ 곰젤리 열차

- 한 번에 납품하기 기능을 추가하였습니다.

  · ‘한 번에 납품하기’ 버튼 터치 시 열차에서 요구하는 아이템 중 보유하고 있는 아이템이 한 번에 납품됩니다.

  · 열차에서 요구하는 아이템 중, 요구 수량에 비해 보유 수량이 부족한 아이템은 한 번에 납품되지 않습니다.

- 납품 보상의 일괄 수령 기능을 추가하였습니다.

  · 납품을 마치고 돌아온 열차 터치 시, 보상이 일괄 수령됩니다.

■ 건물 / 생산

- 쿠키성 13레벨에 솜사탕양 목장을 1개 추가로 건설할 수 있게 하였습니다.

- 잠금해제 되지 않은 생산품의 필요 재료를 미리볼 수 있도록 개선하였습니다.

- 생산품의 필요 재료를 만드는 건물이 건설되지 않은 경우, 안내 팝업이 출력되도록 개선하였습니다.

- 생산 중일 때에도 건물 레벨업에 필요한 재화 등 관련 정보를 확인할 수 있도록 개선하였습니다.

- 생산 재료 보유 상태에서 생산품의 ‘제작하기’ 버튼을 길게 터치하면 빈 대기열에 해당 아이템이 일괄 등록되도록 개선하였습니다.

- 생산 완료된 생산품이 대기열에 있을 경우, 추가로 생산을 시도하면 완료된 생산품이 자동 수거되도록 개선하였습니다.

- 건물/생산 등에 크리스탈 300개 이상 사용 시, 크리스탈 사용 여부를 한번 더 확인하는 팝업이 출력되도록 개선하였습니다.

  · 희귀재료 즉시 구입, 생산 재료 즉시 구입, 곰젤리 열차 남은 시간 즉시 완료, 소원나무 다음 소원 기다리기 즉시 완료 등

​

■ 길드

- 길드에서 추방되었을 때, 타 길드 가입 신청을 위한 제한 시간을 완화하였습니다. (24시간 → 1시간)

- 기존의 ‘길드 관리’ 메뉴를 ‘길드 정보’로 변경하였습니다.

- 길드 정보에 ‘길드 편집’ 기능을 추가하였으며, 해당 메뉴에서 길드 이름/소개 변경 및 가입 조건 등을 변경할 수 있습니다.

- 길드 가입 방식 및 조건을 추가하였습니다.

  ① 길드 가입 방식: 자유가입, 초대가입, 승인가입(신규 추가)

  ② 왕국 레벨(신규 추가): 길드 가입 신청자의 왕국 레벨을 조건으로 설정할 수 있습니다.

  ③ 아레나 전투력(신규 추가): 가입 신청자의 아레나 방어덱의 전투력을 조건으로 설정할 수 있습니다.

■ UI

- 이벤트 메뉴에 이벤트 종류에 따른 카테고리를 추가하였습니다.

- 쿠키뽑기 패키지 상품 내 ‘상세 확률’ 및 ‘뽑기 내역’ 조회 기능을 추가하였습니다.

- ‘모험 스테이지 N회 클리어 하기’업적의 문구를 '모험 스테이지 N개 클리어 하기'로 수정하였습니다.

​

■ 길드 토벌전

- 플레이 중 재접속 및 강제 종료(네트워크 에러 포함)되었을 때의 보상 지급 및 입장 조건을 아래와 같이 적용하였습니다.

  ① 레드벨벳 케이크 드래곤에게 피해를 입혔지만 전투가 종료되지 않은 상태에서 비정상 종료 시

  → 비정상 종료 전 까지 입힌 피해량을 점수로 반영, 토벌 준비 횟수 감소

  ② 레드벨벳 케이크 드래곤에게 피해를 입히기 전에 비정상 종료 시

  → 해당 전투는 무효 처리됨 (토벌 준비 횟수는 감소하지 않음)

  ③ 전투 종료 전에 시즌이 종료될 경우

  → 해당 전투는 무효 처리됨 (토벌 준비 횟수는 감소하지 않음)

​

■ 킹덤 아레나

- 아레나(친선전 포함) 전투 로딩화면에 내 덱에있는 쿠키 중 하나가 랜덤하게 등장하도록 변경하였습니다.

- 플레이 중 재접속 및 강제 종료(네트워크 에러 포함)되었을 때의 보상 지급 및 입장 조건을 아래와 같이 적용하였습니다.

  ① 상대의 체력이 남아있는 상태에서 비정상 종료 시

  → 패배 판정, 패배 보상 획득, 아레나 티켓 소모

  ② 아군이 체력이 남아 있고, 상대 쿠키를 모두 쓰러뜨린 상태에서 비정상 종료 시

  → 승리 판정, 승리 보상 획득, 아레나 티켓 소모

  ③ 전투 종료 전에 시즌이 종료될 경우

  → 해당 전투는 무효 처리됨 (아레나 티켓은 소모되지 않음)

​

■ 모험

- 플레이 중 재접속 및 강제 종료(네트워크 에러 포함)되었을 때의 보상 지급 및 입장 조건을 아래와 같이 적용하였습니다.

  ① 적에게 피해를 입히지 않은 상태에서 비정상 종료 시

  → 해당 모험은 무효 처리됨(스태미너 젤리는 소모되지 않음)

  ② 적에게 피해를 입힌 상태에서 비정상 종료 시

  → 해당 모험 실패 판정, 실패 시의 보상 지급 및 해당 스테이지에 해당하는 스태미너 젤리 30% 소모

  ※ 비정상적으로 전투 종료 시 전투리포트에 표시되는 총 피해량과 실제 입힌 피해량의 차이가 발생할 수 있습니다.

🏰 쿠키

■ [공통] 피해감소 옵션​

- 피해 감소 효과가 적용될 수 있는 최대 수치가 85%를 지정하였습니다.

  · 기존에도 피해 감소 효과는 85%를 초과할 수 없었습니다. 다만 피해감소 효과가 과도하게 높아지는 경우 전투가 정상적으로 진행되지 않는 문제가 발생할 수 있기 때문에, 피해 감소 효과는 85%까지만 적용될 수 있도록 최대 상한선을 지정하였습니다.

​

■ 마들렌맛 쿠키

- 공격 속도를 상향 조정하였습니다. (2.5초 → 2.0초)

- 스킬 사용 후 원거리 검기 모션을 1.5배 빠르게 적용하였습니다.

- 원거리 검기 공격의 사거리를 증가하였습니다. (3.25 → 6.00)

- 원거리 검기 공격의 넉백 거리를 조정하였습니다. (2.0 → 0.75)

​

■ 민트초코 쿠키

- 스킬 회복량을 증가하였습니다. (스킬 50레벨 기준, 132.1% → 158.5%)

- 스킬의 공격 속도 버프 상승률을 증가하였습니다. (25.0% → 40.0%)

- 스킬 시작 시점으로부터 회복 버프가 발동되기까지 시간을 단축하였습니다. (0.6초 → 0.1초)

- 스킬 시작 시점으로부터 공격 속도 버프가 발동되기까지 시간을 단축하였습니다. (0.8초 -> 0.4초 )

​

■ 라떼맛 쿠키

- 스킬 효과 설명을 수정하였습니다.

  · [기존] 1회 피해량: % / 라떼마법진 중심부 총 피해량: % / 라떼마법진 외곽부 피해량: %

  · [변경] 1회 피해량: % / 라떼마법진 중심부 총 피해량: % / 라떼마법진 외곽부 총 피해량: % / 침묵: 1.0초 / 이동불가: 5.0초

- 스킬 레벨업 설명을 수정하였습니다.

  · [기존] 피해량 % 증가 / 라떼마법진 중심부 피해량 % 증가 / 라떼마법진 외곽부 피해량 % 증가

  · [변경] 피해량 % 증가 / 라떼마법진 중심부 총 피해량 % 증가 / 라떼마법진 외곽부 총 피해량 % 증가

​

■ 독버섯맛 쿠키

- 스킬 효과 설명에서 잘못된 부분을 수정하였습니다.

  · [기존] 피해량: % / 중독 피해량: n초 동안 0.5초마다 % 피해

  · [변경] 피해량: % / 중독 피해량: n초 동안 1.0초마다 % 피해

⚙️ 버그 수정

■ [공통] 전투

- 사운드를 OFF로 설정하였으나 특정 상황에서 BGM이 출력되던 현상을 수정하였습니다.

- 전투 준비 화면에서 덱을 수정한 후 상세정보 페이지 이동이 발생하면 마지막으로 수정한 덱이 반영되지 않던 현상을 수정하였습니다.

- 에피소드 보상 정보 창에서 보상 아이템의 종류가 10개를 넘어가는 경우 10개 까지만 출력되던 현상을 수정하였습니다.

​

■ 길드 토벌전​

- 전투 준비 화면에서 보스의 체력 수치가 한 단위 낮게 출력되던 현상을 수정하였습니다.

- 길드 토벌전의 결과창에 표시되는 피해량에 전투 종료 판정 이후에 발생한 피해량이 포함되어 실제 피해량과 다르게 보이던 현상을 수정하였습니다.

- 일부 쿠키들의 공격이 비정상적으로 동작하던 현상을 수정하였습니다.

  · 기본 공격 : 구미호맛 쿠키, 마들렌맛 쿠키

  · 스킬 : 근육맛 쿠키, 비트맛 쿠키, 연금술사맛 쿠키, 체리맛 쿠키, 딸기맛 쿠키, 버블껌맛 쿠키, 슈크림맛 쿠키, 아보카도맛 쿠키, 자색고구마맛 쿠키

​

■ 킹덤 아레나

- 티어 상승 시, 트로피 게이지가 비정상적으로 출력되던 현상(티어 상승 후에도 게이지가 99%로 출력)을 수정하였습니다.

- 아레나 트로피를 2,000 이상 보유 중임에도 누적 트로피 게이지에 2,000 으로 출력되던 현상을 수정하였습니다.

- 아레나 결과 화면에서 보유한 쿠키가 최고 레벨일 때 ‘최고레벨’ 문구가 출력되지 않던 현상을 수정하였습니다.

[출처] 4월 8일(목) 업데이트 안내 (쿠키런 킹덤 공식카페) | 작성자 쿠키런 킹덤
            `,
    },
    {
      id: 2,
      title: "[패치] 3월 24일(수) 업데이트 안내",
      date: "2021.03.24. 08:07",
      type: "패치노트",
      content: `안녕하세요, 쿠키런: 킹덤 입니다.

      3/24(수) 업데이트를 통해 적용된 사항을 안내드리며, 더욱 즐거운 왕국 생활을 위해 노력하겠습니다.

      ⭐️ 추가·변경 및 개선

      - 현상수배 미션 화면 상단에 현재 보유 중인 스킬파우더 수량이 표기 되도록 개선하였습니다.

      - 영토 잠금해제 완료 시 지급되는 보상 연출에 보상의 명칭이 표기 되도록 개선하였습니다.

      - 특정 해상도에서 어색하게 보이던 뽑기 화면 UI를 개선하였습니다.

      - 에픽확정 쿠키 뽑기 패키지에 안내 문구를 아래와 같이 추가하였습니다.

      '확정되는 에픽 쿠키 1종은 슈크림맛 쿠키를 제외한 에픽 쿠키들 중 1종이 모두 같은 확률로 등장합니다'

      ​

      ⚙️ 버그 수정

      - '어린 순례자의 장난감 새총' 보물 아이템의 효과가 비정상적으로 적용되는 문제를 수정하였습니다.

      - 특정 조건에서 스태미너 젤리 충전시간이 초기화 되었던 문제를 수정하였습니다.

      - 외국어 환경에서 토핑 연속 강화 시 한국어가 출력되던 문제를 수정하였습니다.

      - 길드 효과가 소수점 둘 째 자리 이상으로 출력되던 문제를 수정하였습니다.

      - 바로가기 대상이 레벨 업으로 공사중일 경우 해당 건물로 이동하지 않고, 왕국 중앙으로 이동하던 문제를 수정하였습니다.

      - 바로가기로 생산물 수거 시 생산물 수거 이펙트가 비정상적으로 출력되던 문제를 수정하였습니다.

      - 00시(KST) 소원나무 초기화 후, 쿠키의 부탁을 들어주거나 갱신하지 않은 상태에서, 일일보상 초기화 잔여시간이 표시되지 않고 비정상적으로 표기되던 문제를 수정하였습니다.

      - 왕국 건물의 레벨 업 가능 아이콘(초록색 화살표)이 보유 재화량 변화에 따라 바로 적용 되도록 수정하였습니다.

      ​

      감사합니다.

      [출처] 3월 24일(수) 업데이트 안내 (쿠키런 킹덤 공식카페) | 작성자 쿠키런 킹덤`,
    },
  ];
};

export const APIGetPatchNote = (id: number): IPatchNote | null => {
  return APIGetPatchNoteList().find((note) => note.id === id) || null;
};
