import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import BackHeader from "../../../basic/header/BackHeader";
import Layout from "../../../container/Layout";

interface Props {
  Password: React.ReactElement;
  PasswordConfirm: React.ReactElement;
  SubmitButton: React.ReactElement;
}

const PasswordChangePageTemplate: React.FC<Props> = ({ Password, PasswordConfirm, SubmitButton }) => {
  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  return (
    <Layout Header={<BackHeader title="비밀번호 변경" onClick={handleBack} />}>
      <Container>
        <Title>비밀번호 변경 페이지</Title>

        <InputBoxWrapper>
          <Label>새 비밀번호</Label>
          <InputWrapper>{Password}</InputWrapper>
        </InputBoxWrapper>
        <InputBoxWrapper>
          <Label>새 비밀번호 재확인</Label>
          <InputWrapper>{PasswordConfirm}</InputWrapper>
        </InputBoxWrapper>
        <div>{SubmitButton}</div>
      </Container>
    </Layout>
  );
};

export default PasswordChangePageTemplate;

const Container = styled.div`
  padding: 20px;
  background-color: #fff;
  min-height: 600px;
`;

const Title = styled.div`
  margin-bottom: 32px;
`;

const InputBoxWrapper = styled.div`
  margin: 12px 0;
`;

const Label = styled.label`
  font-size: 0.825rem;
`;

const InputWrapper = styled.div`
  margin-top: 4px;
  min-height: 70px;
`;
