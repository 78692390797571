import React from "react";
import styled from "styled-components";

interface Props {
  color: string;
}

const Logo: React.FC<Props> = ({ color }) => {
  return <LogoBlock color={color}>🍪 쿠킹지지</LogoBlock>;
};

export default Logo;

const LogoBlock = styled.h3<{ color: string }>`
  font-family: "양진체";
  font-weight: normal;
  color: ${(props) => props.color};
  margin: 0;
`;
