import { IComment } from "../common/comment.types";
import { AttackType, Grade, PositionType } from "../common/common.type";
import { ICookieTopping } from "./cookie.topping.types";

export enum CookieTierType {
  종합티어 = "totalTier",
  월드스토리티어 = "worldTier",
  아레나티어 = "arenaTier",
  토벌전티어 = "warTier",
}
export interface ICookie {
  id: string;
  thumbnail: string;
  name: string;
  grade: Grade;
  type: AttackType;
  position: PositionType;
  totalTier: number; // 종합 티어
  worldTier: number; // 월드(스토리) 티어
  arenaTier: number; // 아레나 티어
  warTier: number; // 토벌전 티어
  skillName: string | null;
  skillImage: string | null;
  skillDescription: string | null;
  story: string | null;
}

export interface ICookieComment extends IComment {}

export interface ICookieDetail extends ICookie {
  cookieComments: ICookieComment[];
  recommendedTopping: ICookieTopping | null;
}
