import { gql } from "@apollo/client";

export const ADD_COOKIE_DECK_LIKE = gql`
  mutation addCookieDeckLike($id: ID!) {
    addCookieDeckLike(cookieDeckId: $id) {
      id
    }
  }
`;

export const DELETE_COOKIE_DECK_LIKE = gql`
  mutation deleteCookieDeckLike($id: ID!) {
    deleteCookieDeckLike(cookieDeckId: $id) {
      id
    }
  }
`;
