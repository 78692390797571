import React, { useCallback, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { CookieTierType } from "../apis/cookie/cookie.type";
import { GET_TREASURES } from "../apis/treasure/treasure";
import { ITreasure } from "../apis/treasure/treasure.type";
import Tab from "../components/common/Tab";
import TreasureList from "../components/pages/treasure/list/TreasureList";
import TreasureListPageTemplate from "../components/pages/treasure/list/TreasureListPageTemplate";

const tabs = [
  {
    name: "종합",
    value: CookieTierType.종합티어,
  },
];

const TreasureListPage: React.FC = () => {
  const { loading, data } = useQuery<{ treasures: ITreasure[] }>(GET_TREASURES);

  const [items, setItems] = useState<ITreasure[]>([]);
  const [tab, setTab] = useState<string>(CookieTierType.종합티어);

  const sort = useCallback((treasures: ITreasure[], key: string) => {
    return treasures.slice().sort((a: any, b: any) => {
      return a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0;
    });
  }, []);

  useEffect(() => {
    if (data) {
      setItems(sort(data.treasures || [], CookieTierType.종합티어));
    } else {
      setItems([]);
    }
  }, [data, sort]);

  const handleChangeTab = (value: string) => {
    setTab(value);
    setItems(sort(items, value));
  };

  return (
    <TreasureListPageTemplate
      Tab={<Tab value={tab} onChange={handleChangeTab} items={tabs} />}
      List={<TreasureList loading={loading} tierType={tab} items={items} />}
    />
  );
};

export default TreasureListPage;
