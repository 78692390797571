import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import BackHeader from "../../../../basic/header/BackHeader";
import NoticeBox from "../../../../box/NoticeBox";
import Layout from "../../../../container/Layout";

interface Props {
  List: React.ReactElement;
  MoreButton?: React.ReactElement;
  CreateLinkButton: React.ReactElement;
}

const DeckListPageTemplate: React.FC<Props> = ({ List, MoreButton, CreateLinkButton }) => {
  const [scrollPosition, setSrollPosition] = useState(0);
  const [showBottomButton, setShowBottomButton] = useState(true);

  const handleScroll = useCallback(() => {
    const position = window.pageYOffset;

    if (scrollPosition > position) {
      setShowBottomButton(true);
    } else if (scrollPosition < position) {
      setShowBottomButton(false);
    }

    setSrollPosition(position);
  }, [scrollPosition]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <Layout Header={<BackHeader title="덱 목록" />}>
      <Container>
        <Top>
          <NoticeBox text="쿠키 덱을 만들어서 공유해보세요!" />
        </Top>
        <ListWrapper>{List}</ListWrapper>
        {MoreButton && <MoreButtonWrapper>{MoreButton}</MoreButtonWrapper>}
      </Container>
      {showBottomButton && (
        <FixedCreateDeckPageLinkBox>
          <FixedCreateDeckContainer>{CreateLinkButton}</FixedCreateDeckContainer>
        </FixedCreateDeckPageLinkBox>
      )}
    </Layout>
  );
};

export default DeckListPageTemplate;

const Container = styled.div``;

const Top = styled.div`
  background-color: #fff;
  padding: 20px;
`;

const ListWrapper = styled.div`
  margin-top: 8px;
  background-color: #fff;
  min-height: 600px;
`;

const MoreButtonWrapper = styled.div`
  padding: 20px;
  background-color: #fff;
`;

const FixedCreateDeckPageLinkBox = styled.div`
  box-shadow: -3px -3px 8px rgba(0, 0, 0, 0.2);
  border-top: 1px solid #f2f2f2;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  max-width: 600px;
  margin: 0 auto;
`;

const FixedCreateDeckContainer = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
