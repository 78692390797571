import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { API_URL } from "../../apis/config";
import { IUser } from "../../types/IUser";
import { isTokenExpired } from "../../utils/storage";
import { AppThunk, RootState } from "../index";

interface AuthState {
  user: IUser | null;
  loading: boolean;
}

const initialState: AuthState = {
  user: null,
  loading: true,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUser | null>) => {
      state.user = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const { setUser, setLoading } = authSlice.actions;

export const checkToken = (): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  const token = localStorage.getItem("token");
  const expired = isTokenExpired(token);

  if (expired) {
    dispatch(setLoading(false));
    dispatch(setUser(null));
    return;
  }

  const data = await fetch(`${API_URL}/users/me`, {
    method: "GET",
    headers: new Headers({
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    }),
  });

  const user = await data.json();

  dispatch(setUser(user));
  dispatch(setLoading(false));
};

export const logout = async () => {
  await localStorage.clear();
};

export const user = (state: RootState) => state.auth.user;
export const userLoading = (state: RootState) => state.auth.loading;
export const loggedIn = (state: RootState) => state.auth.user !== null;

export default authSlice.reducer;
