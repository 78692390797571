export const convertTier = (tier: number) => {
  if (tier === 0) {
    return "S+";
  } else if (tier === 0.5) {
    return "S";
  } else if (tier === 1) {
    return "A";
  } else if (tier === 1.5) {
    return "B";
  } else if (tier === 2) {
    return "C";
  } else if (tier === 2.5) {
    return "D";
  } else if (tier === 3) {
    return "E";
  } else {
    return "-";
  }
};
