import React from "react";
import styled from "styled-components";
import { IYoutube } from "../../../apis/youtube/youtube.types";
import HomeYoutubeListItem from "./HomeYoutubeListItem";

interface Props {
  items: IYoutube[];
}

const HomeYoutubeList: React.FC<Props> = ({ items }) => {
  return (
    <HomeYoutubeListBlock>
      <ListWrapper>
        {items.map((item, index) => (
          <ListItemWrapper key={`home-youtube-${item.id}`} first={index === 0} last={items.length - 1 === index}>
            <HomeYoutubeListItem item={item} />
          </ListItemWrapper>
        ))}
      </ListWrapper>
    </HomeYoutubeListBlock>
  );
};

export default HomeYoutubeList;

const HomeYoutubeListBlock = styled.div`
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ListWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
`;

const ListItemWrapper = styled.div<{ first: boolean; last: boolean }>`
  padding-left: ${(props) => (props.first ? 20 : 10)}px;
  padding-right: ${(props) => (props.last ? 20 : 10)}px;
  white-space: pre-line;
`;
