import { useQuery } from "@apollo/client";
import React from "react";
import { GET_COUPONS } from "../apis/coupon/coupon";
import { ICoupon } from "../apis/coupon/coupon.types";
import CouponList from "../components/pages/coupon/CouponList";
import CouponListPageTemplate from "../components/pages/coupon/CouponListPageTemplate";

const CouponListPage: React.FC = () => {
  const { data, loading } = useQuery<{ coupons: ICoupon[] }>(GET_COUPONS);

  return <CouponListPageTemplate List={<CouponList loading={loading} items={data ? data.coupons : []} />} />;
};

export default CouponListPage;
