import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ICookie } from "../../../../apis/cookie/cookie.type";
import GoogleAdsense from "../../../ad/GoogleAdsense";
import LoadingMessageBox from "../../../container/LoadingMessageBox";
import CookieListItem from "./CookieListItem";

interface Props {
  loading: boolean;
  tierType: string;
  items: ICookie[];
}

const CookieList: React.FC<Props> = ({ loading, tierType, items }) => {
  if (loading) {
    return <LoadingMessageBox />;
  }

  return (
    <div>
      {items.map((item, index) => {
        if (index === 12) {
          return (
            <AdWithListItem key={`cookie-${item.id}`}>
              <Link to={`/cookie/${item.id}`}>
                <GoogleAdsense
                  className="cookies-google-ad-2"
                  adClient="ca-pub-8271789671155671"
                  adSlot="5904079471"
                  adLayoutKey="-f7+5u+4t-da+6l"
                  adFormat="fluid"
                />

                <ListItemWrapper>
                  <CookieListItem tierType={tierType} item={item} />
                </ListItemWrapper>
              </Link>
            </AdWithListItem>
          );
        }

        return (
          <ListItemWrapper key={`cookie-${item.id}`}>
            <Link to={`/cookie/${item.id}`}>
              <CookieListItem tierType={tierType} item={item} />
            </Link>
          </ListItemWrapper>
        );
      })}
    </div>
  );
};

export default CookieList;

const ListItemWrapper = styled.div``;

const AdWithListItem = styled.div``;
