import React from "react";
import styled from "styled-components";
import { format } from "date-fns";
import { IComment } from "../../../../apis/common/comment.types";

interface Props {
  item: IComment;
  onDelete?: (item: IComment) => void;
}

const CookieCommentListItem: React.FC<Props> = ({ item, onDelete }) => {
  const date = format(new Date(item.created_at), "yyyy.MM.dd HH:mm");

  const handleDelete = () => {
    onDelete && onDelete(item);
  };

  return (
    <CookieCommentListItemBlock>
      <Username>{item.user.username}</Username>
      <Content>{item.content}</Content>
      <Inline>
        <CreatedAt>{date}</CreatedAt>
        {onDelete && <DeleteText onClick={handleDelete}>삭제</DeleteText>}
      </Inline>
    </CookieCommentListItemBlock>
  );
};

export default CookieCommentListItem;

const CookieCommentListItemBlock = styled.div`
  border-bottom: 1px solid #f2f2f2;
  padding: 16px 0;
`;

const Username = styled.span`
  font-size: 0.725rem;
  font-weight: bold;
  color: #333;
`;

const Content = styled.p`
  margin: 4px 0;
  font-size: 0.975rem;
  line-height: 1.5;
  color: #000;
  white-space: pre-line;
`;

const Inline = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
`;

const CreatedAt = styled.div`
  font-size: 0.725rem;
  color: #5668;
  margin-bottom: 1px;
`;

const DeleteText = styled.div`
  margin-left: 16px;
  font-size: 0.725rem;
  color: #5668;
`;
