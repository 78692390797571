import React from "react";
import styled from "styled-components";

const EmptyMessageBox = () => {
  return <EmptyMessageBoxBlock>🍪 데이터 찾을 수 없어요...</EmptyMessageBoxBlock>;
};

export default EmptyMessageBox;

const EmptyMessageBoxBlock = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 100px;
  height: 400px;
  width: 100%;
`;
