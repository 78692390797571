import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router";
import { GET_COOKIE } from "../apis/cookie/cookie";
import { ICookieDetail } from "../apis/cookie/cookie.type";
import CookieDetailPageTemplate from "../components/pages/cookie/detail/CookieDetailPageTemplate";
import EmptyLayout from "../components/container/EmptyLayout";
import LoadingLayout from "../components/container/LoadingLayout";
import CookieCommentList from "../components/pages/cookie/detail/CookieCommentList";
import CookieCommentInputBox from "../components/pages/cookie/detail/CookieCommentInputBox";
import { useSelector } from "react-redux";
import { user } from "../modules/auth/auth";
import { INCREMENT_COOKIE_VIEW_COUNT } from "../apis/cookie/cookie.count";

const CookieDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const loginUser = useSelector(user);

  const { loading, data, refetch } = useQuery<{ cookie: ICookieDetail }>(GET_COOKIE, {
    variables: { id: Number(id) },
    onCompleted: () => {
      try {
        incrementViewCount({
          variables: {
            id: Number(id),
          },
        });
      } catch (error) {
        return;
      }
    },
  });

  const [incrementViewCount] = useMutation(INCREMENT_COOKIE_VIEW_COUNT);

  const handleRefresh = () => {
    refetch();
  };

  if (loading) {
    return <LoadingLayout />;
  }

  if (!data || !data.cookie) {
    return <EmptyLayout />;
  }

  return (
    <CookieDetailPageTemplate
      item={data.cookie}
      CommentInput={<CookieCommentInputBox cookieId={data.cookie.id} user={loginUser} onRefresh={handleRefresh} />}
      CommentList={<CookieCommentList items={data.cookie.cookieComments} />}
    />
  );
};

export default CookieDetailPage;
