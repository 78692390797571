import React, { ChangeEvent } from "react";
import styled from "styled-components";
import color from "../../styles/color";

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  errorMessage?: string;
  name?: string;
  onChange?: (value: any) => void;
  onChangeNameAndValue?: (name: string, value: any) => void;
}

const Input: React.FC<Props> = (props: Props) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (props.onChangeNameAndValue) {
      props.onChangeNameAndValue(e.target.name, e.target.value);
    }

    if (props.onChange) {
      props.onChange(e.target.value);
    }
  };

  const copyProps = { ...props };
  const inputProps = () => {
    if (copyProps.onChangeNameAndValue) {
      delete copyProps.onChangeNameAndValue;
    }

    return copyProps;
  };

  return (
    <>
      <InputBlock>
        <InputWrapper>
          <input {...inputProps()} onChange={handleChange} />
        </InputWrapper>
      </InputBlock>
      {props.errorMessage && <ErrorMessage>{props.errorMessage}</ErrorMessage>}
    </>
  );
};

export default Input;

const InputBlock = styled.div`
  border-radius: 3px;
  border: 1px solid ${color.light.border};
  background-color: #fff;
`;

const InputWrapper = styled.div`
  padding: 2px 8px;

  input {
    border: 0;
    height: 38px;
    width: calc(100% - 16px);
    outline: none;
    font-size: 16px;
    border-radius: 4px;
  }
`;

const ErrorMessage = styled.p`
  margin: 4px 0;
  font-size: 0.825rem;
  color: #f55;
  padding: 0 2px;
`;
