import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import BackHeader from "../../../basic/header/BackHeader";
import Layout from "../../../container/Layout";

interface Props {
  SaveTextButton: React.ReactElement;
  Title: React.ReactElement;
  Content: React.ReactElement;
}

const CreatePostPageTemplate: React.FC<Props> = ({ SaveTextButton, Title, Content }) => {
  const history = useHistory();

  const handleBack = () => {
    history.push("/posts");
  };

  return (
    <Layout Header={<BackHeader Right={SaveTextButton} onClick={handleBack} />}>
      <Container>
        <div>{Title}</div>
        <div>{Content}</div>
      </Container>
    </Layout>
  );
};

export default CreatePostPageTemplate;

const Container = styled.div`
  background-color: #fff;
`;
