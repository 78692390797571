import React from "react";
import styled from "styled-components";
import { ThemeContext, ThemeType } from "../../styles/Theme";
import Footer from "../basic/Footer";

interface Props {
  Header?: React.ReactElement;
}

const Layout: React.FC<Props> = ({ children, Header }) => {
  const [theme] = React.useContext(ThemeContext);

  return (
    <LayoutBlock themeType={theme}>
      <Container>
        {Header}
        {children}
      </Container>
      <Footer />
    </LayoutBlock>
  );
};

export default Layout;

const LayoutBlock = styled.div<{ themeType: ThemeType }>`
  max-width: 600px;
  margin: 0 auto;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Container = styled.div``;
