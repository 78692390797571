import React from "react";
import { IconBaseProps } from "react-icons";
import { RiArrowDownSLine } from "react-icons/ri";

interface Props extends IconBaseProps {}

const ArrowDownIcon: React.FC<Props> = ({ size, color }) => {
  return <RiArrowDownSLine size={size} color={color} />;
};

export default ArrowDownIcon;
