import { gql } from "@apollo/client";
import { CookieTierType } from "./cookie.type";

export const GET_COOKIES = gql`
  query cookies {
    cookies {
      id
      name
      thumbnail
      grade
      type
      position
      totalTier
      worldTier
      arenaTier
      warTier
    }
  }
`;

export const GET_COOKIE = gql`
  query cookie($id: ID!) {
    cookie(id: $id) {
      id
      name
      thumbnail
      grade
      type
      totalTier
      worldTier
      arenaTier
      warTier
      skillName
      skillImage
      skillDescription
      story
      cookieComments {
        id
        content
        created_at
        user {
          id
          username
        }
      }
      recommendedTopping {
        id
        name
        thumbnail
        description
        synergy1
        synergy2
      }
    }
  }
`;

export const cookieListTabItems = [
  {
    name: "종합",
    value: CookieTierType.종합티어,
  },
  {
    name: "월드",
    value: CookieTierType.월드스토리티어,
  },
  {
    name: "아레나",
    value: CookieTierType.아레나티어,
  },
  {
    name: "토벌전",
    value: CookieTierType.토벌전티어,
  },
];

// export const APIGetCookieList = (): ICookie[] => {
//   return [
//     {
//       id: 0,
//       thumbnail: "https://image.rok.gg/cookie/character/ancient/퓨어바닐라+쿠키.png",
//       name: "퓨어바닐라맛 쿠키",
//       grade: Grade.에이션트,
//       type: AttackType.회복,
//       totalTier: 0,
//       worldTier: 0,
//       arenaTier: 0,
//       warTier: 0,
//     },
//     {
//       id: 1,
//       thumbnail: "https://image.rok.gg/cookie/character/epic/블랙레이즌맛+쿠키.png",
//       name: "블랙레이즌맛 쿠키",
//       grade: Grade.에픽,
//       type: AttackType.침투,
//       totalTier: 1.5,
//       worldTier: 999,
//       arenaTier: 999,
//       warTier: 999,
//     },
//     {
//       id: 2,
//       thumbnail: "https://image.rok.gg/cookie/character/epic/아몬드맛+쿠키.png",
//       name: "아몬드맛 쿠키",
//       grade: Grade.에픽,
//       type: AttackType.지원,
//       totalTier: 1,
//       worldTier: 999,
//       arenaTier: 999,
//       warTier: 999,
//     },
//     {
//       id: 3,
//       thumbnail: "https://image.rok.gg/cookie/character/epic/호밀맛+쿠키.png",
//       name: "호밀맛 쿠키",
//       grade: Grade.에픽,
//       type: AttackType.사격,
//       totalTier: 1,
//       worldTier: 0.5,
//       arenaTier: 0,
//       warTier: 0,
//     },
//     {
//       id: 4,
//       thumbnail: "https://image.rok.gg/cookie/character/epic/허브맛+쿠키.png",
//       name: "허브맛 쿠키",
//       grade: Grade.에픽,
//       type: AttackType.회복,
//       totalTier: 0.5,
//       worldTier: 0,
//       arenaTier: 0,
//       warTier: 1.5,
//     },
//     {
//       id: 5,
//       thumbnail: "https://image.rok.gg/cookie/character/epic/칠리맛+쿠키.png",
//       name: "칠리맛 쿠키",
//       grade: Grade.에픽,
//       type: AttackType.침투,
//       totalTier: 2,
//       worldTier: 999,
//       arenaTier: 999,
//       warTier: 999,
//     },
//     {
//       id: 6,
//       thumbnail: "https://image.rok.gg/cookie/character/epic/정글전사+쿠키.png",
//       name: "정글전사 쿠키",
//       grade: Grade.에픽,
//       type: AttackType.사격,
//       totalTier: 2,
//       worldTier: 0.5,
//       arenaTier: 0,
//       warTier: 2,
//     },
//     {
//       id: 7,
//       thumbnail: "https://image.rok.gg/cookie/character/epic/자색고구마맛+쿠키.png",
//       name: "자색고구마맛 쿠키",
//       grade: Grade.에픽,
//       type: AttackType.돌격,
//       totalTier: 2.5,
//       worldTier: 999,
//       arenaTier: 999,
//       warTier: 999,
//     },
//     {
//       id: 8,
//       thumbnail: "https://image.rok.gg/cookie/character/epic/웨어울프맛+쿠키.png",
//       name: "웨어울프맛 쿠키",
//       grade: Grade.에픽,
//       type: AttackType.돌격,
//       totalTier: 2.5,
//       worldTier: 999,
//       arenaTier: 999,
//       warTier: 999,
//     },
//     {
//       id: 9,
//       thumbnail: "https://image.rok.gg/cookie/character/epic/우유맛+쿠키.png",
//       name: "우유맛 쿠키",
//       grade: Grade.에픽,
//       type: AttackType.방어,
//       totalTier: 1,
//       worldTier: 0,
//       arenaTier: 0.5,
//       warTier: 2,
//     },
//     {
//       id: 10,
//       thumbnail: "https://image.rok.gg/cookie/character/epic/에스프레소맛+쿠키.png",
//       name: "에스프레소맛 쿠키",
//       grade: Grade.에픽,
//       type: AttackType.마법,
//       totalTier: 0,
//       worldTier: 0.5,
//       arenaTier: 0,
//       warTier: 0.5,
//     },
//     {
//       id: 11,
//       thumbnail: "https://image.rok.gg/cookie/character/epic/스파클링맛+쿠키.png",
//       name: "스파클링맛 쿠키",
//       grade: Grade.에픽,
//       type: AttackType.회복,
//       totalTier: 2,
//       worldTier: 1,
//       arenaTier: 0,
//       warTier: 2,
//     },
//     {
//       id: 12,
//       thumbnail: "https://image.rok.gg/cookie/character/epic/슈크림맛+쿠키.png",
//       name: "슈크림맛 쿠키",
//       grade: Grade.에픽,
//       type: AttackType.지원,
//       totalTier: 1.5,
//       worldTier: 999,
//       arenaTier: 999,
//       warTier: 999,
//     },
//     {
//       id: 13,
//       thumbnail: "https://image.rok.gg/cookie/character/epic/석류맛+쿠키.png",
//       name: "석류맛 쿠키",
//       grade: Grade.에픽,
//       type: AttackType.지원,
//       totalTier: 0,
//       worldTier: 1,
//       arenaTier: 0,
//       warTier: 0,
//     },
//     {
//       id: 14,
//       thumbnail: "https://image.rok.gg/cookie/character/epic/뱀파이어맛+쿠키.png",
//       name: "뱀파이어맛 쿠키",
//       grade: Grade.에픽,
//       type: AttackType.침투,
//       totalTier: 0,
//       worldTier: 0.5,
//       arenaTier: 0,
//       warTier: 0,
//     },
//     {
//       id: 15,
//       thumbnail: "https://image.rok.gg/cookie/character/epic/민트초코+쿠키.png",
//       name: "민트초코 쿠키",
//       grade: Grade.에픽,
//       type: AttackType.지원,
//       totalTier: 3,
//       worldTier: 999,
//       arenaTier: 999,
//       warTier: 999,
//     },
//     {
//       id: 16,
//       thumbnail: "https://image.rok.gg/cookie/character/epic/마들렌맛+쿠키.png",
//       name: "마들렌맛 쿠키",
//       grade: Grade.에픽,
//       type: AttackType.방어,
//       totalTier: 2,
//       worldTier: 1,
//       arenaTier: 1,
//       warTier: 2,
//     },
//     {
//       id: 17,
//       thumbnail: "https://image.rok.gg/cookie/character/epic/라떼맛+쿠키.png",
//       name: "라떼맛 쿠키",
//       grade: Grade.에픽,
//       type: AttackType.마법,
//       totalTier: 0.5,
//       worldTier: 0.5,
//       arenaTier: 0,
//       warTier: 0.5,
//     },
//     {
//       id: 18,
//       thumbnail: "https://image.rok.gg/cookie/character/epic/독버섯맛+쿠키.png",
//       name: "독버섯맛 쿠키",
//       grade: Grade.에픽,
//       type: AttackType.폭발,
//       totalTier: 2,
//       worldTier: 999,
//       arenaTier: 999,
//       warTier: 999,
//     },
//     {
//       id: 19,
//       thumbnail: "https://image.rok.gg/cookie/character/epic/다크초코+쿠키.png",
//       name: "다크초코 쿠키",
//       grade: Grade.에픽,
//       type: AttackType.돌격,
//       totalTier: 0,
//       worldTier: 0,
//       arenaTier: 0,
//       warTier: 0,
//     },
//     {
//       id: 20,
//       thumbnail: "https://image.rok.gg/cookie/character/epic/눈설탕맛+쿠키.png",
//       name: "눈설탕맛 쿠키",
//       grade: Grade.에픽,
//       type: AttackType.마법,
//       totalTier: 2,
//       worldTier: 0,
//       arenaTier: 0,
//       warTier: 0,
//     },
//     {
//       id: 21,
//       thumbnail: "https://image.rok.gg/cookie/character/epic/구미호맛+쿠키.png",
//       name: "구미호맛 쿠키",
//       grade: Grade.에픽,
//       type: AttackType.돌격,
//       totalTier: 2,
//       worldTier: 0,
//       arenaTier: 0,
//       warTier: 0,
//     },
//     {
//       id: 22,
//       thumbnail: "https://image.rok.gg/cookie/character/epic/감초맛+쿠키.png",
//       name: "감초맛 쿠키",
//       grade: Grade.에픽,
//       type: AttackType.마법,
//       totalTier: 0,
//       worldTier: 0,
//       arenaTier: 0.5,
//       warTier: 0,
//     },
//     {
//       id: 23,
//       thumbnail: "https://image.rok.gg/cookie/character/rare/팬케이크맛+쿠키.png",
//       name: "팬케이크맛 쿠키",
//       grade: Grade.레어,
//       type: AttackType.침투,
//       totalTier: 999,
//       worldTier: 999,
//       arenaTier: 999,
//       warTier: 999,
//     },
//     {
//       id: 24,
//       thumbnail: "https://image.rok.gg/cookie/character/rare/탐험가맛+쿠키.png",
//       name: "탐험가맛 쿠키",
//       grade: Grade.레어,
//       type: AttackType.침투,
//       totalTier: 999,
//       worldTier: 999,
//       arenaTier: 999,
//       warTier: 999,
//     },
//     {
//       id: 25,
//       thumbnail: "https://image.rok.gg/cookie/character/rare/클로버맛+쿠키.png",
//       name: "클로버맛 쿠키",
//       grade: Grade.레어,
//       type: AttackType.지원,
//       totalTier: 999,
//       worldTier: 999,
//       arenaTier: 999,
//       warTier: 999,
//     },
//     {
//       id: 26,
//       thumbnail: "https://image.rok.gg/cookie/character/rare/커스터드+3세맛+쿠키.png",
//       name: "커스터드 3세맛 쿠키",
//       grade: Grade.레어,
//       type: AttackType.회복,
//       totalTier: 999,
//       worldTier: 999,
//       arenaTier: 999,
//       warTier: 999,
//     },
//     {
//       id: 27,
//       thumbnail: "https://image.rok.gg/cookie/character/rare/체리맛+쿠키.png",
//       name: "체리맛 쿠키",
//       grade: Grade.레어,
//       type: AttackType.폭발,
//       totalTier: 999,
//       worldTier: 999,
//       arenaTier: 999,
//       warTier: 999,
//     },
//     {
//       id: 28,
//       thumbnail: "https://image.rok.gg/cookie/character/rare/용사맛+쿠키.png",
//       name: "용사맛 쿠키",
//       grade: Grade.레어,
//       type: AttackType.방어,
//       totalTier: 999,
//       worldTier: 999,
//       arenaTier: 999,
//       warTier: 999,
//     },
//     {
//       id: 29,
//       thumbnail: "https://image.rok.gg/cookie/character/rare/연금술사맛+쿠키.png",
//       name: "연금술사맛 쿠키",
//       grade: Grade.레어,
//       type: AttackType.폭발,
//       totalTier: 999,
//       worldTier: 999,
//       arenaTier: 999,
//       warTier: 999,
//     },
//     {
//       id: 30,
//       thumbnail: "https://image.rok.gg/cookie/character/rare/양파맛+쿠키.png",
//       name: "양파맛 쿠키",
//       grade: Grade.레어,
//       type: AttackType.지원,
//       totalTier: 999,
//       worldTier: 999,
//       arenaTier: 999,
//       warTier: 999,
//     },
//     {
//       id: 31,
//       thumbnail: "https://image.rok.gg/cookie/character/rare/아보카도맛+쿠키.png",
//       name: "아보카도맛 쿠키",
//       grade: Grade.레어,
//       type: AttackType.방어,
//       totalTier: 999,
//       worldTier: 999,
//       arenaTier: 999,
//       warTier: 999,
//     },
//     {
//       id: 32,
//       thumbnail: "https://image.rok.gg/cookie/character/rare/블랙베리맛+쿠키.png",
//       name: "블랙베리맛 쿠키",
//       grade: Grade.레어,
//       type: AttackType.마법,
//       totalTier: 999,
//       worldTier: 999,
//       arenaTier: 999,
//       warTier: 999,
//     },
//     {
//       id: 33,
//       thumbnail: "https://image.rok.gg/cookie/character/rare/버블껌맛+쿠키.png",
//       name: "버블껌맛 쿠키",
//       grade: Grade.레어,
//       type: AttackType.폭발,
//       totalTier: 999,
//       worldTier: 999,
//       arenaTier: 999,
//       warTier: 999,
//     },
//     {
//       id: 34,
//       thumbnail: "https://image.rok.gg/cookie/character/rare/당근맛+쿠키.png",
//       name: "당근맛 쿠키",
//       grade: Grade.레어,
//       type: AttackType.지원,
//       totalTier: 999,
//       worldTier: 999,
//       arenaTier: 999,
//       warTier: 999,
//     },
//     {
//       id: 35,
//       thumbnail: "https://image.rok.gg/cookie/character/rare/공주맛+쿠키.png",
//       name: "공주맛 쿠키",
//       grade: Grade.레어,
//       type: AttackType.돌격,
//       totalTier: 999,
//       worldTier: 999,
//       arenaTier: 999,
//       warTier: 999,
//     },
//   ];
// };
