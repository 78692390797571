import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { USER_ME } from "../../../apis/auth/me";
import MenuIcon from "../../icon/MenuIcon";
import HeaderSide from "./HeaderSide";
import Logo from "./Logo";
import { useDispatch } from "react-redux";
import { setUser } from "../../../modules/auth/auth";

const Header: React.FC = () => {
  const { data } = useQuery(USER_ME);
  const [showSide, setShowSide] = useState(false);
  const dispatch = useDispatch();

  const handleOpenSide = () => {
    window.document.body.style.overflowY = "hidden";
    setShowSide(true);
  };

  const handleCloseSide = () => {
    window.document.body.style.overflowY = "auto";
    setShowSide(false);
  };

  useEffect(() => {
    if (data) {
      dispatch(setUser(data.user));
    } else {
      dispatch(setUser(null));
    }
  }, [data, dispatch]);

  return (
    <>
      <HeaderBlock>
        <IconWrapper />
        <div>
          <Logo color="#fff" />
        </div>
        <IconWrapper onClick={handleOpenSide}>
          <MenuIcon color="#fff" />
        </IconWrapper>
      </HeaderBlock>
      {showSide && <HeaderSide user={data ? data.me : undefined} onClose={handleCloseSide} />}
    </>
  );
};

export default Header;

const HeaderBlock = styled.header`
  background-color: #151e2e;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
`;

const IconWrapper = styled.div`
  width: 50px;
  text-align: right;
`;
