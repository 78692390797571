import React, { ChangeEvent, useState } from "react";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router";
import styled from "styled-components";
import { SAVE_COOKIE_DECK_COMMENT } from "../../../../../apis/deck/deck.comment";
import { IUser } from "../../../../../types/IUser";

interface Props {
  cookieDeckId: string;
  user: IUser | null;
  onRefresh?: () => void;
}

const CookieDeckCommentInputBox: React.FC<Props> = ({ cookieDeckId, user, onRefresh }) => {
  const history = useHistory();
  const [comment, setComment] = useState("");

  const [saveComment, { error, loading }] = useMutation(SAVE_COOKIE_DECK_COMMENT);

  if (error) {
    alert("잠시 후 다시 시도해주세요.");
  }

  const handleChangeInput = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setComment(event.target.value);
  };

  const handleSubmit = async () => {
    if (!user) {
      alert("로그인 후 댓글을 입력해보세요.");
      history.push(`/login?prev=${window.location.pathname}`);
      return;
    }

    if (!comment || !comment.trim()) {
      alert("내용을 입력해주세요.");
      return;
    }

    try {
      await saveComment({
        variables: {
          input: {
            data: {
              user: user!.id.toString(),
              cookieDeck: cookieDeckId,
              content: comment,
              published_at: new Date(),
            },
          },
        },
      });

      setComment("");
      onRefresh && onRefresh();
    } catch (error) {}
  };

  return (
    <CookieCommentInputBoxBlock>
      <Textarea
        value={comment}
        onChange={handleChangeInput}
        rows={3}
        disabled={!user}
        placeholder={user ? "댓글을 입력해보세요" : "로그인 후 댓글을 입력해보세요"}
      />
      <Button onClick={loading ? undefined : handleSubmit}>{user ? "작성하기" : "로그인 후 작성하기"}</Button>
    </CookieCommentInputBoxBlock>
  );
};

export default CookieDeckCommentInputBox;

const CookieCommentInputBoxBlock = styled.div`
  width: 100%;
`;

const Textarea = styled.textarea`
  padding: 8px;
  border: 2px solid #151e2e;
  border-radius: 4px;
  width: calc(100% - 20px);
  font-size: 16px;
`;

const Button = styled.div`
  background-color: #151e2e;
  border-radius: 4px;
  padding: 16px;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
  text-align: center;
`;
