import { IYoutube } from "./youtube.types";

export const APIGetYoutubeList = (): IYoutube[] => {
  return [
    {
      id: 1,
      link: "https://youtu.be/T6zlzsPQNbE",
      youtubeId: "T6zlzsPQNbE",
      title: "축음기의 히든능력 발동?? 크리스탈 10만개 초대박 에픽쿠키뽑기!! 오늘도 사고친 나팔 민간요법 레전드뽑기",
    },
    {
      id: 2,
      link: "https://youtu.be/gEDQ5HyiGcE",
      youtubeId: "gEDQ5HyiGcE",
      title: "뱀파이어맛 쿠키 치명적인 버그?! 도와주세요 (업데이트 후 뱀파가 이상해요)",
    },
    {
      id: 3,
      link: "https://youtu.be/Da93OzdhNXU",
      youtubeId: "Da93OzdhNXU",
      title: "3힐러덱! 10만 높은 상대도 잡아낸다?! 퓨어바닐라 출격! [구스마일]",
    },
    {
      id: 4,
      link: "https://youtu.be/nfLGiVpz56E",
      youtubeId: "nfLGiVpz56E",
      title: "열어도 되는 것 / 열면 안되는 것 (수정본) [구스마일]",
    },
    {
      id: 5,
      link: "https://youtu.be/2Pql2sXoeac",
      youtubeId: "2Pql2sXoeac",
      title: "끝판왕 어둠마녀 최종 신규스토리를 공개합니다. 최종보스 10-31 클리어!!",
    },
    {
      id: 6,
      link: "https://youtu.be/GJVcgwCcmjg",
      youtubeId: "GJVcgwCcmjg",
      title: "어..? 좋은데?!! 5별 50레벨 블랙레이즌맛 쿠키 리뷰! 스테이지(PVE)/아레나(PVP)",
    },
    {
      id: 7,
      link: "https://youtu.be/xrlPhOnUcpg",
      youtubeId: "xrlPhOnUcpg",
      title: "퓨바 없이 에피소드 10-31 클리어 공략!",
    },
    {
      id: 8,
      link: "https://youtu.be/Axi_gXN5jVI",
      youtubeId: "Axi_gXN5jVI",
      title: "💎150,000크리스탈 준비했습니다.. 오늘은 퓨어바닐라맛 가능할까요..?",
    },
    {
      id: 9,
      link: "https://youtu.be/OO1GELTfyOg",
      youtubeId: "OO1GELTfyOg",
      title:
        "고대 등급 퓨어바닐라 쿠키 뽑기 레전드 찍었습니다 ㄷㄷ 40만원 현질 차원뽑기에 올인!! 퓨어바닐라맛 쿠키 뽑기",
    },
    {
      id: 10,
      link: "https://youtu.be/o4dl7yv3SqI",
      youtubeId: "o4dl7yv3SqI",
      title: "4월8일 역대급 대규모 업데이트 패치노트!! 퓨어바닐라 고대쿠키 & 블랙레이즌 신규쿠키 & 신규컨텐츠",
    },
  ];
};
