import React from "react";
import styled from "styled-components";

export interface TabItem {
  name: string;
  value: string;
}

interface Props {
  items: TabItem[];
  value: string;
  onChange: (value: string) => void;
}

const Tab: React.FC<Props> = ({ items, value, onChange }) => {
  return (
    <TabBlock>
      {items.map((item) => (
        <TabWrapper
          key={`tab-${item.value}`}
          onClick={() => {
            onChange(item.value);
          }}
        >
          <Name active={item.value === value}>{item.name}</Name>
          <Underline active={item.value === value} />
        </TabWrapper>
      ))}
    </TabBlock>
  );
};

export default Tab;

const TabBlock = styled.div`
  display: flex;
  align-items: center;
`;

const TabWrapper = styled.div`
  cursor: pointer;
`;

const Name = styled.div<{ active: boolean }>`
  text-align: center;
  font-size: 1.05rem;
  font-weight: bold;
  padding: 8px 16px;
  color: ${(props) => (props.active ? "#000" : "#888")};
`;

const Underline = styled.div<{ active: boolean }>`
  height: 2px;
  width: 100%;
  background-color: ${(props) => (props.active ? "#000" : "transparent")};
`;
