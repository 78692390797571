import React, { useEffect } from "react";
import { isIE } from "react-device-detect";
import styled from "styled-components";

interface Props {
  width: string;
  height: string;
  unit: string;
  name: string;
}

const KakaoAdFitBox: React.FC<Props> = ({ width, height, unit, name }) => {
  useEffect(() => {
    if (isIE) {
      return;
    }

    let ins: any = document.createElement("ins");
    let scr: any = document.createElement("script");

    ins.className = "kakao_ad_area";
    ins.style = "display:none; width:100%;";
    scr.async = "true";
    scr.type = "text/javascript";
    scr.src = "//t1.daumcdn.net/kas/static/ba.min.js";
    ins.setAttribute("data-ad-width", width);
    ins.setAttribute("data-ad-height", height);
    ins.setAttribute("data-ad-unit", unit);

    const adFitDocument = document.querySelector(`.${name}`);

    if (adFitDocument) {
      adFitDocument.appendChild(ins);
      adFitDocument.appendChild(scr);
    }
  }, [height, width, unit, name]);

  if (isIE) {
    return (
      <Empty width={Number(width)} height={Number(height)}>
        AD
      </Empty>
    );
  }

  return <div className={name}></div>;
};

export default KakaoAdFitBox;

const Empty = styled.div<{ width: number; height: number }>`
  background-color: #f2f2f5;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: #999;
  min-width: ${(props) => props.width}px;
  width: 100%;
  height: ${(props) => props.height}px;
`;
