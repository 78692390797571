import { gql } from "@apollo/client";

export const SAVE_COOKIE_COMMENT = gql`
  mutation createCookieComment($input: createCookieCommentInput!) {
    createCookieComment(input: $input) {
      cookieComment {
        content
      }
    }
  }
`;
