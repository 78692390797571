import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import color from "../../../styles/color";
import { ThemeContext } from "../../../styles/Theme";
import GoogleAdsense from "../../ad/GoogleAdsense";
import Header from "../../basic/header/Header";
import Layout from "../../container/Layout";

interface Props {
  PatchNote: React.ReactElement;
  Youtube: React.ReactElement;
}

const HomePageTemplate: React.FC<Props> = ({ PatchNote, Youtube }) => {
  const [theme] = React.useContext(ThemeContext);
  const colors = color[theme];
  const boxBackgroundColor = "#45454c";
  const boxFontColor = "#000";
  const borderColor = "#ff8c00";

  const cookieSize = 44;

  return (
    <Layout Header={<Header />}>
      <Container>
        {/* <PatchNoteListBox>
          <SubTitle>쿠키노트</SubTitle>

          <div>{PatchNote}</div>
        </PatchNoteListBox> */}

        <ButtonBoxWrapper>
          <Link to="/cookies">
            <ButtonBox backgroundColor={boxBackgroundColor} borderColor={borderColor}>
              <ButtonBoxContainer>
                <div>
                  <RoundImage
                    borderColor={colors.primary}
                    src="http://image.rok.gg/cookie/character/epic/슈크림맛+쿠키.png"
                    size={cookieSize}
                  />
                  <RoundImageWrapper>
                    <RoundImage
                      borderColor={colors.primary}
                      src="http://image.rok.gg/cookie/character/epic/다크초코+쿠키.png"
                      size={cookieSize}
                    />
                  </RoundImageWrapper>
                  <RoundImageWrapper>
                    <RoundImage
                      borderColor={colors.primary}
                      src="http://image.rok.gg/cookie/character/epic/석류맛+쿠키.png"
                      size={cookieSize}
                    />
                  </RoundImageWrapper>
                </div>

                <ButtonTitleWrapper>
                  <ButtonTitle color={boxFontColor}>쿠키 티어표</ButtonTitle>
                </ButtonTitleWrapper>
              </ButtonBoxContainer>
            </ButtonBox>
          </Link>
        </ButtonBoxWrapper>

        <ButtonBoxWrapper>
          <Link to="/treasures">
            <ButtonBox backgroundColor={boxBackgroundColor} borderColor={borderColor}>
              <ButtonBoxContainer>
                <div>
                  <RoundImage
                    borderColor={colors.primary}
                    src="http://image.rok.gg/cookie/treasure/나이든+순례자의+승리를+향한+무공비급.png"
                    size={cookieSize}
                  />
                  <RoundImageWrapper>
                    <RoundImage
                      borderColor={colors.primary}
                      src="http://image.rok.gg/cookie/treasure/설탕백조의+샤이닝+설탕깃털.png"
                      size={cookieSize}
                    />
                  </RoundImageWrapper>
                  <RoundImageWrapper>
                    <RoundImage
                      borderColor={colors.primary}
                      src="https://image.rok.gg/cookie/treasure/%EC%99%95%EC%9E%90+%EA%B3%B0%EC%A0%A4%EB%A6%AC%EC%9D%98%20%EB%A7%90%EB%9E%91%EB%A7%90%EB%9E%91%20%EC%A0%A4%EB%A6%AC%EC%8B%9C%EA%B3%84.png"
                      size={cookieSize}
                    />
                  </RoundImageWrapper>
                </div>

                <ButtonTitleWrapper>
                  <ButtonTitle color={boxFontColor}>보물 티어표</ButtonTitle>
                </ButtonTitleWrapper>
              </ButtonBoxContainer>
            </ButtonBox>
          </Link>
        </ButtonBoxWrapper>

        <ButtonBoxWrapper>
          <Link to="/posts">
            <ButtonBox backgroundColor={boxBackgroundColor} borderColor={borderColor}>
              <ButtonBoxContainer>
                <div>
                  <RoundImage
                    borderColor={colors.primary}
                    src="http://image.rok.gg/cookie/common/kingdom.png"
                    size={cookieSize}
                  />
                </div>

                <ButtonTitleWrapper>
                  <ButtonTitle color={boxFontColor}>꿀팁의 전당</ButtonTitle>
                </ButtonTitleWrapper>
              </ButtonBoxContainer>
            </ButtonBox>
          </Link>
        </ButtonBoxWrapper>

        <ButtonBoxWrapper>
          <Link to="/decks">
            <ButtonBox backgroundColor={boxBackgroundColor} borderColor={borderColor}>
              <ButtonBoxContainer>
                <div>
                  <RoundImage
                    borderColor={colors.primary}
                    src="http://image.rok.gg/cookie/common/kingdom.png"
                    size={cookieSize}
                  />
                </div>

                <ButtonTitleWrapper>
                  <ButtonTitle color={boxFontColor}>추천 덱</ButtonTitle>
                </ButtonTitleWrapper>
              </ButtonBoxContainer>
            </ButtonBox>
          </Link>
        </ButtonBoxWrapper>

        <ButtonBoxWrapper>
          <Link to="/coupons">
            <ButtonBox backgroundColor={boxBackgroundColor} borderColor={borderColor}>
              <ButtonBoxContainer>
                <div>
                  <RoundImage
                    borderColor={colors.primary}
                    src="http://image.rok.gg/cookie/common/kingdom.png"
                    size={cookieSize}
                  />
                </div>

                <ButtonTitleWrapper>
                  <ButtonTitle color={boxFontColor}>쿠키런 킹덤 쿠폰</ButtonTitle>
                </ButtonTitleWrapper>
              </ButtonBoxContainer>
            </ButtonBox>
          </Link>
        </ButtonBoxWrapper>

        <YoutubeListBox>
          <SubTitleWrapper>
            <SubTitle>추천영상</SubTitle>
          </SubTitleWrapper>
          <div>{Youtube}</div>
        </YoutubeListBox>

        <ADBoxWrapper>
          <GoogleAdsense className="home-google-ad-1" adClient="ca-pub-8271789671155671" adSlot="1479756918" />
        </ADBoxWrapper>
      </Container>
    </Layout>
  );
};

export default HomePageTemplate;

const Container = styled.div`
  width: 100%;
`;

// const PatchNoteListBox = styled.div`
//   padding: 16px;
//   margin-bottom: 8px;
//   background-color: #fff;
//   border-bottom: 1px solid #fafafa;
// `;

const SubTitleWrapper = styled.div`
  padding: 0 20px;
`;

const SubTitle = styled.h6`
  margin-top: 0;
  margin-bottom: 8px;
  color: #222222;
`;

const ButtonBoxWrapper = styled.div``;

const ButtonBox = styled.div<{ backgroundColor: string; borderColor: string }>`
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #fafafa;
`;

const ButtonBoxContainer = styled.div`
  padding: 18px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const RoundImageWrapper = styled.span`
  position: relative;
  margin-left: -8px;
  z-index: 2;
`;

const RoundImage = styled.img<{ size: number; borderColor: string }>`
  position: relative;
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  border-radius: ${(props) => props.size}px;
  padding: 4px;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2);
`;

const ButtonTitleWrapper = styled.div``;

const ButtonTitle = styled.p<{ color: string }>`
  color: ${(props) => props.color};
  margin: 0;
  font-size: 1.125rem;
  font-weight: bold;
`;

const YoutubeListBox = styled.div`
  margin-top: 8px;
  background-color: #fff;
  padding-bottom: 10px;
  padding-top: 16px;
`;

const ADBoxWrapper = styled.div`
  margin-top: 8px;
  background-color: #fff;
  min-height: 300px;
`;
