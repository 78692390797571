import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { APIGetPatchNote } from "../apis/patchnote/patchnote";
import { IPatchNote } from "../apis/patchnote/patchnote.type";
import EmptyLayout from "../components/container/EmptyLayout";
import PatchNoteDetailPageTemplate from "../components/pages/patchnote/detail/PatchNoteDetailPageTemplate";

const PatchNoteDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [detail, setDetail] = useState<IPatchNote | null>(null);

  useEffect(() => {
    if (id) {
      setDetail(APIGetPatchNote(Number(id)));
    }
  }, [id]);

  if (!detail) {
    return <EmptyLayout />;
  }

  return <PatchNoteDetailPageTemplate item={detail} />;
};

export default PatchNoteDetailPage;
