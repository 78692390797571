import React from "react";
import styled from "styled-components";
import BackHeader from "../basic/header/BackHeader";
import EmptyMessageBox from "./EmptyMessageBox";
import Layout from "./Layout";

interface Props {}

const EmptyLayout: React.FC<Props> = () => {
  return (
    <Layout Header={<BackHeader />}>
      <Container>
        <EmptyMessageBox />
      </Container>
    </Layout>
  );
};

export default EmptyLayout;

const Container = styled.div`
  background-color: #fff;
  min-height: calc(100vh - 180px);
`;
