import { gql } from "@apollo/client";

export const SAVE_COOKIE_DECK_COMMENT = gql`
  mutation createCookieDeckComment($input: createCookieDeckCommentInput!) {
    createCookieDeckComment(input: $input) {
      cookieDeckComment {
        content
      }
    }
  }
`;
