import React from "react";
import ReactQuill, { Quill } from "react-quill";
import "./QuillEditor.css";
import styled from "styled-components";
import MagicUrl from "quill-magic-url";
import ImageResize from "quill-image-resize-module-react";
import { APIImageUpload } from "../../apis/common/upload";

Quill.register("modules/magicUrl", MagicUrl);
Quill.register("modules/imageResize", ImageResize);

const Size = Quill.import("attributors/style/size");
Size.whitelist = ["16px", "18px", "20px", "22px", "24px"];
Quill.register(Size, true);

interface Props {
  placeholder?: string;
  contents: string;
  onChange: (contents: string) => void;
}

interface IState {}

class QuillEditor extends React.Component<Props, IState> {
  public fileElement: any;
  public quillRef: any;

  state: IState = {};

  imageHandler = () => {
    this.handleOpenFile();
  };

  modules = {
    toolbar: {
      container: "#toolbar",
      handlers: { image: this.imageHandler },
    },
    clipboard: { matchVisual: false },
    imageResize: {
      modules: ["Resize", "DisplaySize", "Toolbar"],
    },
    magicUrl: true,
  };

  formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "size",
    "color",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "align",
  ];

  handleChangeFile = async (e: any) => {
    try {
      const images = e.target.files;

      for (const image of images) {
        const formData = new FormData();
        formData.append("files", image);
        const url = await APIImageUpload(formData);
        const quill = await this.quillRef.getEditor();
        const range: any = await quill.getSelection();
        quill.insertEmbed(range.index, "image", url);
        quill.setSelection(range.index + 1);
        quill.focus();
      }
    } catch (error) {
      return false;
    }
  };

  handleOpenFile = () => {
    this.fileElement.click();
  };

  handleFocus = () => {
    const quill = this.quillRef.getEditor();
    quill.focus();
  };

  render() {
    const { contents, onChange, placeholder } = this.props;

    return (
      <>
        <QuillWrapper onClick={this.handleFocus}>
          <CustomToolbar />
          <ReactQuill
            placeholder={placeholder}
            ref={(el) => (this.quillRef = el)}
            value={contents}
            onChange={onChange}
            theme="snow"
            modules={this.modules}
            formats={this.formats}
            style={{ minHeight: 440 }}
          />
        </QuillWrapper>
        <input
          ref={(el) => (this.fileElement = el)}
          type="file"
          multiple
          name="file"
          onChange={this.handleChangeFile}
          accept="image/*"
          style={{ display: "none" }}
        />
      </>
    );
  }
}

export default QuillEditor;

const QuillWrapper = styled.div`
  min-height: 500px;

  p,
  div,
  a,
  span {
    font-size: 16px;
  }
`;

const CustomToolbar = () => (
  <div id="toolbar" className="fixed-editor">
    <span className="ql-formats pc-only">
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
    </span>
    <span className="ql-formats pc-only">
      <button className="ql-blockquote" />
      <button className="ql-code-block" />
    </span>
    <span className="ql-formats">
      <select className="ql-align" />
    </span>
    <span className="ql-formats">
      <button className="ql-image" />
    </span>
  </div>
);
