import React from "react";
import { IconBaseProps } from "react-icons";
import { HiPencilAlt } from "react-icons/hi";

interface Props extends IconBaseProps {}

const PenIcon: React.FC<Props> = ({ size, color }) => {
  return <HiPencilAlt size={size} color={color} />;
};

export default PenIcon;
