import { gql } from "@apollo/client";

export const INCREMENT_COOKIE_DECK_VIEW_COUNT = gql`
  mutation cookieDeckViewCount($id: ID!) {
    cookieDeckViewCount(id: $id) {
      id
      viewCount
    }
  }
`;

export const INCREMENT_COOKIE_DECK_COMMENT_COUNT = gql`
  mutation cookieDeckCommentCount($id: ID!) {
    cookieDeckCommentCount(id: $id) {
      id
      commentCount
    }
  }
`;
