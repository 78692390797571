import React from "react";
import styled from "styled-components";
import { IPost } from "../../../../apis/post/post.types";
import { timeForToday } from "../../../../utils/date";

interface Props {
  item: IPost;
}

const PostListItem: React.FC<Props> = ({ item }) => {
  const getThumbnail = () => {
    try {
      const content = item.content;
      const matchts = content.match(/<img[^>]* src="([^"]*)"[^>]*>/g);
      const images = matchts ? matchts.map((v: string) => v.replace('<img src="', "").replace('">', "")) : [];

      if (images.length > 0) {
        return images[0];
      }

      return "";
    } catch (error) {
      return "";
    }
  };

  return (
    <PostListItemBlock>
      <Left>
        <FirstRow>
          <Title>{item.title}</Title>
        </FirstRow>

        <SecondRow>
          <SecondRowText>{item.user.username}</SecondRowText>
          <SecondRowText>{timeForToday(item.created_at)}</SecondRowText>
          <SecondRowText>조회 {item.viewCount || 0}</SecondRowText>
          <SecondRowText>댓글 {item.commentCount || 0}</SecondRowText>
          <SecondRowText>추천 {item.likeCount || 0}</SecondRowText>
        </SecondRow>
      </Left>

      {getThumbnail() && (
        <Right>
          <Thumbnail src={getThumbnail()} />
        </Right>
      )}
    </PostListItemBlock>
  );
};

export default PostListItem;

const PostListItemBlock = styled.div`
  padding: 14px;
  display: flex;
  justify-content: space-between;
`;

const FirstRow = styled.div``;

const Title = styled.div`
  font-family: sans-serif;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.4;
  color: #000;
`;

const Left = styled.div`
  flex: 1;
`;

const Right = styled.div`
  width: 60px;
  text-align: right;
`;

const Thumbnail = styled.img`
  margin-top: 2px;
  height: 54px;
  width: 54px;
  border-radius: 4px;
`;

const SecondRow = styled.div`
  margin-top: 4px;
`;

const SecondRowText = styled.span`
  margin-right: 8px;
  font-size: 0.675rem;
  color: #999;
`;
