import React from "react";
import { IconBaseProps } from "react-icons";
import { RiArrowUpSLine } from "react-icons/ri";

interface Props extends IconBaseProps {}

const ArrowUpIcon: React.FC<Props> = ({ size, color }) => {
  return <RiArrowUpSLine size={size} color={color} />;
};

export default ArrowUpIcon;
