import { API_URL } from "../config";

export const APIImageUpload = async (data: FormData): Promise<string> => {
  try {
    const response = await fetch(API_URL + "/upload", {
      method: "POST",
      headers: new Headers({
        Authorization: "Bearer " + localStorage.getItem("token"),
      }),
      body: data,
    });

    const json = await response.json();
    return json[0].url;
  } catch (error) {
    return "";
  }
};
