import React from "react";
import styled from "styled-components";

interface Props {
  color?: string;
  disabled?: boolean;
  text: string;
  onClick?: () => void;
}

const Button: React.FC<Props> = ({ color = "#151e2e", disabled, text, onClick }) => {
  return (
    <ButtonBLock color={color} disabled={disabled} onClick={onClick}>
      <Text>{text}</Text>
    </ButtonBLock>
  );
};

export default Button;

const ButtonBLock = styled.button<{ disabled?: boolean; color: string }>`
  border: 0;
  background-color: ${(props) => (props.disabled ? "#888" : props.color)};
  padding: 16px;
  width: 100%;
  border-radius: 3px;
`;

const Text = styled.span`
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
`;
