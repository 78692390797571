import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { user } from "../../modules/auth/auth";
import { SAVE_COOKIE_DECK } from "../../apis/deck/deck";
import { COOKIE_SLOT, IAddCookieDeck } from "../../apis/deck/deck.types";
import { ICookie } from "../../apis/cookie/cookie.type";
import { ICookieTopping } from "../../apis/cookie/cookie.topping.types";
import BackHeader from "../../components/basic/header/BackHeader";
import CreateCookieDeckPageTemplate from "../../components/pages/cookie/deck/create/CreateCookieDeckPageTemplate";
import CreateCookieDeckNextText from "../../components/pages/cookie/deck/create/CreateCookieDeckNextText";
import DeckCookieList from "../../components/pages/cookie/deck/common/DeckCookieList";
import DeckCookieSlot from "../../components/pages/cookie/deck/common/DeckCookieSlot";
import DeckToppingList from "../../components/pages/cookie/deck/common/DeckToppingList";
import CreateCookieTitleWithContent from "../../components/pages/cookie/deck/create/CreateCookieTitleWithContent";

enum CREATE_COOKIE_STEP {
  STEP_1_SELECT_COOKIE = "select_cookie",
  STEP_2_SELECT_TOPPING = "select_topping",
  STEP_LAST_TITLE_CONTENT = "LAST",
}

const CreateCookieDeckPage: React.FC = () => {
  const history = useHistory();
  const [addDeck] = useMutation(SAVE_COOKIE_DECK);
  const loginUser = useSelector(user);

  const [step, setStep] = useState(CREATE_COOKIE_STEP.STEP_1_SELECT_COOKIE);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [slotFocus, setSlotFocus] = useState(COOKIE_SLOT.SLOT1);
  const [slot1Cookie, setSlot1Cookie] = useState<ICookie | null>(null);
  const [slot2Cookie, setSlot2Cookie] = useState<ICookie | null>(null);
  const [slot3Cookie, setSlot3Cookie] = useState<ICookie | null>(null);
  const [slot4Cookie, setSlot4Cookie] = useState<ICookie | null>(null);
  const [slot5Cookie, setSlot5Cookie] = useState<ICookie | null>(null);

  const [slot1CookieTopping, setSlot1CookieTopping] = useState<ICookieTopping | null>(null);
  const [slot2CookieTopping, setSlot2CookieTopping] = useState<ICookieTopping | null>(null);
  const [slot3CookieTopping, setSlot3CookieTopping] = useState<ICookieTopping | null>(null);
  const [slot4CookieTopping, setSlot4CookieTopping] = useState<ICookieTopping | null>(null);
  const [slot5CookieTopping, setSlot5CookieTopping] = useState<ICookieTopping | null>(null);

  const fetchSubmit = async () => {
    if (!loginUser) {
      return;
    }

    const isOk = window.confirm("저장하시겠습니까?");

    if (!isOk) {
      return;
    }

    try {
      const data: IAddCookieDeck = {
        title,
        content,
        cookieA: slot1Cookie!.id,
        cookieB: slot2Cookie!.id,
        cookieC: slot3Cookie!.id,
        cookieD: slot4Cookie!.id,
        cookieE: slot5Cookie!.id,
        toppingA: slot1CookieTopping!.id,
        toppingB: slot2CookieTopping!.id,
        toppingC: slot3CookieTopping!.id,
        toppingD: slot4CookieTopping!.id,
        toppingE: slot5CookieTopping!.id,
        user: loginUser.id.toString(),
        published_at: new Date(),
      };

      await addDeck({
        variables: { input: { data } },
      });

      alert("등록되었습니다.");

      history.push("/decks");
    } catch (error) {
      alert("잠시 후 다시 시도해주세요.");
      return;
    }
  };

  const isSlot1Focused = slotFocus === COOKIE_SLOT.SLOT1;
  const isSlot2Focused = slotFocus === COOKIE_SLOT.SLOT2;
  const isSlot3Focused = slotFocus === COOKIE_SLOT.SLOT3;
  const isSlot4Focused = slotFocus === COOKIE_SLOT.SLOT4;
  const isSlot5Focused = slotFocus === COOKIE_SLOT.SLOT5;

  const selectedCookies = [slot1Cookie, slot2Cookie, slot3Cookie, slot4Cookie, slot5Cookie].filter(
    (v: ICookie | null) => v && v.id
  ) as ICookie[];

  const selectedToppings = [
    slot1CookieTopping,
    slot2CookieTopping,
    slot3CookieTopping,
    slot4CookieTopping,
    slot5CookieTopping,
  ].filter((v: ICookieTopping | null) => v && v.id) as ICookieTopping[];

  const isCookieStep = step === CREATE_COOKIE_STEP.STEP_1_SELECT_COOKIE;
  const isToppingStep = step === CREATE_COOKIE_STEP.STEP_2_SELECT_TOPPING;
  const isLastStep = step === CREATE_COOKIE_STEP.STEP_LAST_TITLE_CONTENT;

  const handleClickHeaderBackButton = () => {
    if (isCookieStep) {
      history.push("/decks");
    } else if (isToppingStep) {
      setStep(CREATE_COOKIE_STEP.STEP_1_SELECT_COOKIE);
    } else if (isLastStep) {
      setStep(CREATE_COOKIE_STEP.STEP_2_SELECT_TOPPING);
    }
  };

  const handleNextStep = () => {
    if (isCookieStep) {
      if (!slot1Cookie || !slot2Cookie || !slot3Cookie || !slot4Cookie || !slot5Cookie) {
        alert("아직 비어있는 슬롯이 있습니다.\n다시 확인해주세요.");
        return;
      }

      setStep(CREATE_COOKIE_STEP.STEP_2_SELECT_TOPPING);
      setSlotFocus(COOKIE_SLOT.SLOT1);
    } else if (isToppingStep) {
      if (
        !slot1CookieTopping ||
        !slot2CookieTopping ||
        !slot3CookieTopping ||
        !slot4CookieTopping ||
        !slot5CookieTopping
      ) {
        alert("아직 비어있는 슬롯이 있습니다.\n다시 확인해주세요.");
        return;
      }

      setStep(CREATE_COOKIE_STEP.STEP_LAST_TITLE_CONTENT);
    } else if (isLastStep) {
      if (!title || !title.trim()) {
        alert("제목을 입력해주세요.");
        return;
      } else if (!content || !content.trim()) {
        alert("내용을 입력해주세요.");
        return;
      }

      fetchSubmit();
    }
  };

  return (
    <CreateCookieDeckPageTemplate
      Header={
        <BackHeader
          title="덱 빌더"
          onClick={handleClickHeaderBackButton}
          Right={<CreateCookieDeckNextText title={isLastStep ? "저장" : undefined} onClick={handleNextStep} />}
        />
      }
      Slot1={
        <DeckCookieSlot
          title="SLOT 1"
          active={isSlot1Focused}
          cookie={slot1Cookie}
          onClickCookieImage={() => {
            if (isCookieStep) {
              setSlot1Cookie(null);
            } else {
              setSlotFocus(COOKIE_SLOT.SLOT1);
            }
          }}
          onClickEmptySlot={() => {
            setSlotFocus(isSlot1Focused ? COOKIE_SLOT.EMPTY : COOKIE_SLOT.SLOT1);
          }}
          topping={slot1CookieTopping}
        />
      }
      Slot2={
        <DeckCookieSlot
          title="SLOT 2"
          active={isSlot2Focused}
          cookie={slot2Cookie}
          onClickCookieImage={() => {
            if (isCookieStep) {
              setSlot2Cookie(null);
            } else {
              setSlotFocus(COOKIE_SLOT.SLOT2);
            }
          }}
          onClickEmptySlot={() => {
            setSlotFocus(isSlot2Focused ? COOKIE_SLOT.EMPTY : COOKIE_SLOT.SLOT2);
          }}
          topping={slot2CookieTopping}
        />
      }
      Slot3={
        <DeckCookieSlot
          title="SLOT 3"
          active={isSlot3Focused}
          cookie={slot3Cookie}
          onClickCookieImage={() => {
            if (isCookieStep) {
              setSlot3Cookie(null);
            } else {
              setSlotFocus(COOKIE_SLOT.SLOT3);
            }
          }}
          onClickEmptySlot={() => {
            setSlotFocus(isSlot3Focused ? COOKIE_SLOT.EMPTY : COOKIE_SLOT.SLOT3);
          }}
          topping={slot3CookieTopping}
        />
      }
      Slot4={
        <DeckCookieSlot
          title="SLOT 4"
          active={isSlot4Focused}
          cookie={slot4Cookie}
          onClickCookieImage={() => {
            if (isCookieStep) {
              setSlot4Cookie(null);
            } else {
              setSlotFocus(COOKIE_SLOT.SLOT4);
            }
          }}
          onClickEmptySlot={() => {
            setSlotFocus(isSlot4Focused ? COOKIE_SLOT.EMPTY : COOKIE_SLOT.SLOT4);
          }}
          topping={slot4CookieTopping}
        />
      }
      Slot5={
        <DeckCookieSlot
          title="SLOT 5"
          active={isSlot5Focused}
          cookie={slot5Cookie}
          onClickCookieImage={() => {
            if (isCookieStep) {
              setSlot5Cookie(null);
            } else {
              setSlotFocus(COOKIE_SLOT.SLOT5);
            }
          }}
          onClickEmptySlot={() => {
            setSlotFocus(isSlot5Focused ? COOKIE_SLOT.EMPTY : COOKIE_SLOT.SLOT5);
          }}
          topping={slot5CookieTopping}
        />
      }
      CookieList={
        step === CREATE_COOKIE_STEP.STEP_1_SELECT_COOKIE ? (
          <DeckCookieList
            selectedCookies={selectedCookies}
            onClick={(item) => {
              if (
                slot1Cookie === item ||
                slot2Cookie === item ||
                slot3Cookie === item ||
                slot4Cookie === item ||
                slot5Cookie === item
              ) {
                return;
              }

              if (isSlot1Focused) {
                setSlot1Cookie(item);
                setSlotFocus(COOKIE_SLOT.SLOT2);
              } else if (isSlot2Focused) {
                setSlot2Cookie(item);
                setSlotFocus(COOKIE_SLOT.SLOT3);
              } else if (isSlot3Focused) {
                setSlot3Cookie(item);
                setSlotFocus(COOKIE_SLOT.SLOT4);
              } else if (isSlot4Focused) {
                setSlot4Cookie(item);
                setSlotFocus(COOKIE_SLOT.SLOT5);
              } else if (isSlot5Focused) {
                setSlot5Cookie(item);
                setSlotFocus(COOKIE_SLOT.SLOT1);

                if (slot1Cookie && slot2Cookie && slot3Cookie && slot4Cookie) {
                  setStep(CREATE_COOKIE_STEP.STEP_2_SELECT_TOPPING);
                }
              }
            }}
          />
        ) : step === CREATE_COOKIE_STEP.STEP_2_SELECT_TOPPING ? (
          <DeckToppingList
            selectedToppings={selectedToppings}
            onClick={(item) => {
              if (isSlot1Focused) {
                setSlot1CookieTopping(item);
                setSlotFocus(COOKIE_SLOT.SLOT2);
              } else if (isSlot2Focused) {
                setSlot2CookieTopping(item);
                setSlotFocus(COOKIE_SLOT.SLOT3);
              } else if (isSlot3Focused) {
                setSlot3CookieTopping(item);
                setSlotFocus(COOKIE_SLOT.SLOT4);
              } else if (isSlot4Focused) {
                setSlot4CookieTopping(item);
                setSlotFocus(COOKIE_SLOT.SLOT5);
              } else if (isSlot5Focused) {
                setSlot5CookieTopping(item);
                setSlotFocus(COOKIE_SLOT.SLOT1);

                if (slot1CookieTopping && slot2CookieTopping && slot3CookieTopping && slot4CookieTopping) {
                  setStep(CREATE_COOKIE_STEP.STEP_LAST_TITLE_CONTENT);
                }
              }
            }}
          />
        ) : (
          <CreateCookieTitleWithContent
            title={title}
            onChangeTitle={setTitle}
            content={content}
            onChangeContent={setContent}
          />
        )
      }
    />
  );
};

export default CreateCookieDeckPage;
