import React from "react";
import { useHistory } from "react-router";
import Button from "../../components/button/Button";
import ProfilePageTemplate from "../../components/pages/auth/profile/ProfilePageTemplate";

const ProfilePage: React.FC = () => {
  const history = useHistory();

  const handlePressPasswordChange = () => {
    history.push("/password");
  };

  return (
    <ProfilePageTemplate PasswordChangeButton={<Button text="비밀번호 변경" onClick={handlePressPasswordChange} />} />
  );
};

export default ProfilePage;
