import React from "react";
import styled from "styled-components";

interface Props {
  content: string;
}

const Content: React.FC<Props> = ({ content }) => <ContentWrapper dangerouslySetInnerHTML={{ __html: content }} />;

export default Content;

const paddingSize = 16;

const ContentWrapper = styled.div`
  img {
    width: calc(100% + 32px);
    margin-left: -${paddingSize}px;
  }

  p {
    padding: 0 ${paddingSize}px;
    margin-top: 0;
    margin-bottom: 0;
    font-family: sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.6;
  }

  a {
    color: #06c;
    text-decoration: underline;
  }
`;
