import React from "react";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { IUser } from "../../../types/IUser";

interface Props {
  user?: IUser | null;
  onClose: () => void;
}

const HeaderSide: React.FC<Props> = ({ user, onClose }) => {
  const isLoggedIn = user ? true : false;

  const handleLogout = () => {
    const isOk = window.confirm("로그아웃 하시겠습니까?");

    if (!isOk) {
      return;
    }

    localStorage.clear();
    window.location.href = "/login";
  };

  const handleClickLoginButton = () => {
    window.location.href = "/login";
  };

  return (
    <HeaderSideBlock>
      <SideBlur onClick={onClose} />
      <SideContainer>
        {isLoggedIn ? (
          <Container>
            <div>
              <WelcomeText>
                <Name>{user?.username} </Name>님 안녕하세요.
              </WelcomeText>
              <MenuList>
                <Menu>
                  <Link to="/profile">프로필</Link>
                </Menu>
                {/* <Menu>내가 작성한 댓글</Menu> */}
              </MenuList>
            </div>
            <LogoutButtonWrapper>
              <Button onClick={handleLogout}>로그아웃</Button>
            </LogoutButtonWrapper>
          </Container>
        ) : (
          <Container>
            <div>
              <RequestLoginMessage>로그인 후 더 많은 서비스를{"\n"}이용해보세요.</RequestLoginMessage>
              <Button onClick={handleClickLoginButton}>로그인하기</Button>
            </div>
            <BottomLogo>© 쿠킹지지. All rights reserved</BottomLogo>
          </Container>
        )}
      </SideContainer>
    </HeaderSideBlock>
  );
};

export default HeaderSide;

const showSide = keyframes`
  0% {
    right: -75%;
  }
  100% {
    right: 0;
  }
`;

const HeaderSideBlock = styled.aside`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

const SideBlur = styled.div`
  position: fixed;
  top: 0;
  height: 100%;
  width: 25%;

  @media (min-width: 500px) {
    width: 100%;
  }
`;

const SideContainer = styled.div`
  position: fixed;
  top: 0;
  height: 100%;
  width: 75%;
  background-color: #fff;
  animation: ${showSide} 250ms ease-in-out both;
  z-index: 999;

  @media (min-width: 500px) {
    max-width: 500px;
    width: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const Name = styled.span`
  font-size: 2rem;
`;

const WelcomeText = styled.p`
  color: #222;
  font-size: 1.15rem;
  font-weight: bold;
`;

const MenuList = styled.ul`
  margin-top: 32px;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  padding: 0;
`;

const Menu = styled.li`
  list-style: none;
  margin: 24px 16px;
  font-size: 1.5rem;
  color: #3e4044;

  a {
    color: #3e4044;
  }
`;

const LogoutButtonWrapper = styled.div`
  margin-top: 24px;
`;

const Button = styled.div`
  border: 0;
  background-color: #151e2e;
  padding: 16px;
  border-radius: 3px;
  text-align: center;
  color: #fff;
`;

const RequestLoginMessage = styled.p`
  color: #222;
  font-size: 1.15rem;
  font-weight: bold;
  line-height: 1.6;
  white-space: pre-line;
`;

const BottomLogo = styled.div`
  margin-top: 24px;
  color: #151e2e;
  font-family: sans-serif;
  font-size: 0.725rem;
  font-weight: bold;
  text-align: center;
`;
