const color: IColor = {
  light: {
    primary: "#ff8c00",
    background: "#222222",
    border: "#eaeaea",
  },
  dark: {
    primary: "#1a2436",
    background: "#222222",
    border: "#eaeaea",
  },
};

export default color;

export interface IColor {
  light: Colors;
  dark: Colors;
}

interface Colors {
  primary: string;
  background: string;
  border: string;
}
