import React from "react";
import { IconBaseProps } from "react-icons";
import { HiArrowLeft } from "react-icons/hi";

interface Props extends IconBaseProps {}

const BackIcon: React.FC<Props> = ({ size, color }) => {
  return <HiArrowLeft size={size} color={color} />;
};

export default BackIcon;
