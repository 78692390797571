import React, { useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { Grade } from "../../../../apis/common/common.type";
import { ICookieDetail } from "../../../../apis/cookie/cookie.type";
import { convertTier } from "../../../../utils/tier";
import BackHeader from "../../../basic/header/BackHeader";
import Layout from "../../../container/Layout";
import ArrowDownIcon from "../../../icon/ArrowDownIcon";
import ArrowUpIcon from "../../../icon/ArrowUpIcon";

interface Props {
  item: ICookieDetail;
  CommentInput: React.ReactElement;
  CommentList: React.ReactElement;
}

const CookieDetailPageTemplate: React.FC<Props> = ({ item, CommentInput, CommentList }) => {
  const [expandStory, setExpandStory] = useState(false);

  const history = useHistory();

  const handleBack = () => {
    history.push("/cookies");
  };

  const getGradeColor = () => {
    if (item.grade === Grade.에이션트) {
      return "#8400ff";
    }

    if (item.grade === Grade.에픽) {
      return "#cc379f";
    }

    if (item.grade === Grade.레어) {
      return "#0089ff";
    }

    if (item.grade === Grade.커먼) {
      return "#f4f4f4";
    }

    return "#0089ff";
  };

  return (
    <Layout Header={<BackHeader title={item.name} onClick={handleBack} />}>
      <Container>
        <Box>
          <ImageWithName>
            <Image src={item.thumbnail} />

            <NameWrapper>
              <GradeWithType>
                <GradeName color={getGradeColor()}>{item.grade}</GradeName>
                <Type>{item.type}</Type>
              </GradeWithType>
              <Name>{item.name}</Name>
            </NameWrapper>
          </ImageWithName>
        </Box>

        {item.story && (
          <Box>
            <TitleWithExpand
              onClick={() => {
                setExpandStory(!expandStory);
              }}
            >
              <div>
                <BoxTitleExpand>스토리</BoxTitleExpand>
              </div>
              <div>{expandStory ? <ArrowUpIcon size={20} /> : <ArrowDownIcon size={20} />}</div>
            </TitleWithExpand>
            {expandStory && <Story>{item.story}</Story>}
          </Box>
        )}

        {item.skillName && item.skillDescription && (
          <Box>
            <BoxTitle>스킬</BoxTitle>

            <Inline>
              <div>{item.skillImage && <SkillImage src={item.skillImage} />}</div>
              <div>
                <SkillName>{item.skillName}</SkillName>
                <SkillDescription>{item.skillDescription}</SkillDescription>
              </div>
            </Inline>
          </Box>
        )}

        {item.recommendedTopping && (
          <Box>
            <BoxTitle>추천 토핑</BoxTitle>
            <RecommendedTopping>
              <img src={item.recommendedTopping.thumbnail} height={75} width={55} alt={item.recommendedTopping.name} />
              <RecommendedToppingContent>
                <RecommendedToppingName>{item.recommendedTopping.name}</RecommendedToppingName>
                <RecommendedToppingDescription>{item.recommendedTopping.description}</RecommendedToppingDescription>
                <RecommendedToppingOption>{item.recommendedTopping.synergy1}</RecommendedToppingOption>
                <RecommendedToppingOption>{item.recommendedTopping.synergy2}</RecommendedToppingOption>
              </RecommendedToppingContent>
            </RecommendedTopping>
          </Box>
        )}

        <Box>
          <BoxTitle>티어</BoxTitle>
          <TierList>
            <TierWrapper>
              <TierLabel>종합</TierLabel>
              <Tier>{item.totalTier > 100 ? "-" : convertTier(item.totalTier)}</Tier>
            </TierWrapper>

            <TierWrapper>
              <TierLabel>스토리</TierLabel>
              <Tier>{item.worldTier > 100 ? "-" : convertTier(item.worldTier)}</Tier>
            </TierWrapper>
            <TierWrapper>
              <TierLabel>아레나</TierLabel>
              <Tier>{item.arenaTier > 100 ? "-" : convertTier(item.arenaTier)}</Tier>
            </TierWrapper>
            <TierWrapper>
              <TierLabel>토벌전</TierLabel>
              <Tier>{item.warTier > 100 ? "-" : convertTier(item.warTier)}</Tier>
            </TierWrapper>
          </TierList>
        </Box>

        <Box>
          <BoxTitle>댓글</BoxTitle>
          <CommentListWrapper>{CommentList}</CommentListWrapper>
          <CommentInputWrapper>{CommentInput}</CommentInputWrapper>
        </Box>
      </Container>
    </Layout>
  );
};

export default CookieDetailPageTemplate;

const Container = styled.div`
  min-height: 600px;
  font-family: sans-serif;
`;

const Box = styled.div`
  background-color: #fff;
  padding: 20px;
  margin-bottom: 8px;
`;

const TitleWithExpand = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BoxTitleExpand = styled.h6`
  margin-top: 0;
  margin-bottom: 0;
  color: #888;
`;

const BoxTitle = styled.h6`
  margin-top: 0;
  margin-bottom: 16px;
  color: #888;
`;

const Image = styled.img`
  height: 100px;
  width: 100px;
`;

const ImageWithName = styled.div`
  display: flex;
`;

const NameWrapper = styled.div`
  padding: 20px;
`;

const Name = styled.h4`
  margin: 0;
`;

const GradeWithType = styled.div`
  display: flex;
`;

const Type = styled.span`
  color: #333;
  margin-left: 8px;
  font-size: 0.7rem;
`;

const GradeName = styled.p<{ color: string }>`
  color: ${(props) => props.color};
  font-weight: bold;
  font-size: 0.7rem;
  margin-bottom: 8px;
  margin-top: 0;
`;

const Inline = styled.div`
  display: flex;
`;

const SkillImage = styled.img`
  height: 58px;
  width: 58px;
  margin-top: 3px;
  margin-right: 12px;
  border-radius: 4px;
`;

const SkillName = styled.span`
  font-size: 1rem;
  font-weight: bold;
`;

const SkillDescription = styled.p`
  font-size: 0.925rem;
  line-height: 1.625;
  color: #333;
  margin-top: 4px;
  margin-bottom: 0;
  white-space: pre-line;
`;

const Story = styled.p`
  font-size: 0.925rem;
  line-height: 1.625;
  color: #333;
  white-space: pre-line;
  margin-top: 16px;
  margin-bottom: 0;
`;

const RecommendedTopping = styled.div`
  display: flex;
`;

const RecommendedToppingContent = styled.div`
  margin-left: 16px;
`;

const RecommendedToppingName = styled.h6`
  font-size: 1.125rem;
  font-weight: bold;
  color: #000;
  margin-top: 0;
  margin-bottom: 4px;
`;

const RecommendedToppingDescription = styled.p`
  margin: 0;
  font-size: 0.825rem;
  color: #333;
`;

const RecommendedToppingOption = styled.p`
  margin: 2px 0;
  font-size: 0.825rem;
  color: #0078ff;
`;

const TierList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TierWrapper = styled.div`
  margin: 4px 0;
  color: #222;
  font-size: 0.725rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TierLabel = styled.div`
  width: 60px;
  color: #000;
  font-size: 0.825rem;
  line-height: 1.45rem;
  text-align: center;
`;

const Tier = styled.div`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
  width: 40px;
  color: #000;
  text-align: center;
`;

const CommentListWrapper = styled.div``;

const CommentInputWrapper = styled.div`
  margin-top: 16px;
`;
