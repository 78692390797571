import React from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import BackHeader from "../../../basic/header/BackHeader";
import Layout from "../../../container/Layout";
import PenIcon from "../../../icon/PenIcon";

interface Props {
  isLoggedIn?: boolean;
  Tab?: React.ReactElement;
  List: React.ReactElement;
}

const PostListPageTemplate: React.FC<Props> = ({ isLoggedIn = false, Tab, List }) => {
  const history = useHistory();

  const BannerElement = () => {
    return (
      <BannerBox>
        <BannerTopText>쿠키런 킹덤 꿀팁이 있다면 공유해주세요!</BannerTopText>
        <BannerIconWithText>
          <IconWrapepr>
            <PenIcon size={20} color="#000" />
          </IconWrapepr>
          <BannerText>나만의 꿀팁 쓰러가기 Click!</BannerText>
        </BannerIconWithText>
      </BannerBox>
    );
  };

  return (
    <Layout Header={<BackHeader title="꿀팁의 전당" />}>
      <Container>
        <BannerWrapper>
          {isLoggedIn ? (
            <Link to="/create/post">{BannerElement()}</Link>
          ) : (
            <div
              onClick={() => {
                history.push(`/login?prev=${window.location.pathname}`);
              }}
            >
              {BannerElement()}
            </div>
          )}
        </BannerWrapper>

        <TabWrapper>{Tab}</TabWrapper>
        {List}
      </Container>
    </Layout>
  );
};

export default PostListPageTemplate;

const Container = styled.div`
  background-color: #fff;
  min-height: calc(100vh - 200px);
`;

const BannerWrapper = styled.div`
  padding: 20px;
`;

const BannerBox = styled.div`
  border: 1px solid #eaeaea;
  background-color: #f2f2f5;
  padding: 16px 16px 14px 16px;
  border-radius: 4px;
`;

const BannerTopText = styled.p`
  margin: 0;
  font-size: 0.725rem;
  color: #999;
`;

const BannerIconWithText = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
`;

const BannerText = styled.p`
  margin: 0;
  font-size: 0.925rem;
  font-weight: bold;
  color: #000;
`;

const IconWrapepr = styled.div`
  margin-top: 2px;
  margin-right: 8px;
`;

const TabWrapper = styled.div`
  border-bottom: 1px solid #f2f2f2;
`;
