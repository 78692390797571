import React from "react";
import YouTube, { Options } from "react-youtube";
import styled from "styled-components";
import { IYoutube } from "../../../apis/youtube/youtube.types";

interface Props {
  item: IYoutube;
}

const HomeYoutubeListItem: React.FC<Props> = ({ item }) => {
  const opts: Options = {
    height: "195",
    width: "280",
    playerVars: {
      autoplay: 0,
    },
  };

  return (
    <HomeYoutubeListItemBlock>
      <YouTube videoId={item.youtubeId} opts={opts} />
      <Title>{item.title}</Title>
    </HomeYoutubeListItemBlock>
  );
};

export default HomeYoutubeListItem;

const HomeYoutubeListItemBlock = styled.div`
  min-width: 280px;
`;

const Title = styled.p`
  margin: 4px 0;
  font-size: 0.925rem;
  font-weight: normal;
  font-family: sans-serif;
  color: #333;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  line-height: 1.4rem;
  height: 2.9rem;
`;
