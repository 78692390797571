import React from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import { GET_TOPPINGS } from "../../../../../apis/cookie/cookie.topping";
import { ICookieTopping } from "../../../../../apis/cookie/cookie.topping.types";

interface Props {
  selectedToppings: ICookieTopping[];
  onClick: (item: ICookieTopping) => void;
}

const DeckToppingList: React.FC<Props> = ({ selectedToppings, onClick }) => {
  const { data } = useQuery<{ toppings: ICookieTopping[] }>(GET_TOPPINGS);

  return (
    <DeckCookieListBlock>
      <TitleWrapper>
        <h3>쿠키마다 토핑을 선택해주세요</h3>
      </TitleWrapper>

      <CookieListWrapper>
        {data &&
          data.toppings.map((item) => (
            <CookieListItem
              key={`deck-topping-list-${item.id}`}
              onClick={() => {
                onClick(item);
              }}
            >
              <CookieListItemImageWrapper active={selectedToppings.includes(item)} activeColor="#b37314">
                <CookieListItemImage src={item.thumbnail} height={50} width={50} alt={item.name} />
              </CookieListItemImageWrapper>
              <CookieName>{item.name}</CookieName>
            </CookieListItem>
          ))}
      </CookieListWrapper>
    </DeckCookieListBlock>
  );
};

export default DeckToppingList;

const DeckCookieListBlock = styled.div``;

const TitleWrapper = styled.div`
  padding: 0 20px;
`;

const CookieListWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const CookieListItem = styled.div`
  margin: 8px;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 25%;
`;

const CookieListItemImageWrapper = styled.div<{ active?: boolean; activeColor?: string }>`
  border-radius: 100%;
  padding: 8px;
  border: ${(props) => (props.active && props.activeColor ? `3px solid ${props.activeColor}` : "3px solid #eaeaea")};
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CookieListItemImage = styled.img`
  height: 50px;
  width: 34px;
`;

const CookieName = styled.div`
  margin-top: 8px;
  text-align: center;
  font-size: 12px;
`;
