import React from "react";
import { useQuery } from "@apollo/client";
import styled from "styled-components";
import { GET_COOKIES } from "../../../../../apis/cookie/cookie";
import { ICookie } from "../../../../../apis/cookie/cookie.type";

interface Props {
  selectedCookies: ICookie[];
  onClick: (item: ICookie) => void;
}

const DeckCookieList: React.FC<Props> = ({ selectedCookies, onClick }) => {
  const { data } = useQuery<{ cookies: ICookie[] }>(GET_COOKIES);

  return (
    <DeckCookieListBlock>
      <TitleWrapper>
        <h3>5개의 쿠키를 선택해주세요</h3>
      </TitleWrapper>

      <CookieListWrapper>
        {data &&
          data.cookies.map((item) => (
            <CookieListItem
              key={`deck-cookie-list-${item.id}`}
              onClick={() => {
                onClick(item);
              }}
            >
              <CookieListItemImage
                active={selectedCookies.includes(item)}
                activeColor="#b37314"
                src={item.thumbnail}
                height={50}
                width={50}
                alt={item.name}
              />
              <CookieName>{item.name.replace(" 쿠키", "")}</CookieName>
            </CookieListItem>
          ))}
      </CookieListWrapper>
    </DeckCookieListBlock>
  );
};

export default DeckCookieList;

const DeckCookieListBlock = styled.div``;

const TitleWrapper = styled.div`
  padding: 0 20px;
`;

const CookieListWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const CookieListItem = styled.div`
  margin: 8px;
  border-radius: 50%;
`;

const CookieListItemImage = styled.img<{ active?: boolean; activeColor?: string }>`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  padding: 8px;
  border: ${(props) => (props.active && props.activeColor ? `3px solid ${props.activeColor}` : "3px solid #eaeaea")};
`;

const CookieName = styled.div`
  text-align: center;
  font-size: 12px;
`;
