import { gql } from "@apollo/client";

export enum POST_CATEGORY {
  유저팁 = "USER_TIPS",
  베스트팁 = "TIPS",
  자유 = "FREE",
}

export const SAVE_POST = gql`
  mutation createPost($input: createPostInput!) {
    createPost(input: $input) {
      post {
        id
        title
        content
      }
    }
  }
`;

export const UPDATE_POST = gql`
  mutation updateMyPost($input: JSON!) {
    updateMyPost(input: $input) {
      post {
        id
        title
        content
      }
    }
  }
`;

export const GET_TIP_POSTS = gql`
  query posts($where: JSON, $sort: String) {
    posts(where: $where, sort: $sort) {
      id
      title
      content
      created_at
      viewCount
      commentCount
      likeCount
      user {
        id
        username
      }
    }
  }
`;

export const GET_POST = gql`
  query post($id: ID!) {
    post(id: $id) {
      id
      title
      content
      category
      viewCount
      commentCount
      likeCount
      user {
        id
        username
      }
      created_at
      postComments {
        id
        content
        created_at
        user {
          id
          username
        }
      }
      postLikeHistories {
        user {
          id
        }
      }
    }
  }
`;

export const GET_MY_POST = gql`
  query myPost($id: ID!) {
    myPost(id: $id) {
      id
      title
      content
      category
    }
  }
`;
