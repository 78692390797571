import React, { useState } from "react";
import { API_URL } from "../../apis/config";
import Button from "../../components/button/Button";
import Input from "../../components/common/Input";
import PasswordChangePageTemplate from "../../components/pages/auth/password/PasswordChangePageTemplate";

const PasswordChangePage: React.FC = () => {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const passwordErrorMessage = (): undefined | string => {
    if (!password) {
      return undefined;
    }

    if (password.length < 6) {
      return "패스워드는 최소 6글자 이상 입력해주세요.";
    }

    return undefined;
  };

  const passwordConfirmErrorMessage = (): undefined | string => {
    if (!passwordConfirm) {
      return undefined;
    }

    if (password !== passwordConfirm) {
      return "패스워드가 일치하지 않습니다.";
    }

    return undefined;
  };

  const handleSubmit = async () => {
    if (passwordErrorMessage() || passwordConfirmErrorMessage()) {
      return;
    }

    try {
      const data = await fetch(`${API_URL}/password`, {
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({ password, passwordConfirmation: passwordConfirm }),
      });

      const response = await data.json();

      if (!response) {
        alert("잠시 후 다시 시도해주세요.");
        return;
      }

      alert("비밀번호가 변경되었습니다.\n새로운 비밀번호로 다시 로그인 해주세요.");
      window.location.href = "/login";
    } catch (error) {
      alert("잠시 후 다시 시도해주세요.");
    }
  };

  return (
    <PasswordChangePageTemplate
      Password={<Input type="password" errorMessage={passwordErrorMessage()} value={password} onChange={setPassword} />}
      PasswordConfirm={
        <Input
          type="password"
          errorMessage={passwordConfirmErrorMessage()}
          value={passwordConfirm}
          onChange={setPasswordConfirm}
        />
      }
      SubmitButton={<Button text="비밀번호 변경" onClick={handleSubmit} />}
    />
  );
};

export default PasswordChangePage;
