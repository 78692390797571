import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ICookieDeck } from "../../../../../apis/deck/deck.types";
import LoadingMessageBox from "../../../../container/LoadingMessageBox";
import DeckListItem from "./DeckListItem";

interface Props {
  loading: boolean;
  items: ICookieDeck[];
}

const DeckList: React.FC<Props> = ({ loading, items }) => {
  if (loading) {
    return <LoadingMessageBox />;
  }

  return (
    <div>
      {items.map((item) => (
        <Link to={`/deck/${item.id}`} key={`deck-list-${item.id}`}>
          <ListItemWrapper>
            <DeckListItem item={item} />
          </ListItemWrapper>
        </Link>
      ))}
    </div>
  );
};

export default DeckList;

const ListItemWrapper = styled.div`
  border-bottom: 1px solid #f2f2f2;
`;
