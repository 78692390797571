import React, { useEffect } from "react";
import { isProduction } from "../../config/basic";

export interface IGoogleAdsenseProps {
  className?: string;
  style?: object;
  adClient: string;
  adSlot: string;
  adLayout?: string;
  adLayoutKey?: string;
  adFormat?: string;
  fullWidthResponsive?: string;
}

declare global {
  interface Window {
    adsbygoogle: { [key: string]: unknown }[];
    dataLayer: any;
  }
}

const GoogleAdsense: React.FC<IGoogleAdsenseProps> = ({
  className = "",
  style = { display: "block" },
  adLayout = "",
  adLayoutKey = "",
  adFormat = "auto",
  fullWidthResponsive = "false",
  adClient,
  adSlot,
}) => {
  useEffect(() => {
    if (window && isProduction) {
      try {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } catch (error) {
        return;
      }
    }
  });

  return (
    <ins
      className={`adsbygoogle ${className}`}
      style={style}
      data-ad-client={adClient}
      data-ad-slot={adSlot}
      data-ad-layout={adLayout}
      data-ad-layout-key={adLayoutKey}
      data-ad-format={adFormat}
      data-full-width-responsive={fullWidthResponsive}
    />
  );
};

export default GoogleAdsense;
