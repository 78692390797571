import "react-app-polyfill/ie11";
import "core-js/stable";
import "regenerator-runtime/runtime";

import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "./styles/Theme";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import client from "./apis/apollo";
import { Provider } from "react-redux";
import { store } from "./modules";
import HomePage from "./pages/HomePage";
import CookieListPage from "./pages/CookieListPage";
import TreasureListPage from "./pages/TreasureListPage";
import PatchNoteDetailPage from "./pages/PatchNoteDetailPage";
import { ApolloProvider } from "@apollo/react-hooks";
import CookieDetailPage from "./pages/CookieDetailPage";
import LoginPage from "./pages/auth/LoginPage";
import RegisterPage from "./pages/auth/RegisterPage";
import ProfilePage from "./pages/auth/ProfilePage";
import PrivateRoute from "./containers/PrivateRoute";
import PasswordChangePage from "./pages/auth/PasswordChangePage";
import CouponListPage from "./pages/CouponListPage";
import CreateCookieDeckPage from "./pages/deck/CreateCookieDeckPage";
import CookieDeckListPage from "./pages/deck/CookieDeckListPage";
import CookieDeckDetailPage from "./pages/deck/CookieDeckDetailPage";
import CreatePostPage from "./pages/post/CreatePostPage";
import PostListPage from "./pages/post/PostListPage";
import PostDetailPage from "./pages/post/PostDetailPage";

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <ThemeProvider>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/register" component={RegisterPage} />
            <PrivateRoute exact path="/profile" component={ProfilePage} />
            <PrivateRoute exact path="/password" component={PasswordChangePage} />
            <PrivateRoute exact path="/create/deck" component={CreateCookieDeckPage} />
            <Route exact path="/decks" component={CookieDeckListPage} />
            <Route exact path="/deck/:id" component={CookieDeckDetailPage} />
            <Route exact path="/coupons" component={CouponListPage} />
            <Route exact path="/cookies" component={CookieListPage} />
            <Route exact path="/cookie/:id" component={CookieDetailPage} />
            <Route exact path="/treasures" component={TreasureListPage} />
            <Route exact path="/patchnote/:id" component={PatchNoteDetailPage} />
            <PrivateRoute exact path="/create/post/:id?" component={CreatePostPage} />
            <Route exact path="/posts" component={PostListPage} />
            <Route exact path="/post/:id" component={PostDetailPage} />
          </Switch>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  </ApolloProvider>,
  document.getElementById("root")
);

reportWebVitals();
