import { gql } from "@apollo/client";

export const DELETE_COOKIE_DECK = gql`
  mutation myCookieDeckDelete($id: ID!) {
    myCookieDeckDelete(id: $id) {
      id
    }
  }
`;

export const SAVE_COOKIE_DECK = gql`
  mutation createCookieDeck($input: createCookieDeckInput) {
    createCookieDeck(input: $input) {
      cookieDeck {
        id
        title
        cookieA {
          id
          name
        }
        cookieB {
          id
          name
        }
        cookieC {
          id
          name
        }
        cookieD {
          id
          name
        }
        cookieE {
          id
          name
        }
        user {
          id
          username
        }
      }
    }
  }
`;

export const GET_DECKS = gql`
  query cookieDecks {
    cookieDecks {
      id
      title
      created_at
      cookieA {
        id
        name
        thumbnail
      }
      cookieB {
        id
        name
        thumbnail
      }
      cookieC {
        id
        name
        thumbnail
      }
      cookieD {
        id
        name
        thumbnail
      }
      cookieE {
        id
        name
        thumbnail
      }
    }
  }
`;

export const GET_DECKS_PAGING = gql`
  query cookieDecksConnection($sort: String, $limit: Int, $start: Int, $where: JSON) {
    cookieDecksConnection(start: $start, limit: $limit, sort: $sort, where: $where) {
      values {
        id
        title
        created_at
        viewCount
        commentCount
        likeCount
        cookieA {
          id
          name
          thumbnail
        }
        cookieB {
          id
          name
          thumbnail
        }
        cookieC {
          id
          name
          thumbnail
        }
        cookieD {
          id
          name
          thumbnail
        }
        cookieE {
          id
          name
          thumbnail
        }
        user {
          id
          username
        }
      }
      aggregate {
        count
        totalCount
      }
    }
  }
`;

export const GET_DECK = gql`
  query cookieDeck($id: ID!) {
    cookieDeck(id: $id) {
      id
      title
      content
      viewCount
      commentCount
      likeCount
      cookieA {
        id
        name
        thumbnail
        grade
        type
        totalTier
        worldTier
        arenaTier
        warTier
        skillName
        skillImage
        skillDescription
        story
      }
      cookieB {
        id
        name
        thumbnail
        grade
        type
        totalTier
        worldTier
        arenaTier
        warTier
        skillName
        skillImage
        skillDescription
        story
      }
      cookieC {
        id
        name
        thumbnail
        grade
        type
        totalTier
        worldTier
        arenaTier
        warTier
        skillName
        skillImage
        skillDescription
        story
      }
      cookieD {
        id
        name
        thumbnail
        grade
        type
        totalTier
        worldTier
        arenaTier
        warTier
        skillName
        skillImage
        skillDescription
        story
      }
      cookieE {
        id
        name
        thumbnail
        grade
        type
        totalTier
        worldTier
        arenaTier
        warTier
        skillName
        skillImage
        skillDescription
        story
      }
      toppingA {
        id
        name
        thumbnail
        description
        synergy1
        synergy2
      }
      toppingB {
        id
        name
        thumbnail
        description
        synergy1
        synergy2
      }
      toppingC {
        id
        name
        thumbnail
        description
        synergy1
        synergy2
      }
      toppingD {
        id
        name
        thumbnail
        description
        synergy1
        synergy2
      }
      toppingE {
        id
        name
        thumbnail
        description
        synergy1
        synergy2
      }
      user {
        id
        username
      }
      created_at
      cookieDeckComments {
        id
        content
        created_at
        user {
          id
          username
        }
      }
      cookieDeckLikeHistories {
        user {
          id
        }
      }
    }
  }
`;
