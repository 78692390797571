import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { LOGIN } from "../../apis/auth/login";
import Button from "../../components/button/Button";
import Input from "../../components/common/Input";
import LoginPageTemplate from "../../components/pages/auth/login/LoginPageTemplate";
import queryString from "query-string";

const LoginPage: React.FC = () => {
  const [login, { data }] = useMutation(LOGIN);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const parsingPrev = () => {
    const parsed = queryString.parse(window.location.search);

    if (parsed && typeof parsed.prev === "string") {
      window.location.href = parsed.prev as string;
      return;
    }

    window.location.href = "/";
    return;
  };

  if (data) {
    try {
      const res = data.login;
      const { jwt } = res;
      localStorage.setItem("token", jwt);

      parsingPrev();
    } catch (error) {}
  }

  const handleSubmit = async () => {
    try {
      localStorage.clear();

      await login({
        variables: {
          input: {
            identifier: username,
            password,
          },
        },
      });
    } catch (error) {
      alert("아이디 또는 패스워드를 다시 확인해주세요.");
    }
  };

  return (
    <LoginPageTemplate
      Username={<Input value={username} onChange={setUsername} />}
      Password={<Input type="password" value={password} onChange={setPassword} />}
      SubmitButton={<Button disabled={!username || !password} text="로그인" onClick={handleSubmit} />}
    />
  );
};

export default LoginPage;
