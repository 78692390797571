import React, { useCallback, useEffect, useState } from "react";
import { cookieListTabItems, GET_COOKIES } from "../apis/cookie/cookie";
import { CookieTierType, ICookie } from "../apis/cookie/cookie.type";
import Tab from "../components/common/Tab";
import CookieList from "../components/pages/cookie/list/CookieList";
import CookieListPageTemplate from "../components/pages/cookie/list/CookieListPageTemplate";
import { useQuery } from "@apollo/react-hooks";
import CookieListSubTab from "../components/pages/cookie/list/CookieListSubTab";
import CookieListPositionTab from "../components/pages/cookie/list/CookieListPositionTab";

const CookieListPage: React.FC = () => {
  const { loading, data } = useQuery<{ cookies: ICookie[] }>(GET_COOKIES);

  const [items, setItems] = useState<ICookie[]>([]);
  const [tab, setTab] = useState<string>(CookieTierType.종합티어);
  const [typeTab, setTypeTab] = useState<string>("전체");
  const [positionTab, setPositionTab] = useState<string>("전체");

  const sort = useCallback((cookies: ICookie[], key: string) => {
    return cookies.slice().sort((a: any, b: any) => {
      return a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0;
    });
  }, []);

  useEffect(() => {
    if (data) {
      setItems(sort(data.cookies || [], CookieTierType.종합티어));
    } else {
      setItems([]);
    }
  }, [data, sort]);

  const handleChangeTab = (value: string) => {
    setTab(value);
    setItems(sort(items, value));
  };

  const handleChangeSubTab = (value: string) => {
    if (!data) {
      return;
    }

    setTypeTab(value);

    if (value === "전체" && positionTab === "전체") {
      setItems(sort(data.cookies, tab));
      return;
    }

    if (value === "전체") {
      setItems(
        sort(
          data.cookies.filter((v) => v.position === positionTab),
          tab
        )
      );
      return;
    }

    if (positionTab === "전체") {
      setItems(
        sort(
          data.cookies.filter((v) => v.type === value),
          tab
        )
      );
      return;
    }

    setItems(
      sort(
        data.cookies.filter((v) => v.type === value).filter((v) => v.position === positionTab),
        tab
      )
    );
  };

  const handleChangePositionTab = (value: string) => {
    if (!data) {
      return;
    }

    setPositionTab(value);

    if (value === "전체" && typeTab === "전체") {
      setItems(sort(data.cookies, tab));
      return;
    }

    if (value === "전체") {
      setItems(
        sort(
          data.cookies.filter((v) => v.type === typeTab),
          tab
        )
      );
      return;
    }

    if (typeTab === "전체") {
      setItems(
        sort(
          data.cookies.filter((v) => v.position === value),
          tab
        )
      );
      return;
    }

    setItems(
      sort(
        data.cookies.filter((v) => v.type === typeTab).filter((v) => v.position === value),
        tab
      )
    );
  };

  return (
    <CookieListPageTemplate
      Tab={<Tab value={tab} onChange={handleChangeTab} items={cookieListTabItems} />}
      SubTab={<CookieListSubTab value={typeTab} onChange={handleChangeSubTab} />}
      PositionTab={<CookieListPositionTab value={positionTab} onChange={handleChangePositionTab} />}
      List={<CookieList loading={loading} tierType={tab} items={items} />}
    />
  );
};

export default CookieListPage;
