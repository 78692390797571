import React from "react";
import styled from "styled-components";
import { IPatchNote } from "../../../../apis/patchnote/patchnote.type";
import BackHeader from "../../../basic/header/BackHeader";
import Layout from "../../../container/Layout";

interface Props {
  item: IPatchNote;
}

const PatchNoteDetailPageTemplate: React.FC<Props> = ({ item }) => {
  return (
    <Layout Header={<BackHeader title="쿠키노트" />}>
      <HeaderWrapper>
        <Date>{item.date}</Date>
        <Title>{item.title}</Title>
      </HeaderWrapper>
      <ContentWrapper>
        <Content>{item.content}</Content>
      </ContentWrapper>
    </Layout>
  );
};

export default PatchNoteDetailPageTemplate;

const HeaderWrapper = styled.div`
  padding: 20px;
  background-color: #fff;
`;

const Date = styled.span`
  font-family: sans-serif;
  font-size: 0.825rem;
  color: #888;
`;

const Title = styled.p`
  font-size: 1rem;
  margin: 0;
`;

const ContentWrapper = styled.div`
  margin-top: 8px;
  padding: 20px;
  background-color: #fff;
`;

const Content = styled.div`
  white-space: pre-line;
  font-family: sans-serif;
`;
