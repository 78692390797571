import React from "react";
import styled from "styled-components";

const Footer: React.FC = () => {
  return (
    <FooterBlock>
      <Logo>쿠킹지지</Logo>

      <ContactWrapper>
        <ContactMail href="mailto:notice.rokgg@gmail.com">Contact Us</ContactMail>
      </ContactWrapper>

      <div>
        <Text>© 쿠킹지지. All rights reserved</Text>
        <Text>쿠킹지지 is not associated with devsisters.</Text>
      </div>
    </FooterBlock>
  );
};

export default Footer;

const FooterBlock = styled.footer`
  margin-top: 8px;
  background-color: #222f46;
  padding: 20px 20px 28px 20px;
`;

const Logo = styled.p`
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 1.4rem;
  font-weight: bold;
  color: #fff;
`;

const ContactWrapper = styled.div`
  margin-bottom: 12px;
`;

const ContactMail = styled.a`
  color: #fff;
  text-decoration-color: #fff;
  text-decoration-line: underline;
`;

const Text = styled.p`
  margin: 4px 0;
  font-size: 0.625rem;
  font-weight: bold;
  color: #fff;
`;
