import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { IPost } from "../../../../apis/post/post.types";
import LoadingMessageBox from "../../../container/LoadingMessageBox";
import PostListItem from "./PostListItem";

interface Props {
  category: string;
  loading: boolean;
  items: IPost[];
}

const PostList: React.FC<Props> = ({ category, loading, items }) => {
  if (loading) {
    return <LoadingMessageBox />;
  }

  return (
    <div>
      {items.map((item) => (
        <ListItemWrapper key={`post-${item.id}`}>
          <Link to={`/post/${item.id}?category=${category}`}>
            <PostListItem item={item} />
          </Link>
        </ListItemWrapper>
      ))}
    </div>
  );
};

export default PostList;

const ListItemWrapper = styled.div`
  border-bottom: 1px solid #f2f2f5;
`;
