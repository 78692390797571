import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_DECKS_PAGING } from "../../apis/deck/deck";
import { ICookieDeck } from "../../apis/deck/deck.types";
import DeckList from "../../components/pages/cookie/deck/list/DeckList";
import DeckListPageTemplate from "../../components/pages/cookie/deck/list/DeckListPageTemplate";
import Button from "../../components/button/Button";
import { useSelector } from "react-redux";
import { user } from "../../modules/auth/auth";
import { useHistory } from "react-router";

const limit = 50;
const sort = "created_at:DESC";

const CookieDeckListPage: React.FC = () => {
  const history = useHistory();
  const loginUser = useSelector(user);

  const [skip, setSkip] = useState(0);

  const { data, loading, fetchMore } = useQuery<{
    cookieDecksConnection: {
      values: ICookieDeck[];
      aggregate: {
        totalCount: number;
        count: number;
      };
    };
  }>(GET_DECKS_PAGING, {
    variables: { sort, limit, where: { published_at_null: false } },
    notifyOnNetworkStatusChange: true,
  });

  const handleGoCreateDeck = () => {
    if (!loginUser) {
      window.location.href = "/login";
      return;
    }

    history.push("/create/deck");
  };

  const items = data ? data.cookieDecksConnection.values : [];

  const totalCount =
    data && data.cookieDecksConnection && data.cookieDecksConnection.aggregate
      ? data.cookieDecksConnection.aggregate.count
      : 0;

  const hasMore = totalCount && totalCount > items.length;

  const handleMore = async () => {
    const start = skip + limit;

    await fetchMore({
      variables: { start, sort, limit, where: { published_at_null: false } },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          cookieDecksConnection: {
            values: [...prev.cookieDecksConnection.values, ...fetchMoreResult.cookieDecksConnection.values],
            aggregate: fetchMoreResult.cookieDecksConnection.aggregate,
          },
        });
      },
    });

    setSkip(skip + limit);
  };

  return (
    <DeckListPageTemplate
      List={<DeckList loading={loading} items={items} />}
      CreateLinkButton={<Button text="나만의 덱 만들기" onClick={handleGoCreateDeck} />}
      MoreButton={hasMore ? <Button text="덱 더보기" onClick={handleMore} /> : undefined}
    />
  );
};

export default CookieDeckListPage;
