import React from "react";
import styled from "styled-components";
import KakaoAdFitBox from "../../../ad/KakaoAdFitBox";
import BackHeader from "../../../basic/header/BackHeader";
import NoticeBox from "../../../box/NoticeBox";
import Layout from "../../../container/Layout";

interface Props {
  Tab: React.ReactElement;
  SubTab: React.ReactElement;
  PositionTab?: React.ReactElement;
  List: React.ReactElement;
}

const CookieListPageTemplate: React.FC<Props> = ({ Tab, SubTab, PositionTab, List }) => {
  return (
    <Layout Header={<BackHeader title="🍪 쿠키 티어" />}>
      <Container>
        <Top>
          <UpdateText>UPDATE 2021.05.15</UpdateText>

          <NoticeWrapper>
            <NoticeBox text="커뮤니티, 유튜브 등 다양한 의견을 종합한 주관적 티어 분류입니다." />
          </NoticeWrapper>

          <TopAdWrapper>
            <KakaoAdFitBox width="320" height="100" unit="DAN-jpDFhKv3NFz7Cagh" name="cookie-list-adfit-top" />
          </TopAdWrapper>
        </Top>

        <TabListWrapper>
          {Tab}
          <SubTabListWrapper>
            <SubTabWrapper>
              <SubTabLabel>배치</SubTabLabel>
              {PositionTab}
            </SubTabWrapper>
            <SubTabWrapper>
              <SubTabLabel>유형</SubTabLabel>
              {SubTab}
            </SubTabWrapper>
          </SubTabListWrapper>
        </TabListWrapper>
        <ListWrapper>{List}</ListWrapper>

        <BottomAdWrapper>
          <KakaoAdFitBox width="320" height="100" unit="DAN-2QlitBGgsWbuolul" name="cookie-list-adfit-bottom" />
        </BottomAdWrapper>
      </Container>
    </Layout>
  );
};

export default CookieListPageTemplate;

const Container = styled.div``;

const Top = styled.div`
  padding: 20px;
  background-color: #fff;
`;

const TabListWrapper = styled.div`
  margin-top: 8px;
  padding-top: 4px;
  background-color: #fff;
  border-bottom: 1px solid #f2f2f5;
  margin-bottom: 8px;
`;

const SubTabListWrapper = styled.div`
  margin-top: 8px;
`;

const SubTabWrapper = styled.div`
  padding: 7px 15px;
`;

const ListWrapper = styled.div`
  background-color: #fff;
`;

const NoticeWrapper = styled.div`
  margin-top: 16px;
`;

const UpdateText = styled.p`
  font-size: 0.725rem;
  margin-top: 0;
  margin-bottom: 0;
  color: #444;
`;

const TopAdWrapper = styled.div`
  margin-top: 16px;
  position: relative;
  height: 100px;
`;

const BottomAdWrapper = styled.div`
  background-color: #fff;
  padding-top: 16px;
  padding-bottom: 16px;
  height: 100px;
`;

const SubTabLabel = styled.div`
  padding: 0 4px;
  font-size: 0.725rem;
  color: #000;
`;
