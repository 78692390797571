import { gql } from "@apollo/client";

export const GET_TOPPINGS = gql`
  query toppings {
    toppings {
      id
      name
      thumbnail
      description
      synergy1
      synergy2
    }
  }
`;
