import React from "react";
import styled from "styled-components";
import NoticeIcon from "../icon/NoticeIcon";

interface Props {
  text: string;
}

const NoticeBox: React.FC<Props> = ({ text }) => {
  return (
    <NoticeTextBox>
      <NoticeIcon color="#0078ff" />
      <NoticeText>{text}</NoticeText>
    </NoticeTextBox>
  );
};

export default NoticeBox;

const NoticeTextBox = styled.div`
  background-color: #dfecff;
  padding: 8px;
  border-radius: 6px;
  display: flex;
`;

const NoticeText = styled.p`
  margin-left: 8px;
  font-family: sans-serif;
  font-size: 0.725rem;
  margin-top: 0;
  margin-bottom: 0;
  color: #0078ff;
`;
