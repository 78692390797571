import { gql } from "@apollo/client";

export const GET_TREASURES = gql`
  query treasures {
    treasures {
      id
      name
      thumbnail
      grade
      level1
      totalTier
    }
  }
`;

// export const APIGetTreasureList = (): ITreasure[] => {
//   return [
//     {
//       id: 0,
//       name: "정화된 석류신목의 신성한 나뭇가지",
//       thumbnail: "",
//       totalTier: 1,
//       grade: Grade.에픽,
//     },
//     {
//       id: 1,
//       name: "작은 뿔꿈틀이의 끈적끈적 방어용 점액",
//       thumbnail: "",
//       totalTier: 2,
//       grade: Grade.에픽,
//     },
//     {
//       id: 2,
//       name: "순례자의 마을 촌장의 활활 화르륵 횃불",
//       thumbnail: "",
//       totalTier: 1.5,
//       grade: Grade.에픽,
//     },
//     {
//       id: 3,
//       name: "설탕백조의 샤이닝 설탕깃털",
//       thumbnail: "",
//       totalTier: 2,
//       grade: Grade.에픽,
//     },
//     {
//       id: 4,
//       name: "나이든 순례자의 승리를 향한 무공비급",
//       thumbnail: "",
//       totalTier: 0,
//       grade: Grade.에픽,
//     },
//     {
//       id: 5,
//       name: "행복한 곰젤리의 숨겨둔 사탕",
//       thumbnail: "",
//       totalTier: 1,
//       grade: Grade.레어,
//     },
//     {
//       id: 6,
//       name: "어린 순례자의 장난감 새총",
//       thumbnail: "",
//       totalTier: 1,
//       grade: Grade.레어,
//     },
//     {
//       id: 7,
//       name: "아이스크림 상인 유령의 신기루 아이스크림",
//       thumbnail: "",
//       totalTier: 2,
//       grade: Grade.레어,
//     },
//     {
//       id: 8,
//       name: "무녀맛 쿠키의 영험한 종이부적",
//       thumbnail: "",
//       totalTier: 2,
//       grade: Grade.레어,
//     },
//     {
//       id: 9,
//       name: "골카론의 날카로운 낫",
//       thumbnail: "",
//       totalTier: 1,
//       grade: Grade.레어,
//     },
//     {
//       id: 10,
//       name: "골카론의 날카로운 낫",
//       thumbnail: "",
//       totalTier: 2,
//       grade: Grade.커먼,
//     },
//     {
//       id: 11,
//       name: "왕자 곰젤리의 말랑말랑 젤리시계",
//       thumbnail: "",
//       totalTier: 0,
//       grade: Grade.커먼,
//     },
//     {
//       id: 12,
//       name: "문지기 유령의 단단한 오른쪽 뿔",
//       thumbnail: "",
//       totalTier: 0,
//       grade: Grade.커먼,
//     },
//   ].map((v) => {
//     return {
//       ...v,
//       thumbnail: "https://image.rok.gg/cookie/treasure/" + v.name.replace(" ", "+") + ".png",
//     };
//   });
// };
