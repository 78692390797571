import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { SAVE_POST, GET_MY_POST, UPDATE_POST, POST_CATEGORY } from "../../apis/post/post";
import QuillEditor from "../../components/editor/QuillEditor";
import CreatePostPageTemplate from "../../components/pages/post/create/CreatePostPageTemplate";
import { user } from "../../modules/auth/auth";
import CreatePostTextSaveButton from "../../components/pages/post/create/CreatePostTextSaveButton";
import CreatePostTitleInput from "../../components/pages/post/create/CreatePostTitleInput";
import { useHistory, useParams } from "react-router";
import { IPostSimple } from "../../apis/post/post.types";
import EmptyLayout from "../../components/container/EmptyLayout";

const CreatePostPage: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const loginUser = useSelector(user);

  const [title, setTitle] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const [editMode, setEditMode] = useState(false);

  const { data, error } = useQuery<{ myPost: IPostSimple }>(GET_MY_POST, { variables: { id: Number(id || 0) } });
  const [savePost] = useMutation(SAVE_POST);
  const [updatePost] = useMutation(UPDATE_POST);

  useEffect(() => {
    if (data && data.myPost) {
      setTitle(data.myPost.title || "");
      setContent(data.myPost.content || "");
    }
    setEditMode(data && data.myPost ? true : false);
  }, [data]);

  const handleSubmit = async () => {
    if (!loginUser) {
      alert("로그인이 필요합니다.");
      return;
    }

    if (editMode) {
      fetchUpdatePost();
    } else {
      fetchSavePost();
    }
  };

  const fetchSavePost = async () => {
    const isOk = window.confirm("등록하시겠습니까?");

    if (!isOk) {
      return;
    }

    if (!title || !content) {
      alert("내용을 입력해주세요.");
      return;
    }

    try {
      const { data: response } = await savePost({
        variables: {
          input: {
            data: {
              user: loginUser!.id.toString(),
              title,
              content,
              category: POST_CATEGORY.유저팁,
              published_at: new Date(),
            },
          },
        },
      });

      if (response) {
        alert("등록되었습니다.");
        history.push(`/posts`);
      } else {
        alert("잠시후 다시 시도해주세요.");
      }
    } catch (error) {}
  };

  const fetchUpdatePost = async () => {
    const isOk = window.confirm("수정하시겠습니까?");

    if (!isOk) {
      return;
    }

    try {
      const { data: response } = await updatePost({
        variables: {
          input: {
            id,
            title,
            content,
          },
        },
      });

      if (response) {
        alert("수정되었습니다.");
        history.push(`/post/${id}`);
      } else {
        alert("잠시후 다시 시도해주세요.");
      }
    } catch (error) {}
  };

  if (error) {
    return <EmptyLayout />;
  }

  return (
    <CreatePostPageTemplate
      SaveTextButton={<CreatePostTextSaveButton onClick={handleSubmit} />}
      Title={<CreatePostTitleInput value={title} onChange={setTitle} />}
      Content={<QuillEditor placeholder="내용을 입력해주세요" contents={content} onChange={setContent} />}
    />
  );
};

export default CreatePostPage;
