import React from "react";
import styled from "styled-components";

interface Props {
  Header: React.ReactElement;
  Slot1: React.ReactElement;
  Slot2: React.ReactElement;
  Slot3: React.ReactElement;
  Slot4: React.ReactElement;
  Slot5: React.ReactElement;
  CookieList: React.ReactElement;
}

const CreateCookieDeckPageTemplate: React.FC<Props> = ({ Header, Slot1, Slot2, Slot3, Slot4, Slot5, CookieList }) => {
  return (
    <DeckSlotBlockWrapper>
      <DeckSlot2Block>
        <EmptySection />
        <FixedPositionSlotBox>
          {Header}
          <CookieSlotList>
            {Slot1}
            {Slot2}
            {Slot3}
            {Slot4}
            {Slot5}
          </CookieSlotList>
        </FixedPositionSlotBox>
      </DeckSlot2Block>

      <CookieListWrapper>{CookieList}</CookieListWrapper>
    </DeckSlotBlockWrapper>
  );
};

export default CreateCookieDeckPageTemplate;

const fixedSlotHeight = 200;

const DeckSlotBlockWrapper = styled.div`
  padding-bottom: 20px;
  background-color: #fff;
  max-width: 600px;
  margin: 0 auto;
`;

const DeckSlot2Block = styled.div`
  position: relative;
`;

const EmptySection = styled.div`
  height: ${fixedSlotHeight}px;
`;

const FixedPositionSlotBox = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${fixedSlotHeight}px;
  background-color: #151e2e;
  z-index: 4;
  max-width: 600px;
  margin: 0 auto;
`;

const CookieSlotList = styled.div`
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const CookieListWrapper = styled.div`
  position: relative;
  background-color: #fff;
  z-index: 2;
  border-top: 8px solid #eaeaea;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;
