export enum Grade {
  에이션트 = "ANCIENT",
  에픽 = "EPIC",
  레어 = "RARE",
  커먼 = "COMMON",
}

export enum AttackType {
  돌격 = "돌격",
  침투 = "침투",
  방어 = "방어",
  마법 = "마법",
  사격 = "사격",
  회복 = "회복",
  지원 = "지원",
  폭발 = "폭발",
}

export enum PositionType {
  전방 = "전방",
  중앙 = "중앙",
  후방 = "후방",
}
