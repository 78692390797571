import React from "react";
import styled from "styled-components";

interface Props {
  title?: string;
  onClick?: () => void;
}

const CreateCookieDeckNextText: React.FC<Props> = ({ title = "다음", onClick }) => {
  return <DeckSaveText onClick={onClick}>{title}</DeckSaveText>;
};

export default CreateCookieDeckNextText;

const DeckSaveText = styled.div`
  font-size: 0.825rem;
  font-weight: bold;
  color: #fff;
`;
