import React, { ChangeEvent } from "react";
import styled from "styled-components";

interface Props {
  value?: string;
  onChange?: (value: string) => void;
}

const CreatePostTitleInput: React.FC<Props> = ({ value, onChange }) => {
  const handleChangeTitle = (event: ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event.target.value);
  };

  return (
    <Input
      type="text"
      autoFocus
      autoComplete="off"
      placeholder="제목을 입력해주세요."
      value={value}
      onChange={handleChangeTitle}
    />
  );
};

export default CreatePostTitleInput;

const Input = styled.input`
  width: calc(100% - 20px);
  font-size: 16px;
  padding: 16px 10px;
  border: 0;
  outline: none;
  border-bottom: 1px solid #eaeaea;
  border-radius: 0;
`;
