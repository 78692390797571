import React from "react";
import styled from "styled-components";
import { ICoupon } from "../../../apis/coupon/coupon.types";

interface Props {
  item: ICoupon;
}

const CouponListItem: React.FC<Props> = ({ item }) => {
  const handleCopy = () => {
    if (!document.queryCommandSupported("copy")) {
      return alert("복사하기가 지원되지 않는 브라우저입니다.");
    }

    const textarea: HTMLTextAreaElement = document.createElement("textarea");
    textarea.value = item.code;
    textarea.style.top = "0";
    textarea.style.left = "0";
    textarea.style.display = "fixed";

    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    alert("클립보드에 복사되었습니다.");
  };

  return (
    <CouponListItemBlock>
      <div>
        <Name>{item.name}</Name>
        <Code>{item.code}</Code>
        <Reward>({item.reward})</Reward>
      </div>
      <div>
        <CodeCopyButton onClick={handleCopy}>코드복사</CodeCopyButton>
      </div>
    </CouponListItemBlock>
  );
};

export default CouponListItem;

const CouponListItemBlock = styled.div`
  padding: 20px;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Name = styled.div`
  font-size: 0.825rem;
  line-height: 1.5;
  color: #069bff;
`;

const Code = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.5;
  color: #000;
`;

const Reward = styled.div`
  font-size: 0.825rem;
  line-height: 1.5;
  color: #333;
`;

const CodeCopyButton = styled.div`
  background-color: #f2f2f9;
  border: 1px solid #eaeaea;
  padding: 8px 8px;
  font-size: 0.825rem;
  border-radius: 4px;
  cursor: pointer;
`;
