import React from "react";
import styled from "styled-components";
import BackHeader from "../../../basic/header/BackHeader";
import Layout from "../../../container/Layout";

interface Props {
  PasswordChangeButton: React.ReactElement;
}

const ProfilePageTemplate: React.FC<Props> = ({ PasswordChangeButton }) => {
  return (
    <Layout Header={<BackHeader title="프로필" />}>
      <Container>
        <div>프로필 페이지</div>
        <div>{PasswordChangeButton}</div>
      </Container>
    </Layout>
  );
};

export default ProfilePageTemplate;

const Container = styled.div`
  padding: 20px;
`;
