import { IUser } from "../../types/IUser";
import { IComment } from "../common/comment.types";
import { ICookieTopping } from "../cookie/cookie.topping.types";
import { ICookie } from "../cookie/cookie.type";

export enum COOKIE_SLOT {
  EMPTY = 0,
  SLOT1 = 1,
  SLOT2 = 2,
  SLOT3 = 3,
  SLOT4 = 4,
  SLOT5 = 5,
}

export interface IAddCookieDeck {
  title: string;
  content: string;
  cookieA: string;
  cookieB: string;
  cookieC: string;
  cookieD: string;
  cookieE: string;
  toppingA: string;
  toppingB: string;
  toppingC: string;
  toppingD: string;
  toppingE: string;
  user: string;
  published_at: Date;
}

export interface ICookieDeck {
  id: string;
  created_at: string;
  updated_at: string;
  title: string;
  content: string;
  cookieA: ICookie;
  cookieB: ICookie;
  cookieC: ICookie;
  cookieD: ICookie;
  cookieE: ICookie;
  toppingA: ICookieTopping;
  toppingB: ICookieTopping;
  toppingC: ICookieTopping;
  toppingD: ICookieTopping;
  toppingE: ICookieTopping;
  user: IUser;
  published_at: string;
  viewCount: number | null;
  commentCount: number | null;
  likeCount: number;
}

export interface ICookieDeckDetail extends ICookieDeck {
  cookieDeckComments: ICookieDeckComment[];
  cookieDeckLikeHistories: {
    user: {
      id: string;
    };
  }[];
}

export interface ICookieDeckComment extends IComment {}
